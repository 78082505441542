import { Component } from '@angular/core';

@Component({
    selector: 'besc-credit-card-icon',
    standalone: true,
    template: `<svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M20.1162 4H4.11621C3.00621 4 2.12621 4.89 2.12621 6L2.11621 18C2.11621 19.11 3.00621 20 4.11621 20H20.1162C21.2262 20 22.1162 19.11 22.1162 18V6C22.1162 4.89 21.2262 4 20.1162 4ZM20.1162 18H4.11621V12H20.1162V18ZM20.1162 8H4.11621V6H20.1162V8Z"
            fill="currentColor"
        />
    </svg> `,
})
export class CreditCardIconComponent {}

<div
    ngbDropdown
    #dropdown="ngbDropdown"
    placement="bottom-right"
    class="d-flex align-items-stretch flex-shrink-0"
    *ngIf="user$ | async as _user"
>
    <div
        ngbDropdownToggle
        class="d-flex cursor-pointer align-items-center dropdown-toggle-no-after"
    >
        <span class="profile-menu">
            {{_user.firstName | firstLetter}}{{_user.lastName | firstLetter}}
        </span>
    </div>
    <div ngbDropdownMenu class="user-dropdown">
        <div class="user-dropdown-body">
            <div class="user-profile">
                <div [ngClass]="userProfileClass(_user)">
                    {{_user.firstName | firstLetter}}{{_user.lastName | firstLetter}}
                </div>
                <span>
                    {{_user.email}}
                </span>
            </div>
            <div *ngIf="_user && isAdmin" class="admin-links">
                <button class="bs-button bs-primary" (click)="goToAdminCenter()">
                    Admin Center
                </button>
            </div>
            <div class="sign-out">
                <button class="bs-button bs-secondary" (click)="logout()">
                    Sign out
                </button>
            </div>
        </div>
    </div>
</div>
<ng-template #userSelect>
    <div class="activity-user-select-body">
      <div class="search-users">
        <div class="bs-search-input-group">
          <input
            placeholder="Search by Name"
            type="text"
            class="bs-search-input"
            [(ngModel)]="filterText"
            (ngModelChange)="search($event)"
          />
          <div class="bs-search-input-append">
            <button type="button" class="bs-search-button">
              <img src="./assets/icons/search.svg" alt="">
            </button>
          </div>
        </div>
      </div>
      <div class="users-list" *ngIf="users">
        <div
          *ngFor="let user of users"
          [ngClass]="{'selected-user': isSelectedUser(user)}"
          class="user-item"
          (click)="selectActivityUser(user)"
        >
          <div class="user-avatar">
            {{ avatarName(user) }}
          </div>
          <span class="user-name">
            {{ user.firstName }} {{ user.lastName }}
          </span>
        </div>
      </div>
      <div class="actions">
        <button class="bs-button bs-primary" (click)="confirmSelectActivityUser()" [disabled]="!selectedActivityUser">
          Confirm
        </button>
      </div>
    </div>
  </ng-template>

/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SecureInterceptor } from './secure-interceptor';
import { TransformRequestInterceptor } from './transform-request-interceptor';
import { TransformDecimalsToNumbers } from './transform-decimals-to-numbers';
import { ErrorHandlingInterceptor } from './error-handling.interceptor';
import { ApiCacheInterceptor } from './api-cache.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: SecureInterceptor, multi: true },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TransformRequestInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: TransformDecimalsToNumbers,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorHandlingInterceptor,
        multi: true,
    },
    {
        provide: HTTP_INTERCEPTORS,
        useClass: ApiCacheInterceptor,
        multi: true,
    },
];

export { IGNORED_STATUSES } from './error-handling.interceptor';

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AgencyService } from './agency.service';
import { filter, map } from 'rxjs/operators';
import { AgencyStatus } from '../agency.interface';

@Injectable({
    providedIn: 'root',
})
export class AgencyIsActiveGuard implements CanActivate {
    constructor(private _agency: AgencyService, private _router: Router) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._agency.userAgency$.pipe(
            filter((agency) => !!agency),
            map((agency) => {
                return (
                    agency.status === AgencyStatus.ACTIVE ||
                    this._router.createUrlTree(['/app/account'])
                );
            })
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._agency.userAgency$.pipe(
            filter((agency) => !!agency),
            map((agency) => {
                return (
                    agency.status === AgencyStatus.ACTIVE ||
                    this._router.createUrlTree(['/app/account'])
                );
            })
        );
    }
}

import { AbstractControl, ValidationErrors, Validators } from '@angular/forms';
import * as moment from 'moment';

export const BescValidators = {
    requiredZipCode: Validators.compose([
        Validators.required,
        Validators.pattern(/(^\d{5}$)/),
    ]),
    zipCode: Validators.pattern(/(^\d{5}$)/),
    phoneNumber: Validators.compose([
        Validators.minLength(10),
        Validators.maxLength(10),
    ]),
    email: email,
    datePattern,
    maxDate,
    dateIsAfter,
    validDate,
};

function email(control: AbstractControl): ValidationErrors | null {
    const errors = Validators.pattern(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )(control);
    if (errors) {
        return {
            email: true,
        };
    } else {
        return null;
    }
}

function dateIsAfter(date: moment.Moment) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (moment(control.value).isAfter(date)) {
            return null;
        } else {
            return {
                dateIsAfter: true,
            };
        }
    };
}

function datePattern(pattern: RegExp) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (!control.value) {
            return null;
        }
    
        if (typeof control.value === 'string') {
            const isValid = pattern.test(control.value);
            return isValid ? null : { pattern: true };
        }
    
        return null;
    };
}

function maxDate(date: moment.Moment) {
    return (control: AbstractControl): ValidationErrors | null => {
        if (moment.isMoment(control.value)) {
            return moment(control.value).isAfter(date) ? { maxDate: true } : null
        }
        return null;
    }
}

function validDate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
        return null;
    }

    return moment.isMoment(control.value) ? null: { invalidDate: true };
}
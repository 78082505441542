import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  PlanRates,
  CompositeRateRequest
} from '@besc/plan';
import {
  normalizeEffectiveDate,
} from '@benefit-sculptor/core';
import { HttpClient } from '@angular/common/http';
import { shareReplay, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'besc-plan-rate-modal',
  templateUrl: './plan-rate-modal.component.html',
  styleUrls: ['./plan-rate-modal.component.scss']
})
export class PlanRateModalComponent implements OnInit {
  @Input() rates: PlanRates;
  @Input() compositeRateRequest$: Observable<CompositeRateRequest>;
  private _onDestroy$ = new Subject();

  rateForm: UntypedFormGroup = this._fb.group({
    type: 'age_rates'
  });

  compositeRates$ = new BehaviorSubject(null);
  loaded = false;

  indexes: number[] = [...Array(6).keys()];
  columns: number[] = [...Array(11).keys()];

  constructor(
    private _modal: NgbActiveModal,
    private _fb: UntypedFormBuilder,
    private _http: HttpClient,
  ) { }

  ngOnInit() {
    if (this.rates.rates.type === 'age_rate') {
      this.compositeRateRequest$.pipe(takeUntil(this._onDestroy$)).subscribe(request => {
        if (request && !this.loaded && request.planId) {
          this.loaded = true;
          this._http.post(`employer-plans/composite-rates`, {
            planId: request.planId,
            employerId: request.employerId,
            employerPlanId: request.employerPlanId,
            effectiveDate: normalizeEffectiveDate(request.effectiveDate).format(moment.HTML5_FMT.DATE),
            planEffectiveDate: normalizeEffectiveDate(request.planEffectiveDate).format(moment.HTML5_FMT.DATE),
            zipCode: request.zipCode
          }).pipe(shareReplay(1)).subscribe(response => {
            this.compositeRates$.next(response);
          });
        }
      });
    } else {
      this.rateForm.setValue({ type: 'composite' })
      this.compositeRates$.next(this.rates.rates.composite);
    }
  }

  close(crossClick: string) {
    this._modal.close();
  }
}

import { User } from '@benefit-sculptor/auth';

export interface Agency {
    id: string;
    name: string;
    contactInfo: {
        address: string;
        city: string;
        state: string;
        zipCode: number;
    };
    owner?: AgencyUser;
    employees?: AgencyUser[];
    status: AgencyStatus;
    employerLimit: number;
    sidebarBackgroundColor?: string;
    primaryColor?: string;
    primaryActiveColor?: string;
    logoBackgroundColor?: string;
    menuTextColor?: string;
    menuIconColor?: string;
    menuBackgroundColor?: string;
    logo?: string;
}

export interface AgencyUser extends User {
    jobTitle?: string;
    role: AgencyUserRole;
    agencyId: string;
}

export enum AgencyStatus {
    PENDING = 'pending',
    ACTIVE = 'active',
    DEACTIVATED = 'deactivated',
}

export enum AgencyUserRole {
    OWNER = 'owner',
    BASIC = 'basic',
}

import {
    Directive,
    ElementRef,
    Host,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Optional,
    Renderer2,
} from '@angular/core';
import {
    AbstractControl,
    FormControlDirective,
    FormControlName,
} from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { ERROR_MAPPING } from './standard-errors';

@Directive({
    selector: '[bescInputValidation]',
})
export class InputValidationDirective implements OnInit, OnDestroy {
    private _control: AbstractControl;
    private _formControlName: FormControlName;
    private _formControlDirective: FormControlDirective;

    @Input() trimOnBlur: boolean;
    @Optional() @Input() alternativeErrors: {
        [key: string]: (error) => string;
    };

    destroy$ = new Subject<void>();

    @HostListener('blur') onBlur() {
        if (this.trimOnBlur) {
            this._control.setValue(this._control.value.trim());
        }
    }

    constructor(
        @Optional() @Host() _formControlName: FormControlName,
        @Optional() @Host() _formControlDirective: FormControlDirective,
        private _renderer: Renderer2,
        private _hostElement: ElementRef
    ) {
        this._formControlName = _formControlName;
        this._formControlDirective = _formControlDirective;
    }

    ngOnInit() {
        this._setUpControl(this._formControlName, this._formControlDirective);
        this._control.statusChanges
            ?.pipe(
                startWith(this._control.status),
                takeUntil(this.destroy$)
            )
            .subscribe((status) => {
                const controlErrors = this._control.errors;
                if (status === 'INVALID' && !this._control.pristine) {
                    this._hostElement.nativeElement.classList.add('is-invalid');
                    this._renderer.removeClass(
                        this._hostElement.nativeElement,
                        'is-valid'
                    );
                } else if (status === 'VALID' && !this._control.pristine) {
                    this._renderer.addClass(
                        this._hostElement.nativeElement,
                        'is-valid'
                    );
                }

                if (status !== 'INVALID') {
                    this._renderer.removeClass(
                        this._hostElement.nativeElement,
                        'is-invalid'
                    );
                }

                const childElements =
                    this._hostElement.nativeElement.parentElement.children;
                for (const child of childElements) {
                    if (child.classList.contains('invalid-feedback')) {
                        this._renderer.removeChild(
                            this._hostElement.nativeElement.parentElement,
                            child
                        );
                    }
                }
                if (controlErrors) {
                    const firstKey = Object.keys(controlErrors)[0];
                    const getError =
                        this.alternativeErrors &&
                        this.alternativeErrors[firstKey]
                            ? this.alternativeErrors[firstKey]
                            : ERROR_MAPPING[firstKey];
                    if (getError) {
                        const div = this._renderer.createElement('div');
                        this._renderer.addClass(div, 'invalid-feedback');
                        const text = this._renderer.createText(
                            getError(controlErrors[firstKey])
                        );

                        this._renderer.appendChild(div, text);
                        this._renderer.appendChild(
                            this._hostElement.nativeElement.parentElement,
                            div
                        );
                    }
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private _setUpControl(
        formControlName: FormControlName | null,
        formControlDirective: FormControlDirective | null
    ) {
        let control: AbstractControl;
        if (formControlName !== null) {
            control = formControlName.control;
        } else if (formControlDirective !== null) {
            control = formControlDirective.control;
        } else {
            throw new Error(
                'bescFormValidation must be paired with either [formControl] or [formControlName] to work'
            );
        }
        this._control = control;
    }
}

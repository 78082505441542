<besc-navigation-title
    class="navigation-title d-flex align-items-center flex-wrap mx-10 mb-5 mb-lg-0"
></besc-navigation-title>
<ng-container *ngIf="(navigationItems$ | async).length > 0">
    <div
        class="menu d-none d-lg-flex menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-600 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold fs-6 my-5 my-lg-0 align-items-stretch"
    >
        <div
            class="menu-item me-lg-1"
            *ngFor="let item of navigationItems$ | async"
            [ngClass]="{
                'here show': item.isActive
            }"
        >
            <a
                class="menu-link menu-center"
                [attr.href]="!item.disabled ? item.link : undefined"
                (click)="navigateItem(item, $event)"
                [ngClass]="{
                    disabled: item.disabled
                }"
            >
                <fa-icon
                    class="menu-icon me-0"
                    *ngIf="item.icon"
                    [icon]="item.icon"
                ></fa-icon>
                <span class="kt-menu__link-text text-center">
                    {{ item.label }}
                </span>
            </a>
        </div>
    </div>

    <div ngbDropdown class="d-lg-none">
        <button
            type="button"
            class="btn btn-outline-primary"
            id="dropdownBasic1"
            ngbDropdownToggle
        >
            {{ activeNavItemLabel$ | async }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
                ngbDropdownItem
                *ngFor="let item of navigationItems$ | async"
                (click)="navigateItem(item, $event)"
            >
                <fa-icon
                    class="menu-icon me-0"
                    *ngIf="item.icon"
                    [icon]="item.icon"
                ></fa-icon>
                <span class="kt-menu__link-text text-center">
                    {{ item.label }}
                </span>
            </button>
        </div>
    </div>
</ng-container>

<div class="onboarding-container">
    <div class="onboarding-header">
        <bs-close-button (clickEvent)="emitClose()"></bs-close-button>
      </div>
    <div class="d-flex align-items-center justify-content-between h-100">
        <div class="w-50">
            <h1 class="onboarding-title">Welcome to the Small Group Consulting Dashboard</h1>
            <p class="onboarding-description">We've made some improvements & added new features to enhance your experience.</p>
            <div class="actions">
                <button class="bs-button bs-secondary" (click)="emitClose()">
                  Get started
                </button>
                <button class="bs-button bs-primary" (click)="watchDemos()">
                  Watch Demos
                </button>
            </div>
        </div>
        <div class="w-50 h-100">
          <img src="/assets/images/right-side.png" alt="" class="onboarding-image">
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { NavigationItem, NavigationService } from '@besc/layout';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LayoutService } from '@besc/shared';

@Component({
  selector: 'besc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  navigation$ = this._navigation.navigation$;
  navigationItems$ = this.navigation$.pipe(
      this._navigation.getNavigationItemsWithTransformedLinks()
  );
  parent$ = this.navigation$.pipe(map((navigation) => navigation.parent));

  headerText;

  constructor(
    private _navigation: NavigationService,
    private _router: Router,
    private _layout: LayoutService
  ) { }

  ngOnInit() {}

  navigateItem(item: NavigationItem, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!item.disabled) {
        this._router.navigate(
            typeof item.link === 'string' ? [item.link] : item.link
        );
    }
  }
}

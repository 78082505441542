import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { SmallEmployerFacade } from './small-employer.facade';

@Injectable({
    providedIn: 'root'
})
export class SmallEmployerListResolver implements Resolve<any> {
    constructor(private _smallEmployer: SmallEmployerFacade) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return of(this._smallEmployer.all$);
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileMenuComponent } from './components/user-profile-menu/user-profile-menu.component';
import {
    NgbDropdownModule,
    NgbPopoverModule,
} from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomPipesModule } from '@benefit-sculptor/pipes';
import { ViewActivityAsComponent } from './components/view-activity-as/view-activity-as.component';
import { FormsModule } from '@angular/forms';
import { SettingsComponent } from './components/settings/settings.component';
import { SharedModule } from '@besc/shared';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbDropdownModule,
        NgbPopoverModule,
        CustomPipesModule,
        RouterModule,
        FontAwesomeModule,
        SharedModule
    ],
    declarations: [
        UserProfileMenuComponent,
        SettingsComponent,
        ViewActivityAsComponent,
    ],
    exports: [UserProfileMenuComponent, SettingsComponent],
})
export class UserProfileModule {}

<h4 class="plan_name" style="margin-top: 16px;">{{ plan.healthPlan.issuer.name }} - {{ plan.healthPlan.name }}</h4>
<h4 class="plan_name">{{ plan.planGenericName }}</h4>
<div class="plan_row">
    <p class="plan_tag plan_text">Plan effective</p>
    <p class="plan_value plan_text">{{ plan.effectiveDate}} - {{plan.terminationDate}}</p>
</div>
<div class="plan_row">
    <p class="plan_tag plan_text">Network</p>
    <p class="plan_value plan_text">{{ plan.healthPlan.networkName}}</p>
</div>
<div class="plan_row">
    <p class="plan_tag plan_text">HSA Qualified</p>
    <p class="plan_value plan_text">{{ plan.healthPlan.hsaEligible ? 'Yes' : 'No' }}</p>
</div>
<div class="plan_row">
    <p class="plan_tag plan_text">Deductible</p>
    <p class="plan_value plan_text">{{ plan.healthPlan.details.deductibleSingle | currency }} / {{ plan.healthPlan.details.deductibleFamily | currency}}</p>
</div>
<div class="plan_row">
    <p class="plan_tag plan_text">Co-insurance</p>
    <p class="plan_value plan_text">{{ plan.healthPlan.details.coinsuranceMember | percent }}</p>
</div>
<div class="plan_row">
    <p class="plan_tag plan_text">Out-of-Pocket Max</p>
    <p class="plan_value plan_text">{{ plan.healthPlan.details.oopSingle | currency }} / {{ plan.healthPlan.details.oopFamily | currency}}</p>
</div>

<div class="close_btn" (click)="close()">
    x
</div>
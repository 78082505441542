<div class="view-activity-as">
  <div class="activity-as-header">
    <bs-close-button (clickEvent)="close(null)"></bs-close-button>
  </div>
  <div class="activity-as-body">
    <div class="view-as">
      View as: {{ selectedUser.firstName }} {{ selectedUser.lastName }}
    </div>
    <p class="view-description">
      You will be able to access this system as this agent by proxy.<br>Any saved changes you make will be visible by this agent.
    </p>
  </div>
  <div class="activity-as-actions">
    <button class="bs-button bs-secondary" (click)="close(null)">
      Cancel
    </button>
    <button class="bs-button bs-primary" (click)="submit()">
      Confirm
    </button>
  </div>
</div>
import {
    Directive,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    Output,
    Renderer2,
    SimpleChanges,
} from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';

@Directive({
    selector: '[bescDrawer]',
})
export class DrawerDirective implements OnChanges {
    private _overlayElement: ElementRef;

    @Input() direction: 'start' | 'end';
    @Input() isOpen = false;
    @Input() isAvailable = true;

    @Output() closeDrawer: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        private _renderer: Renderer2,
        private _hostElement: ElementRef,
        private _overlay: Overlay,
        @Inject(DOCUMENT) private document: Document
    ) {}

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes.isAvailable &&
            changes.isAvailable.currentValue !==
                changes.isAvailable.previousValue
        ) {
            if (changes.isAvailable.currentValue) {
                this._renderer.addClass(
                    this._hostElement.nativeElement,
                    'drawer'
                );
            } else {
                this._renderer.removeClass(
                    this._hostElement.nativeElement,
                    'drawer'
                );
            }
        }
        if (
            changes.isOpen &&
            changes.isOpen.currentValue !== changes.isOpen.previousValue &&
            this.isAvailable
        ) {
            if (changes.isOpen.currentValue) {
                this._renderer.addClass(
                    this._hostElement.nativeElement,
                    'drawer-on'
                );
                this._overlayElement = this._renderer.createElement('div');
                this._renderer.addClass(this._overlayElement, 'drawer-overlay');
                this._renderer.listen(
                    this._overlayElement,
                    'click',
                    (event) => {
                        if (event.target !== this._hostElement.nativeElement) {
                            this.closeDrawer.emit();
                        }
                    }
                );
                this._renderer.appendChild(
                    this._hostElement.nativeElement.parentElement,
                    this._overlayElement
                );
            } else {
                this._renderer.removeClass(
                    this._hostElement.nativeElement,
                    'drawer-on'
                );
                if (this._overlayElement) {
                    this._renderer.removeChild(
                        this._hostElement.nativeElement,
                        this._overlayElement
                    );
                    delete this._overlayElement;
                }
            }
        }
        if (
            changes.direction &&
            changes.direction.currentValue !==
                changes.direction.previousValue &&
            this.isAvailable
        ) {
            if (changes.direction.currentValue === 'start') {
                this._renderer.addClass(
                    this._hostElement.nativeElement,
                    'drawer-start'
                );
            } else {
                this._renderer.addClass(
                    this._hostElement.nativeElement,
                    'drawer-end'
                );
            }
        }
    }
}

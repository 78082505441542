import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'besc-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit {
    @Input() text: string;
    @Input() loading: boolean;

    constructor() {}

    ngOnInit(): void {}
}

import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class MomentNgbDateAdapterService extends NgbDateAdapter<moment.Moment> {
    fromModel(value: moment.Moment | string | null): NgbDateStruct | null {
        if (typeof value === 'string') {
            const parsedDate = moment(value, moment.HTML5_FMT.DATE);
            if (parsedDate.isValid()) {
                return {
                    day: parsedDate.date(),
                    month: parsedDate.month() + 1,
                    year: parsedDate.year(),
                };
            }
        } else if (moment.isMoment(value)) {
            return {
                day: value.date(),
                month: value.month() + 1,
                year: value.year(),
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): moment.Moment | null {
        if (date) {
            return moment(new Date(date.year, date.month - 1, date.day));
        }
        return null;
    }
}

import { Address } from '@benefit-sculptor/core';

export enum EmployerStatus {
    CLIENT = 'client',
    PROSPECT = 'prospect',
}

export enum RenewingIn {
    IN_90 = 'renewals90',
    IN_60 = 'renewals60',
    ALL = 'all',
}

export interface SmallEmployerListFilter {
    nameContains?: string;
    status?: EmployerStatus;
    state?: string;
    carrier?: string;
    renewingIn?: RenewingIn;
}

export interface SmallEmployerRequest {
    groupsOverview: GroupsOverview;
    groupData: SmallEmployer[];
}

export interface GroupsOverview {
    clients: number;
}

export interface EmployerContactInfo {
    address: string;
    city: string;
    state: string;
    zipCode: string;
    phone: string;
    website: string;
}

export interface SmallEmployer {
    id: string;
    companyName: string;
    companyDba?: string;
    contactInfo: EmployerContactInfo;
    employeeCount?: number;
    medicalPlanCount?: number;
    companyStatus?: EmployerStatus;
    companySicCode?: string;
    companyTaxId?: string;
    medicalOpenEnrollmentDate?: string;
    medicalEndDate?: string;
    keyContacts: KeyContact[];
}

export interface KeyContact {
    id: string;
    firstName: string;
    lastName: string;
    employer?: string;
    jobTitle?: string;
    phone1?: string;
    phone2?: string;
    email?: string;
}

<div class="d-flex flex-column flex-lg-row-fluid login">
    <!--begin::Body-->
    <div class="d-flex h-100">
        <!--begin::Signin-->
        <div class="w-100">
            <div>
                <h3 class="fs-1 mb-12">Please log in</h3>
            </div>
            <!--begin::Form-->
            <form [formGroup]="loginForm">
                <div class="mb-4">
                    <div class="bs-input-group">
                        <label class="bs-input-label-lg" for="username">Email address</label>
                        <input required bescInputValidation class="bs-input" type="email" placeholder="Email address"
                            formControlName="username" id="username" name="username" />
                    </div>
                </div>
                <div class="mb-4">
                    <div class="bs-input-group">
                        <label class="bs-input-label-lg" for="username">Password</label>
                        <input required class="bs-input" type="password" placeholder="************"
                            formControlName="password" id="name" name="password" />
                    </div>
                </div>
                <!--begin::Action-->
                <div>
                    <a href="javascript:;" routerLink="/auth/forgot-password" class="bs-link">
                        Forgot Password?
                    </a>
                </div>
                <div class="d-flex justify-content-end mt-4">
                    <div class="d-flex flex-column align-items-center">
                        <p class="terms">
                            Don't have an account?
                            <a href="https://calendly.com/benefit-sculptor/benefit-sculptor-demo-1" target="_blank">
                                Book demo
                            </a>
                        </p>
                        <button (click)="login()" [disabled]="loginForm.invalid || (loading$ | async)"
                            id="kt_login_signin_submit" class="bs-button bs-primary">
                            <besc-loading-indicator [loading]="loading$ | async">Sign in</besc-loading-indicator>
                        </button>
                        <p class="terms">
                            <a href="https://app.termly.io/document/privacy-policy/97d441f4-1068-422c-aedf-b737338bb116"
                                class="bs-link-inline">Privacy Policy</a>&nbsp;and&nbsp;<a
                                href="https://app.termly.io/document/terms-of-service/0e69234a-44c7-44fd-b12f-e73b8c71528a"
                                class="bs-link-inline">Terms of
                                Service</a>
                        </p>
                    </div>
                </div>
                <!--end::Action-->
            </form>
            <!--end::Form-->
        </div>
        <!--end::Signin-->
    </div>
    <!--end::Body-->
</div>
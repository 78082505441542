import { createSelector } from '@ngrx/store';
import { WizardState } from './wizard.models';
import { transformRouteToActivatedStep } from '../helpers/step-routing';
import { getCurrentSteps } from '../helpers/current-steps';
import { transformDefinitionToRoute } from '../helpers/transform';
import { createNestedFeatureSelector } from '../../helpers/selectors';

export function getWizardSelectors(path: string) {
    const selectWizardFeature = createNestedFeatureSelector<WizardState>(path);

    const getStepRouting = createSelector(
        selectWizardFeature,
        (state) => state.stepRouting
    );

    const getPlanSteps = createSelector(
        selectWizardFeature,
        (state) => state.steps
    );

    const getStepRoute = createSelector(
        getStepRouting,
        getPlanSteps,
        (routeDefinition, steps) =>
            transformDefinitionToRoute(routeDefinition, steps)
    );

    const getCurrentStepPath = createSelector(
        selectWizardFeature,
        (step) => step.pathToCurrentStep
    );

    const getCurrentValue = createSelector(
        selectWizardFeature,
        (state) => state.value
    );

    const getActivatedStep = createSelector(
        getCurrentStepPath,
        getStepRoute,
        getCurrentValue,
        (path, route, currentValue) =>
            transformRouteToActivatedStep([...path], route, currentValue)
    );

    const getSteps = createSelector(getActivatedStep, (activatedStep) =>
        getCurrentSteps(activatedStep)
    );

    const getNavigating = createSelector(
        selectWizardFeature,
        (state) => state.navigating
    );

    return {
        selectWizardFeature,
        getStepRouting,
        getPlanSteps,
        getStepRoute,
        getCurrentStepPath,
        getCurrentValue,
        getActivatedStep,
        getSteps,
        getNavigating,
    };
}

import { DeleteConfirmationModalParams } from '@besc/ui';
import { EmployerPlan, UpdateEmployeesOnPlan } from '../interfaces';
import * as moment from 'moment';

export const changeEmployeesPlanConfirmationParams = ({changes, plan } :{ changes: UpdateEmployeesOnPlan, plan: EmployerPlan }): DeleteConfirmationModalParams =>{
    const assigments = changes.filter(change => change.effectiveDate);
    const deAssigments = changes.filter(change => change.terminationDate);
    if (assigments.length > 0 && deAssigments.length > 0) {
        const terminationDate = moment(deAssigments[0].terminationDate).format('MM/DD/YYYY');
        return ({
            title: `Change plans for ${assigments.length + deAssigments.length} employees?`,
            body: `You are assigning ${assigments.length} ${assigments.length === 1 ? 'employee' : 'employees'} to ${plan.planGenericName} and
                    terminating coverage on ${terminationDate} for ${deAssigments.length} ${deAssigments.length === 1 ? 'employee' : 'employees'} assignment. Confirm?`,
            cancelButton: 'No',
            deleteButton: 'Yes',
            confirmationBtnClass: 'primary'
        });
    }

    if (assigments.length > 0) {
        return ({
            title: `Assign ${assigments.length} ${assigments.length === 1 ? 'employee' : 'employees'}?`,
            body: `You are assigning ${assigments.length} ${assigments.length === 1 ? 'employee' : 'employees'} to ${plan.planGenericName}. Confirm assignment?`,
            cancelButton: 'No',
            deleteButton: 'Yes',
            confirmationBtnClass: 'primary'
        });
    }
    
    const terminationDate = moment(deAssigments[0].terminationDate).format('MM/DD/YYYY');
    return ({
        title: `Terminate ${deAssigments.length} ${deAssigments.length === 1 ? 'employee' : 'employees'} plan assignments?`,
        body: `You are terminating coverage for ${deAssigments.length} ${deAssigments.length === 1 ? 'employee' : 'employees'} as of ${terminationDate}. Confirm removal?`,
        cancelButton: 'No',
        deleteButton: 'Yes',
        confirmationBtnClass: 'danger'
    });
}; 
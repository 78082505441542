import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'besc-registration-container',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './registration-container.component.html',
    styleUrls: ['./registration-container.component.scss'],
})
export class RegistrationContainerComponent {
    @Input() agencyRegistration?: { customerName: string; agencyName: string };
}

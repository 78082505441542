<!-- BEGIN: Left Aside -->
<div
    bescDrawer
    [isOpen]="(asideOpen$ | async) === true"
    [isAvailable]="(isLgSize$ | async) === false"
    (closeDrawer)="toggleAside()"
    direction="start"
    class="aside pb-5 pt-5 pt-lg-0"
    id="kt_aside"
    #aside
>
    <!-- begin::Brand -->
    <div class="aside-logo py-8" id="kt_aside_logo">
        <!-- begin::Logo -->
        <a
            routerLink="/"
            (click)="toggleAside()"
            class="d-flex align-items-center"
        >
            <img
                alt="Logo"
                [src]="logo$ | async"
                class="h-45px logo"
            />
        </a>
    </div>
    <!-- end::Brand  -->

    <!-- BEGIN: Aside Menu -->
    <div class="aside-menu flex-column-fluid" id="kt_aside_menu">
        <besc-aside-navigation></besc-aside-navigation>
    </div>
    <!-- END: Aside Menu -->

    <div class="aside-power-by" *ngIf="hasCustomLogo$ | async">
        Powered by<br>Benefit Sculptor
    </div>
</div>
<!-- END: Left Aside -->

import { createAction, props } from '@ngrx/store';
import {
    StepRoute,
    StepRouteDefinition,
    Steps,
    WizardState,
} from './wizard.models';

export const setCurrentStepPath = createAction(
    '[Wizard] Set current step path',
    props<{
        key: string;
        path: string[];
        previous?: {
            value: any;
            key: string;
        };
    }>()
);

export const setStepRouting = createAction(
    '[Wizard] Set Step Routing',
    props<{
        key: string;
        stepRouting: StepRouteDefinition;
        steps: Steps;
    }>()
);

export const setStepRoute = createAction(
    '[Wizard] Set Step Route',
    props<{
        key: string;
        stepRoute: StepRoute;
    }>()
);

export const setStepValue = createAction(
    '[Wizard] Set step value',
    props<{
        key: string;
        value: any;
        stepKey: string;
    }>()
);

export const setValue = createAction(
    '[Wizard] Set value',
    props<{
        key: string;
        value: any;
    }>()
);

export const navigateToStep = createAction(
    '[Wizard] Navigate to Step',
    props<{
        key: string;
        path: string[];
    }>()
);

export const startNavigating = createAction(
    '[Wizard] Start Navigating',
    props<{
        key: string;
    }>()
);

export const finishedNavigating = createAction(
    '[Wizard] Finished Navigating',
    props<{
        key: string;
    }>()
);

export const goToNextStep = createAction(
    '[Wizard] Navigate to Next Step',
    props<{
        key: string;
        value: any;
    }>()
);

export const goToPreviousStep = createAction(
    '[Wizard] Navigate to Previous Step',
    props<{
        key: string;
        value?: any;
    }>()
);

export const resetState = createAction(
    '[Wizard] Reset State',
    props<{
        state?: Partial<WizardState>;
    }>()
);

export const finishWizard = createAction(
    '[Wizard] Finish Wizard',
    props<{
        key: string;
    }>()
);
export const savedWizard = createAction(
    '[Wizard] Saved Wizard',
    props<{
        key: string;
    }>()
);

<ng-container [formGroup]="ratesForm">
    <h4>Composite Rates</h4>
    <p>
        If you would like to install this plan with composite rates please enter
        the rates below.
    </p>
    <ng-container class="" formGroupName="rates">
        <div class="composite-form" formGroupName="composite">
            <div class="row">
                <div class="col-md mb-4">
                    <label for="single" class="form-label required"
                        >Single</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="single"
                            class="form-control"
                            placeholder="Single"
                            type="number"
                            formControlName="single"
                            name="single"
                            bescInputValidation
                        />
                    </div>
                </div>
                <div class="col-md mb-4">
                    <label for="singleAndChildren" class="form-label required"
                        >Single and Children</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="singleAndChildren"
                            class="form-control"
                            placeholder="Single and Children"
                            type="number"
                            formControlName="singleAndChildren"
                            name="singleAndChildren"
                            bescInputValidation
                        />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md mb-4">
                    <label for="singleAndSpouse" class="form-label required"
                        >Single and Spouse</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="singleAndSpouse"
                            class="form-control"
                            placeholder="Single and Spouse"
                            type="number"
                            formControlName="singleAndSpouse"
                            name="singleAndSpouse"
                            bescInputValidation
                        />
                    </div>
                </div>
                <div class="col-md mb-4">
                    <label for="family" class="form-label required"
                        >Family</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="family"
                            class="form-control"
                            placeholder="Family"
                            type="number"
                            formControlName="family"
                            name="family"
                            bescInputValidation
                        />
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { HeaderService } from '@benefit-sculptor/header';

@Component({
    selector: 'besc-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit {
    @ViewChild('ktPageTitle', { static: true }) ktPageTitle: ElementRef;
    @ViewChild('preActions', { static: true, read: ViewContainerRef })
    preActions: ViewContainerRef;
    @ViewChild('actions', { static: true, read: ViewContainerRef })
    actions: ViewContainerRef;

    constructor(private _header: HeaderService) {}

    ngOnInit(): void {
        this._header.pagePreActions$
            .pipe(distinctUntilChanged())
            .subscribe((component) => {
                this.preActions.clear();
                if (component !== null) {
                    this.preActions.createComponent(component);
                }
            });

        this._header.pageActions$
            .pipe(distinctUntilChanged())
            .subscribe((component) => {
                this.actions.clear();
                if (component !== null) {
                    this.actions.createComponent(component);
                }
            });
    }

    ngAfterViewInit() {
        // if (this.ktPageTitle) {
        //     for (const key in this.pageTitleAttributes) {
        //         if (
        //             this.pageTitleAttributes.hasOwnProperty(key) &&
        //             this.ktPageTitle.nativeElement
        //         ) {
        //             this.ktPageTitle.nativeElement.attributes[key] =
        //                 this.pageTitleAttributes[key];
        //         }
        //     }
        // }
    }
}

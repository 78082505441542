<mat-accordion>
    <!-- <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header class="centered-description">
            <mat-panel-title> Issuer Information </mat-panel-title>
            <mat-panel-description>
                {{ plan.issuer.name }} | {{ plan.info.networkName }}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="item-table">
            <div class="title">Name</div>
            <div class="item">{{ plan.issuer.name }}</div>
            <div class="title">Phone</div>
            <div class="item">{{ plan.issuer.phone }}</div>
            <div class="title">Address</div>
            <div class="item">
                <div
                    *ngIf="
                        plan.issuer.address && plan.issuer.city;
                        else noAddress
                    "
                >
                    {{ plan.issuer.address }}<br />{{ plan.issuer.city }},
                    {{ plan.issuer.state }} {{ plan.issuer.zipCode }}
                </div>
                <ng-template #noAddress>
                    {{ plan.issuer.state }} {{ plan.issuer.zipCode }}
                </ng-template>
            </div>
            <div class="title">Website</div>
            <div class="item">
                <a
                    [href]="plan.issuer.website"
                    *ngIf="plan.issuer.website"
                    target="_blank"
                    >{{ plan.issuer.website }}</a
                >
                <span *ngIf="!plan.issuer.website">No Website Listed</span>
            </div>
        </div>
    </mat-expansion-panel> -->
    <mat-expansion-panel
        class="mat-elevation-z0"
        (opened)="planDetailsExpanded = true"
        (closed)="planDetailsExpanded = false"
    >
        <mat-expansion-panel-header class="centered-description">
            <mat-panel-title> Plan Information </mat-panel-title>
            <mat-panel-description>
                <span *ngIf="!planDetailsExpanded"> Click to view </span>
                <span *ngIf="planDetailsExpanded"> Click to collapse </span>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="item-table">
            <div class="title">Name</div>
            <div class="item">{{ plan.name }}</div>
            <div class="title">Type</div>
            <div class="item">{{ plan.info.type }}</div>
            <div class="title">Actuarial Value</div>
            <div class="item">
                {{
                    plan.details.actuarialValue > 0
                        ? plan.details.actuarialValue
                        : 'N/A'
                }}
            </div>
            <div class="title">Metallic Level</div>
            <div class="item">
                {{ plan.info.metallicLevel | titlecase }}
            </div>
            <div class="title">Network</div>
            <div class="item">{{ plan.info.networkName }}</div>
            <div class="title">HSA</div>
            <div class="item">
                {{ plan.info.hsaEligible ? 'Yes' : 'No' }}
            </div>
            <div class="title">Insurance Carrier Name</div>
            <div class="item">{{ plan.issuer.name }}</div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header class="centered-description">
            <mat-panel-title> Core Plan Benefits </mat-panel-title>
            <mat-panel-description>
                Deductible -
                {{ plan.details.deductibleSingle | currency }}/{{
                    plan.details.deductibleFamily | currency
                }}
                | OOP -
                {{ plan.details.oopSingle | currency }}/{{
                    plan.details.oopFamily | currency
                }}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="item-table pb-8 border-bottom">
            <div class="title">Deductible (Single/Family)</div>
            <div class="item">
                {{ plan.details.deductibleSingle | currency }}/{{
                    plan.details.deductibleFamily | currency
                }}
            </div>
            <div class="title">Deductible Non-Network (Single/Family)</div>
            <div class="item" *ngIf="plan.coverage.outOfNetworkCoverage">
                {{ plan.details.deductibleSingleNonNetwork | currency }}/{{
                    plan.details.deductibleFamilyNonNetwork | currency
                }}
            </div>
            <div class="item" *ngIf="!plan.coverage.outOfNetworkCoverage">
                Not covered
            </div>
            <div class="title">Max Out of Pocket (Single/Family)</div>
            <div class="item">
                {{ plan.details.oopSingle | currency }}/{{
                    plan.details.oopFamily | currency
                }}
            </div>
            <div class="title">
                Max Out of Pocket Non-Network (Single/Family)
            </div>
            <div class="item" *ngIf="plan.coverage.outOfNetworkCoverage">
                {{ plan.details.oopSingleNonNetwork | currency }}/{{
                    plan.details.oopSingleNonNetwork | currency
                }}
            </div>
            <div class="item" *ngIf="!plan.coverage.outOfNetworkCoverage">
                Not covered
            </div>
            <div class="title">Co-Insurance (Employee Cost)</div>
            <div class="item">
                {{ plan.details.coinsuranceMember | percent }}
            </div>
        </div>
        <div class="details-table item-table mt-8">
            <div class="plan-details-header">Type</div>
            <div class="plan-details-header">Value</div>
            <div class="plan-details-header">
                Integrated with Medical Deductible
            </div>
            <div class="plan-details-header">Description</div>
            <div class="title">Rx Deductible Single</div>
            <div class="value">
                {{ plan.details.rxDeductibleSingleValue | currency }}
            </div>
            <div class="integrated">
                {{ plan.details.rxMedicalDeductibleIntegration ? 'Yes' : 'No' }}
            </div>
            <div class="description">
                {{ plan.details.rxDeductibleSingle }}
            </div>
            <div class="title">Rx Deductible Family</div>
            <div class="value">
                {{ plan.details.rxDeductibleFamilyValue | currency }}
            </div>
            <div class="integrated">
                {{ plan.details.rxMedicalDeductibleIntegration ? 'Yes' : 'No' }}
            </div>
            <div class="description">
                {{ plan.details.rxDeductibleFamily }}
            </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-elevation-z0">
        <mat-expansion-panel-header class="centered-description">
            <mat-panel-title> Additional Plan Benefits </mat-panel-title>
            <mat-panel-description>
                Primary Care:&nbsp;{{
                    plan.coverage.pcpValueType | titlecase
                }}&nbsp;|&nbsp;Specialist Care:&nbsp;{{
                    plan.coverage.specialistValueType | titlecase
                }}
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="row">
            <div class="col">
                <h6>Primary Care Physician</h6>
                <div class="item-table 2-column">
                    <div class="title">Type</div>
                    <div class="item">
                        {{ plan.coverage.pcpValueType | titlecase }}
                    </div>
                    <div class="title">Value</div>
                    <div class="item">
                        <span
                            *ngIf="plan.coverage.pcpValueType === 'coinsurance'"
                            >{{ plan.coverage.pcpValue | percent }}</span
                        >
                        <span *ngIf="plan.coverage.pcpValueType === 'copay'">{{
                            plan.coverage.pcpValue | currency
                        }}</span>
                    </div>
                    <div class="title">Deductible Waivers?</div>
                    <div class="item">
                        {{ plan.coverage.pcpDedWaiver ? 'Yes' : 'No' }}
                    </div>
                    <div class="title" *ngIf="plan.coverage.pcpDedWaiver">
                        Waiver Limit
                    </div>
                    <div class="item" *ngIf="plan.coverage.pcpDedWaiver">
                        {{ plan.coverage.pcpWaiverLimit }}
                    </div>
                </div>
            </div>
            <div class="col">
                <h6>Specialist</h6>
                <div class="item-table 2-column">
                    <div class="title">Type</div>
                    <div class="item">
                        {{ plan.coverage.specialistValueType | titlecase }}
                    </div>
                    <div class="title">Value</div>
                    <div class="item">
                        <span
                            *ngIf="
                                plan.coverage.specialistValueType ===
                                'coinsurance'
                            "
                            >{{ plan.coverage.specialistValue | percent }}</span
                        >
                        <span
                            *ngIf="
                                plan.coverage.specialistValueType === 'copay'
                            "
                            >{{
                                plan.coverage.specialistValue | currency
                            }}</span
                        >
                    </div>
                    <div class="title">Deductible Waivers?</div>
                    <div class="item">
                        {{ plan.coverage.specialistDedWaiver ? 'Yes' : 'No' }}
                    </div>
                    <div
                        class="title"
                        *ngIf="plan.coverage.specialistDedWaiver"
                    >
                        Waiver Limit
                    </div>
                    <div class="item" *ngIf="plan.coverage.specialistDedWaiver">
                        {{ plan.coverage.specialistWaiverLimit }}
                    </div>
                </div>
            </div>
        </div>
        <hr class="w-100" />
        <div class="item-table coverage-table">
            <div class="title">Preventive Care</div>
            <div class="item">{{ plan.coverage.preventiveCare }}</div>
            <div class="title">Out of Network Care</div>
            <div class="item">
                {{ plan.coverage.outOfNetworkCoverage ? 'Yes' : 'No' }}
            </div>
            <div class="title">Urgent Care</div>
            <div class="item">{{ plan.coverage.urgentCare }}</div>
            <div class="title">Emergency Room</div>
            <div class="item">{{ plan.coverage.emergencyRoom }}</div>
            <div class="title">Ambulance</div>
            <div class="item">{{ plan.coverage.ambulance }}</div>
            <div class="title">Diagnostic Test</div>
            <div class="item">{{ plan.coverage.diagnosticTest }}</div>
            <div class="title">Inpatient Birth</div>
            <div class="item">{{ plan.coverage.inpatientBirth }}</div>
            <div class="title">Prenatal Care</div>
            <div class="item">{{ plan.coverage.prenatalCare }}</div>
            <div class="title">Postnatal Care</div>
            <div class="item">{{ plan.coverage.postnatalCare }}</div>
            <div class="title"></div>
            <div class="item"></div>
            <div class="title">Inpatient Facility</div>
            <div class="item">{{ plan.coverage.inpatientFacility }}</div>
            <div class="title">Outpatient Facility</div>
            <div class="item">{{ plan.coverage.outpatientFacility }}</div>
            <div class="title">Inpatient Physician</div>
            <div class="item">{{ plan.coverage.inpatientPhysician }}</div>
            <div class="title">Outpatient Physician</div>
            <div class="item">{{ plan.coverage.outpatientPhysician }}</div>
            <div class="title">Inpatient Mental Health</div>
            <div class="item">{{ plan.coverage.inpatientMentalHealth }}</div>
            <div class="title">Outpatient Mental Health</div>
            <div class="item">{{ plan.coverage.outpatientMentalHealth }}</div>
            <div class="title">Inpatient Substance Abuse</div>
            <div class="item">{{ plan.coverage.inpatientSubstanceAbuse }}</div>
            <div class="title">Outpatient Substance Abuse</div>
            <div class="item">{{ plan.coverage.outpatientSubstanceAbuse }}</div>
            <div class="title">Rehabilitation Services</div>
            <div class="item">{{ plan.coverage.rehabilitationServices }}</div>
            <div class="title">Skilled Nursing</div>
            <div class="item">{{ plan.coverage.skilledNursing }}</div>
            <div class="title">Home Health Care</div>
            <div class="item">{{ plan.coverage.homeHealthCare }}</div>
            <div class="title">Hospice</div>
            <div class="item">{{ plan.coverage.hospice }}</div>
            <div class="title">Chiropractic Services</div>
            <div class="item">
                {{ plan.coverage.chiropracticServices ? 'Yes' : 'No' }}
            </div>
            <div class="title">Imaging</div>
            <div class="item">{{ plan.coverage.imaging }}</div>
            <div class="title">Imaging Center</div>
            <div class="item">{{ plan.coverage.imagingCenter }}</div>
            <div class="title">Imaging Physician</div>
            <div class="item">{{ plan.coverage.imagingPhysician }}</div>
        </div>
    </mat-expansion-panel>
    <besc-accordion-rates
        [rates]="plan.planRates"
        [planInstallation]="planInstallation"
        [ratesForm]="ratesForm"
    >
    </besc-accordion-rates>
</mat-accordion>

import { Component } from '@angular/core';

@Component({
    selector: 'besc-chevron-left-icon',
    standalone: true,
    template: `<svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M14.2951 6L15.7051 7.41L11.1251 12L15.7051 16.59L14.2951 18L8.29508 12L14.2951 6Z"
            fill="currentColor"
        />
    </svg>`,
})
export class ChevronLeftIconComponent {}

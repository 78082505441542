import { ComponentFactory, Injectable, Type } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RouteParserService } from '../routing/services/route-parser.service';

@Injectable({
    providedIn: 'root',
})
export class HeaderService {
    private _routeData = this._router.events.pipe(
        filter((e) => e instanceof NavigationEnd),
        startWith(this._route),
        map(() => this._route),
        map((route) => {
            return this._routeParser.getAllRouteData(route);
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    pageTitle$: Observable<string> = this._routeData.pipe(
        map((data) => {
            const topicPage = data.topicPage;
            if (topicPage) {
                const url = this._router.url;
                if (url.indexOf('custom-plans') > -1) {
                    return 'Video Topics: Custom Plans';
                } else if (url.indexOf('employers') > -1) {
                    return 'Video Topics: Employers';
                } else if (url.indexOf('employer-info') > -1) {
                    return 'Video Topics: Employer Info';
                } else if (url.indexOf('employees') > -1) {
                    return 'Video Topics: Employees';
                } else if (url.indexOf('plans') > -1) {
                    return 'Video Topics: Plans';
                } else if (url.indexOf('quotes') > -1) {
                    return 'Video Topics: Quotes';
                }
            }

            const pageTitle = data.pageTitle;

            if (!pageTitle) {
                return 'benefitSculptor';
            }

            return pageTitle;
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    navigationTitle$: Observable<string> = this._routeData.pipe(
        map((data) => {
            if (data.smallEmployer) {
                return data.smallEmployer.companyName;
            }
            return null;
        }),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    pageSubheader$: Observable<ComponentFactory<any>> = this._routeData.pipe(
        map((data) =>
            data.subHeaderComponent ? data.subHeaderComponent : null
        ),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    pageActions$: Observable<Type<any>> = this._routeData.pipe(
        map((data) => (data.actions ? data.actions : null)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    pagePreActions$: Observable<Type<any>> = this._routeData.pipe(
        map((data) => (data.preActions ? data.preActions : null)),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _routeParser: RouteParserService
    ) {}

    // private _getRouteAfterNavigation(): Observable<{
    //     route: ActivatedRoute;
    //     title: string;
    // }> {
    //     return this._router.events.pipe(
    //         filter(event => event instanceof NavigationEnd),
    //         map(() => this._route),
    //         // Traverse over the state tree to find the last activated route, and then return it to the stream
    //         map(route => {
    //             let title = 'Simplifile';
    //             let pageTitle = '';
    //             while (route.firstChild) {
    //                 route = route.firstChild;
    //                 const partTitle = this._getRoutePartTitle(route);
    //                 if (partTitle !== '') {
    //                     title = partTitle + ' | ' + title;
    //                 }
    //                 pageTitle = route.snapshot.data.pageTitle;
    //             }
    //             return { route, title, pageTitle };
    //         })
    //     );
    // }
    //
    // private _getRoutePartTitle(route: ActivatedRoute) {
    //     let partTitle = route.snapshot.data.title;
    //     if (!partTitle) {
    //         const snapshot = route.snapshot;
    //         const params = snapshot.params;
    //         const pathParts = snapshot.routeConfig.path.split('/');
    //         if (pathParts[0] === 'app') {
    //             pathParts.shift();
    //         }
    //         partTitle = this._getTitlePart(pathParts.shift(), params);
    //         for (const part of pathParts) {
    //             const subPartTitle = this._getTitlePart(part, params);
    //             if (subPartTitle !== '' && partTitle !== '') {
    //                 partTitle =
    //                     this._getTitlePart(part, params) + ' - ' + partTitle;
    //             } else if (subPartTitle !== '' && partTitle === '') {
    //                 partTitle = subPartTitle;
    //             }
    //         }
    //     }
    //
    //     return partTitle;
    // }
    //
    // private _getTitlePart(part: string, params: Params): string {
    //     if (part.startsWith(':')) {
    //         return '';
    //         // return params[part.substr(1)];
    //     }
    //
    //     return part;
    // }
}

<div class="d-flex flex-column flex-lg-row flex-column-flui bg-white h-100">
    <!--begin::Content-->
    <div class="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-1">
        <div class="logo">
            <img src="/assets/images/BS-BenefitSculptorLogo-Full.png" alt="Benefit Sculptor" />
        </div>
        <div class="d-flex flex-column flex-lg-row-fluid">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!--end::Content-->
    <!--begin::Aside-->
    <div class="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2 auth-aside">
        <div class="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
            <img src="/assets/images/right-side.png" alt="" class="d-block d-lg-block mx-auto w-275px w-lg-75" />
        </div>
    </div>
    <!--begin::Aside-->
</div>
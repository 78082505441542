import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as CreatePlansSelectors from './create-plans.selectors';
import * as CreatePlansActions from './create-plans.actions';
import {
    cloneExistingPlan,
    loadAgeRateCurve,
    loadCurrentIssuers,
    loadPlanList,
    resetPlanList,
} from './create-plans.actions';
import {
    ActivatedStep,
    CreatePlansState,
    PlanSteps,
} from './create-plans.models';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import {
    setStepRouting,
    StepRouteDefinition,
    WIZARD_SELECTORS,
    WizardFacade,
    WizardSelectors,
    WizardState,
} from '@benefit-sculptor/core';
import { CreateExistingPlanRequest, EmployerPlan, SimplePlan } from '@besc/plan';
import { CREATE_PLAN_WIZARD_KEY } from '../tokens';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class CreatePlansFacade extends WizardFacade {
    protected _currentActivatedStep$: BehaviorSubject<ActivatedStep> = new BehaviorSubject<ActivatedStep>(
        null
    );
    wizardKey = this._wizardKey;
    steps$ = this._store.select(this._selectors.getSteps);
    currentValues$ = this._store.select(this._selectors.getCurrentValue);
    currentStep$ = this._store.select(this._selectors.getActivatedStep).pipe(
        filter((step) => !!step),
        tap((step: ActivatedStep) => this._currentActivatedStep$.next(step))
    );
    stepRouting$ = this._store
        .select(this._selectors.getStepRoute)
        .pipe(filter((routes) => !!routes));
    ageRateCurve$ = this._store
        .select(CreatePlansSelectors.getAgeRates)
        .pipe(filter((ageRateCurve) => !!ageRateCurve));
    ageRateCurveLoading$ = this._store.select(
        CreatePlansSelectors.getAgeRateCurveLoading
    );
    currentIssuers$ = this._store.select(CreatePlansSelectors.getIssuers);
    isIssuersLoading$ = this._store.select(
        CreatePlansSelectors.isIssuersLoading
    );
    wizardState$ = this._store.select(this._selectors.selectWizardFeature);
    navigating$ = this._store.select(this._selectors.getNavigating);
    currentWizardProgress$ = this._store.select(this._selectors.getSteps).pipe(
        map((steps) => {
            let totalComplete = 0;
            let placeholderCount = 0;

            for (const step of steps) {
                if (step.complete) {
                    totalComplete++;
                }
                if (step.placeholder) {
                    placeholderCount++;
                }
                if (!step.placeholder && placeholderCount > 0) {
                    totalComplete = totalComplete + placeholderCount;
                }
            }

            return Math.ceil((totalComplete / steps.length) * 100);
        })
    );
    simplePlans$ = this._store.select(CreatePlansSelectors.getPlans);
    isPlansLoading$ = this._store.select(
        CreatePlansSelectors.isPlanListLoading
    );
    isCloningExisting$ = this._store.select(
        CreatePlansSelectors.isCloningExisting
    );
    creatingPlan$ = this._store.pipe(
        select(CreatePlansSelectors.isCreatingPlan)
    );
    newEmployerPlan$ = this._store.pipe(select(CreatePlansSelectors.newPlan));

    constructor(
        protected _store: Store<{
            plans: CreatePlansState;
            wizard: WizardState;
        }>,
        @Inject(WIZARD_SELECTORS) private _selectors: WizardSelectors,
        @Inject(CREATE_PLAN_WIZARD_KEY) private _wizardKey: string,
        private _http: HttpClient,
        private _toastr: ToastrService,
    ) {
        super(_store);
    }

    get activatedStep(): ActivatedStep {
        return this._currentActivatedStep$.getValue();
    }

    setStepRouting(stepRouting: StepRouteDefinition, steps: PlanSteps) {
        this.dispatchToWizard(setStepRouting, { stepRouting, steps });
    }

    getCurrentStepValue(): any {
        return this.activatedStep.value;
    }

    setCurrentValueToForm(form: UntypedFormGroup) {
        const currentValue = this.activatedStep.value;
        if (currentValue) {
            form.patchValue(currentValue);
            form.markAsPristine();
        }
    }

    loadAgeRateCurve(zipCode: string, effectiveDate: string) {
        this.dispatchToWizard(loadAgeRateCurve, { zipCode, effectiveDate });
    }

    loadCurrentIssuers(zipCode: string, year: string) {
        this.dispatchToWizard(loadCurrentIssuers, { zipCode, year });
    }

    loadSimplePlans(zipCode: string, year: string) {
        this.dispatchToWizard(loadPlanList, { zipCode, year });
    }

    resetSimplePlans() {
        this.dispatchToWizard(resetPlanList);
    }

    clonePlan(plan: SimplePlan, zipCode: string, effectiveDate: string, employerId: string=null) {
        this.dispatchToWizard(cloneExistingPlan, {
            planId: plan.id,
            zipCode,
            effectiveDate,
            employerId,
        });
    }

    addExistingPlanToEmployer(
        employerId: string,
        plan: CreateExistingPlanRequest,
    ) {
        this._http.post<EmployerPlan>('employers/' + employerId + '/plans', plan)
        .pipe(
            tap(employerPlan => this._store.dispatch(CreatePlansActions.saveExistingPlanSuccess({ employerPlan }))),
            catchError(error => {
                this._toastr.error(error.error.detail);
                this._store.dispatch(CreatePlansActions.saveExistingPlanFailure())
                return of(null);
            })
        )
        .subscribe();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AddUserLicensesDTO, AddUserLicensesData, UserLicensesDTO } from './models/UserLicenses.model';
import { StateLicensesDTO, StateLicensesData } from './models/StateLicenses.model';
import { API_LIST } from '@benefit-sculptor/core';
import { UsersLicenseDTO } from './models/Users_License.model';
import { RoleType } from '@benefit-sculptor/auth';
import { EmployerGroupUserAssigned, EmployersGroupDTO } from './models/EmployersGroup.model';
import { ReassignUserDTO } from './models/ReassignUsers.model';
import { NewEmployerRequest, NewEmployerResponse } from './models/NewEmployer.model';
import { ManageUsersUserDetail } from './models/ManageUsersUserDetail.model';
import { LabelValueRecord } from '@besc/shared';

@Injectable({
    providedIn: 'root',
})
export class ManageService {
    constructor(protected _http: HttpClient) {}

    getUsers(): Observable<UsersLicenseDTO> {
        return this._http.get<UsersLicenseDTO>('/admin/users')
            .pipe(
                map((data) => ({ ...data, users: data.users.map(user => ({ ...user, role: user.role.toLowerCase() as RoleType })) }))
            );
    }

    createUser(data): Observable<any> {
        return this._http.post<any>('/admin/users', data);
    }

    getUser(id: string): Observable<ManageUsersUserDetail> {
        return this._http.get<ManageUsersUserDetail>(`/admin/users/${id}`);
    }

    updateUser(id, data): Observable<any> {
        return this._http.patch<any>(`/admin/users/${id}`, data);
    }

    uploadUserAvatar(id, data): Observable<any> {
        return this._http.post<any>(`/users/update-avatar/${id}`, data);
    }

    deleteUser(id): Observable<any> {
        return this._http.delete<any>(`/admin/users/${id}`);
    }

    getEmployerGroups(): Observable<EmployersGroupDTO[]> {
        return this._http.get<EmployersGroupDTO[]>('/admin/employerGroup');
    }

    getEmployerGroupsForUser(userId: string): Observable<EmployersGroupDTO[]> {
        return this._http.get<EmployersGroupDTO[]>(`/admin/user/${userId}/employerGroup`);
    }

    createEmployerGroup(data: NewEmployerRequest): Observable<NewEmployerResponse> {
        return this._http.post<NewEmployerResponse>('/admin/employerGroup', data);
    }

    updateEmployerGroup(data): Observable<any> {
        return this._http.patch<any>('/admin/employerGroup', data);
    }

    deleteEmployerGroup(id: string): Observable<any> {
        return this._http.delete<any>(`/admin/employerGroup/${id}`);
    }

    assignSubAgent(id: string, userId: string): Observable<any> {
        return this._http.post<any>(`/admin/employerGroup/${id}/assign-users`, {
            is_primary: false,
            user: userId,
        });
    }

    reassignUser(employerId: string, originalUser: string, newUser: string): Observable<ReassignUserDTO> {
        const body: ReassignUserDTO = {
            toBeReplaced: originalUser,
            replacement: newUser
        };
        return this._http.post<ReassignUserDTO>(`/admin/employerGroup/${employerId}/reassign-users`, body);
    }

    unassignUser(id: string, userId: string): Observable<any> {
        return this._http.post<any>(
            `/admin/employerGroup/${id}/unassign-users`,
            {
                users: [userId],
            }
        );
    }

    createAgency(data): Observable<any> {
        return this._http.post<any>('/admin/agency', data);
    }

    getAgency(id): Observable<any> {
        return this._http.get<any>(`/admin/agency/${id}`);
    }

    updateAgency(id, data): Observable<any> {
        return this._http.patch<any>(`/admin/agency/${id}`, data);
    }

    deleteAgency(id): Observable<any> {
        return this._http.delete<any>(`/admin/agency/${id}`);
    }

    uploadAgencyLogo(id, data): Observable<any> {
        return this._http.post<any>(`/admin/agency/${id}/logo`, data);
    }

    getLicenses(id: string): Observable<UserLicensesDTO> {
        return this._http.get<UserLicensesDTO>(`/admin/agency/${id}/licenses`);
    }

    getStates(id): Observable<any> {
        return this._http.get<any>(`/admin/agency/${id}/states`);
    }

    removeState(id, stateId): Observable<any> {
        return this._http.delete<any>(
            `/admin/agency/${id}/remove-states/${stateId}`
        );
    }

    completeUserPurchase(data: AddUserLicensesData): Observable<AddUserLicensesDTO> {
        return this._http.post<AddUserLicensesDTO>(API_LIST.ADD_USER_LICENSES, data);
    }
    
    completeStatesPurchase(data: StateLicensesData): Observable<StateLicensesDTO> {
        return this._http.post<StateLicensesDTO>(API_LIST.ADD_STATES_LICENSES, data);
    }

    getUserAgency(userId): Observable<any> {
        return this._http.get<any>(`/agencies/${userId}/agency`);
    }

    getUniqueAssignedUsersForGroup(
        group: EmployersGroupDTO,
        currentAssignedUsers: LabelValueRecord<EmployerGroupUserAssigned>[]
    ): LabelValueRecord<EmployerGroupUserAssigned>[] {
        return group.users
        .filter(groupUser => !currentAssignedUsers.some(assignedUser => assignedUser.value.id === groupUser.id))
        .map(groupUser => ({
          label: `${groupUser.firstName} ${groupUser.lastName}`,
          value: groupUser,
        }));
    }

    getUniqueAssignedSubagentsForGroup(
        group: EmployersGroupDTO,
        currentAssignedUsers: LabelValueRecord<EmployerGroupUserAssigned>[]
    ): LabelValueRecord<EmployerGroupUserAssigned>[] {
        return group.subAgents
        .filter(subAgent => !currentAssignedUsers.some(assignedUser => assignedUser.value.id === subAgent.id))
        .map(subAgent => ({
          label: `${subAgent.firstName} ${subAgent.lastName}`,
          value: subAgent,
        }));
    }
}

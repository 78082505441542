import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Agency } from '@besc/agency';
import { AuthFacadeService, RecaptchaService } from '@benefit-sculptor/auth';

@Component({
    selector: 'besc-add-new-agency-employee-dialog',
    templateUrl: './add-new-agency-employee-dialog.component.html',
    styleUrls: ['./add-new-agency-employee-dialog.component.css'],
})
export class AddNewAgencyEmployeeDialogComponent implements OnInit, OnDestroy {
    private _destroy = new Subject();

    @Input() agency: Agency;

    newAgencyEmployeeForm = this._fb.group({
        firstName: [
            '',
            Validators.compose([
                Validators.required,
                Validators.maxLength(100),
            ]),
        ],
        lastName: [
            '',
            Validators.compose([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(100),
            ]),
        ],
        email: [
            '',
            {
                validators: Validators.compose([
                    Validators.required,
                    Validators.email,
                    Validators.maxLength(256),
                ]),
                updateOn: 'blur',
            },
        ],
        jobTitle: [],
    });
    loading$ = this._auth.loading$;

    constructor(
        private _fb: UntypedFormBuilder,
        private _activeModal: NgbActiveModal,
        private _auth: AuthFacadeService,
        private _recaptcha: RecaptchaService,
    ) {}

    ngOnInit() {
        this.loading$.pipe(takeUntil(this._destroy)).subscribe((loading) => {
            if (loading) {
                this.newAgencyEmployeeForm.disable();
            } else {
                this.newAgencyEmployeeForm.enable();
            }
        });
    }

    ngOnDestroy() {
        this._destroy.next();
    }

    dismiss(reason: any) {
        this._activeModal.dismiss(reason);
    }

    async addNewAgencyEmployee() {
        const recaptcha = await this._recaptcha.getRecaptcha('registerUser');
        this._auth.register({
            ...this.newAgencyEmployeeForm.value,
            agencyId: this.agency.id,
            recaptcha
        });
        this._auth.registered$.subscribe((response) => {
            this._activeModal.close({
                ...this.newAgencyEmployeeForm.value,
                id: response.id,
            });
        });
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to validators name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.newAgencyEmployeeForm.get(controlName);
        if (!control) {
            return false;
        }

        return (
            control.hasError(validationType) &&
            (control.dirty || control.touched)
        );
    }
}

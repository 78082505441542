import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    AsideLeftComponent,
    BaseLayoutComponent,
    LayoutWrapperComponent,
    NoSideLayoutComponent,
} from './components';
import { RouterModule } from '@angular/router';
import { HeaderModule } from '@benefit-sculptor/header';
import { NavigationModule } from '@benefit-sculptor/navigation';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { CoreModule } from '@benefit-sculptor/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HeaderModule,
        NavigationModule,
        CoreModule,
    ],
    declarations: [
        AsideLeftComponent,
        BaseLayoutComponent,
        LayoutWrapperComponent,
        NoSideLayoutComponent,
        PageTitleComponent,
        SidebarComponent,
        ToolbarComponent,
        NavigationBarComponent
    ],
    exports: [
        AsideLeftComponent,
        BaseLayoutComponent,
        LayoutWrapperComponent,
        NoSideLayoutComponent,
    ],
})
export class LayoutModule {}

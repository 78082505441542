<div class="modal-header">
    <h4 class="modal-title">Are you sure?</h4>
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm"
        aria-label="Close"
        (click)="dismiss('Cross click')"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
</div>
<div class="modal-body" *ngIf="user$ | async as user">
    <p>
        Are you sure you want to transfer ownership of the agency to
        {{ newOwner.firstName }} {{ newOwner.lastName }}?
    </p>
    <p *ngIf="!user.isAdmin">
        You will no longer be the owner. You will no longer be able to make
        changes to the agency like adding and removing employees.
    </p>
    <p *ngIf="user.isAdmin">
        {{ agency.owner.firstName }} {{ agency.owner.lastName }} will no longer
        be the owner. They will no longer be able to make changes to the agency
        like adding and removing employees.
    </p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-secondary"
        (click)="dismiss('Cancel click')"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                working$ | async
        }"
        (click)="transferOwnership()"
    >
        Transfer Ownership
    </button>
</div>

import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomError, CustomHttpError } from './custom-error';

declare let heap: any;
@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(@Inject(Injector) private readonly injector: Injector) {}

  private get _toastr() {
      return this.injector.get(ToastrService);
  }
  handleError(error: CustomError | CustomHttpError) {
    console.error(`error:`, error);
    let tag = 'Error';
    if (error.additionalInfo?.ngrx) {
      tag = 'NGRX Errors';
      this._toastr.error('An internal error has occurred. Please refresh the page');
    }
    if (error.additionalInfo?.http) {
      tag = 'Http Errors';
    }
    heap.track(
      tag,
      {
        message: error.message ?? '',
        stack: error.stack ?? '',
        additionalInfo: error.additionalInfo?.data ? JSON.stringify(error.additionalInfo.data) : '',
      });
  }
}

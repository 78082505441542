import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { VimeoService } from '../services/vimeo.service';
import { DemoCategory, VimeoVideo } from '../models/Vimeo-videos';
import { NgbAccordion, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'besc-demos-player',
    templateUrl: './demos-player.component.html',
    styleUrls: ['./demos-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemosPlayerComponent implements OnInit {
    @ViewChild('ngbAccordion') ngbAccordion!: NgbAccordion;
    @Output() closeModal = new EventEmitter<void>();
    categories$!: Observable<DemoCategory[]>;
    selectedVideo$$ = new BehaviorSubject<VimeoVideo | null>(null);
    selectedVideoIndex$$ = new BehaviorSubject<number>(0);
    selectedVideoCategoryIndex$$ = new BehaviorSubject<number>(0);
    allCategoriesPanelsAreClosed$$ = new BehaviorSubject<boolean>(false);
    readonly CATEGORY_PANEL_ID_PREFIX = 'category-panel-';

    constructor(private _vimeo: VimeoService) { }
  
    ngOnInit(): void {
      this.categories$ = this._vimeo.getVideos()
        .pipe(
          tap((categories) => this.onSelectVideo(categories[0].videos[0], 0, 0)),
        );
    }
  
    onSelectVideo(video: VimeoVideo, selectedVideoIndex: number, categoryIndex: number): void {
      this.selectedVideo$$.next(video);
      this.selectedVideoIndex$$.next(selectedVideoIndex);
      this.selectedVideoCategoryIndex$$.next(categoryIndex);
    }

    panelChange(event: NgbPanelChangeEvent, categories: DemoCategory[]): void {
      setTimeout(() => {
        this.allCategoriesPanelsAreClosed$$.next(this.ngbAccordion.activeIds.length === 0);
        const eventCategoryIndex = Number(event.panelId.split(this.CATEGORY_PANEL_ID_PREFIX)[1]);
        const selectedVideoPanelIsClosed = ![ ...this.ngbAccordion.activeIds]
          .map((activeId) => Number(activeId.split(this.CATEGORY_PANEL_ID_PREFIX)[1]))
          .includes(this.selectedVideoCategoryIndex$$.value);
        if (eventCategoryIndex !== this.selectedVideoCategoryIndex$$.value && event.nextState && selectedVideoPanelIsClosed) {
          this.onSelectVideo(categories[eventCategoryIndex].videos[0], 0, eventCategoryIndex);
        }
      }, 0);
    }
  
    emitClose(): void {
        this.closeModal.emit();
    }
}

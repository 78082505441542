import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'besc-help-modal',
    templateUrl: './help-modal.component.html',
    styleUrls: ['./help-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HelpModalComponent {
    constructor(private _activeModal: NgbActiveModal) { }

    seeVideos(): void {
        this._activeModal.close({ seeVideos : true });
    }

    close(): void {
        this._activeModal.close();
    }
}

<div
    class="hover-scroll-overlay-y my-2 my-lg-5 pe-lg-n1"
    id="kt_aside_menu_wrapper"
    data-kt-scroll="true"
    data-kt-scroll-height="auto"
    data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
    data-kt-scroll-wrappers="#kt_aside, #kt_aside_menu"
    data-kt-scroll-offset="5px"
>
    <div
        class="menu menu-column menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold"
        id="kt_aside_menu"
        data-kt-menu="true"
    >
        <div
            class="menu-item py-3"
            *ngIf="parent$ | async as parent"
            [ngClass]="{
                'here show': parent.isActive
            }"
        >
            <a
                class="menu-link menu-center"
                [attr.href]="!parent.disabled ? parent.link : undefined"
                (click)="navigateItem(parent, $event)"
                [ngClass]="{
                    disabled: parent.disabled
                }"
            >
                <fa-icon
                    class="kt-menu__link-icon"
                    [icon]="['fad', 'long-arrow-left']"
                ></fa-icon>
                <span class="kt-menu__link-text text-center">
                    {{ parent.label }}
                </span>
            </a>
        </div>
        <div
            class="menu-item py-3"
            *ngFor="let item of navigationItems$ | async"
            [ngClass]="{
                'here show': item.isActive
            }"
        >
            <a
                class="menu-link menu-center"
                [attr.href]="!item.disabled ? item.link : undefined"
                (click)="navigateItem(item, $event)"
                [ngClass]="{
                    disabled: item.disabled
                }"
            >
                <fa-icon
                    class="menu-icon me-0"
                    *ngIf="item.icon"
                    [icon]="item.icon"
                ></fa-icon>
                <span class="menu-title text-center">
                    {{ item.label }}
                </span>
            </a>
            <hr class="section-separator" *ngIf="item.separator" />
        </div>
        <!--                <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>-->
    </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment';
import { map, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as CreatePlansActions from './create-plans.actions';
import { CreatePlansFacade } from '../+state/create-plans.facade';
import { createPlansDataKey, CreatePlansState } from './create-plans.models';
import {
    AgeRateCurveResponse,
    EmployerPlan,
    Plan,
    RateType,
    SimplePlan,
} from '../../interfaces';
import { PlanFacade } from '../../services/plan.facade';
import { DataPersistence } from '@nrwl/angular';
import { Action } from '@ngrx/store';
import * as dotProp from 'dot-prop';
import { FullPlan } from '../interfaces';
import { tranformPlanToForm } from '../helpers/transform';
import {
    CustomError,
    finishWizard,
    formatMomentProperties,
    getParamsFromRoute,
    savedWizard,
} from '@benefit-sculptor/core';
import * as clone from 'clone';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CREATE_PLAN_WIZARD_KEY } from '../tokens';

@Injectable()
export class CreatePlansEffects {
    getCurrentIssuers$ = createEffect(() =>
        this._actions$.pipe(
            ofType(CreatePlansActions.loadCurrentIssuers),
            switchMap((action) =>
                this._plan.getCurrentIssuers(action.zipCode, action.year)
            ),
            map((issuers) =>
                CreatePlansActions.setCurrentIssuers({
                    issuers: issuers,
                })
            )
        )
    );

    getAgeRateCurve$ = createEffect(() =>
        this._dataPersistence.fetch(CreatePlansActions.loadAgeRateCurve, {
            run: (
                action: ReturnType<typeof CreatePlansActions.loadAgeRateCurve>,
                state: any
            ) => {
                const currentState = dotProp.get(
                    state,
                    createPlansDataKey
                ) as CreatePlansState;

                if (!currentState.ageRateCurve.curve) {
                    return this._plan.getAgeRateCurve(action.zipCode).pipe(
                        map((ageRateCurve: AgeRateCurveResponse) => {
                            const rates: number[] = [];

                            for (let i = 0; i <= 65; i++) {
                                if (ageRateCurve['age' + i]) {
                                    rates[i] = parseFloat(
                                        ageRateCurve['age' + i]
                                    );
                                }
                            }

                            return CreatePlansActions.setAgeRateCurve({
                                ageRateCurve: {
                                    id: ageRateCurve.id,
                                    isFederalDefault: ageRateCurve.isFederalDefault,
                                    ageRateCurve: rates,
                                },
                            });
                        })
                    );
                }

                return CreatePlansActions.setAgeRateCurve({
                    ageRateCurve: {
                        id: currentState.ageRateCurve.curve.id,
                        isFederalDefault: currentState.ageRateCurve.curve.isFederalDefault,
                        ageRateCurve: currentState.ageRateCurve.curve.ageRateCurve,
                    },
                });
            },
            onError: (action, error: any) => {
                return CreatePlansActions.loadAgeRateCurveFailure(action);
            },
        })
    );

    // getAllAgeRateCurves$ = createEffect(() =>
    //     this._dataPersistence.fetch(CreatePlansActions.loadAgeRateCurve, {
    //         run: (
    //             action: ReturnType<typeof CreatePlansActions.loadAgeRateCurve>,
    //             state: any
    //         ) => {
    //             const currentState = dotProp.get(
    //                 state,
    //                 createPlansDataKey
    //             ) as CreatePlansState;
    //
    //             if (currentState.allAgeRateCurves.curves.length === 0) {
    //                 return this._plan.getAllAgeRateCurves().pipe(
    //                     map((curves: AgeRateCurveResponse[]) => {
    //                         return CreatePlansActions.setAllAgeRateCurves({
    //                             curves: curves.map((curve) => {
    //                                 const rates: number[] = [];
    //
    //                                 for (let i = 0; i <= 65; i++) {
    //                                     if (curve['age' + i]) {
    //                                         rates[i] = parseFloat(
    //                                             curve['age' + i]
    //                                         );
    //                                     }
    //                                 }
    //
    //                                 return {
    //                                     id: curve.id,
    //                                     isFederalDefault:
    //                                         curve.isFederalDefault,
    //                                     ageRateCurve: rates,
    //                                 } as AgeRateCurve;
    //                             }),
    //                         });
    //                     })
    //                 );
    //             }
    //
    //             return EMPTY;
    //         },
    //         onError: (action, error: any) => {
    //             return CreatePlansActions.loadAgeRateCurveFailure(action);
    //         },
    //     })
    // );

    loadPlanList$ = createEffect(() =>
        this._dataPersistence.fetch(CreatePlansActions.loadPlanList, {
            run: (
                a: ReturnType<typeof CreatePlansActions.loadPlanList>,
                state?: any
            ): Observable<Action> | Action | void => {
                return this._plan.getSimplePlanList(a.year).pipe(
                    map((plans: SimplePlan[]) =>
                        CreatePlansActions.loadPlanListSuccess({
                            plans,
                        })
                    )
                );
            },
            onError: (a, e: any): Observable<any> | any => {
                console.error(e);
                throw new CustomError(e, {
                    ngrx: true,
                    data: {
                        action: 'loadPlanList$',
                        year: a.year,
                        zipCode: a.zipCode
                    }
                });
            },
        })
    );

    cloneExistingPlan$ = createEffect(() =>
        this._dataPersistence.fetch(CreatePlansActions.cloneExistingPlan, {
            run: (
                a: ReturnType<typeof CreatePlansActions.cloneExistingPlan>,
                state?: any
            ): Observable<Action> | Action | void => {
                return this._plan
                    .get(a.planId, a.zipCode, a.effectiveDate, a.employerId)
                    .pipe(
                        map((plan: FullPlan) => {
                            this._facade.setValue(tranformPlanToForm(plan));
                            return CreatePlansActions.clonedExistingPlan();
                        })
                    );
            },
            onError: (a, e: any): Observable<any> | any => {
                console.error(e);
                throw new CustomError(e, {
                    ngrx: true,
                    data: {
                        action: 'loadPlanList$',
                        effectiveDate: a.effectiveDate,
                        zipCode: a.zipCode,
                        planId: a.planId,
                        employerId: a.employerId
                    }
                });
            },
        })
    );

    savePlan$ = createEffect(() =>
        this._dataPersistence.fetch(finishWizard, {
            run: (
                a: ReturnType<typeof finishWizard>,
                state?: any
            ): Observable<Action> | Action | void => {
                const value = clone(state.createPlans.wizard.value);
                const params = getParamsFromRoute(this._route);
                // Move properties to correct objects.
                // UI places fields in different places than the model.
                if (params.employerId) {
                    const newPlan = formatWizardPlan(value);
                    return this._plan
                        .createCustomPlan(
                            params.employerId,
                            formatMomentProperties(newPlan)
                        )
                        .pipe(
                            tap((employerPlan) => {
                                this._router.navigate([
                                    `/app/small-employers/${employerPlan.employerId}/plans/${employerPlan.id}`
                                ]);
                            }),
                            map(() => savedWizard({ key: this._wizardKey }))
                        );
                }
                return of(savedWizard({ key: this._wizardKey }));
            },
            onError: (a, e: any): Observable<any> | any => {
                console.error(e);
                throw new CustomError(e, {
                    ngrx: true,
                    data: {
                        action: 'savePlan$',
                        key: a.key,
                    }
                });
            },
        })
    );

    saveExistingPlan$ = createEffect(() =>
        this._dataPersistence.fetch(CreatePlansActions.saveExistingPlan, {
            run: (
                a: ReturnType<typeof CreatePlansActions.saveExistingPlan>,
                state?: any
            ): Observable<Action> | Action | void => {
                return this._plan
                    .addExistingPlanToEmployer(a.employerId, a.plan)
                    .pipe(
                        map((plan: EmployerPlan) =>
                            CreatePlansActions.saveExistingPlanSuccess({
                                employerPlan: plan,
                            })
                        )
                    );
            },
            onError: (a, e: any): Observable<any> | any => {
                this._toastr.error(e.error.detail);
                return CreatePlansActions.saveExistingPlanFailure();
            },
        })
    );

    constructor(
        protected _actions$: Actions,
        protected _facade: CreatePlansFacade,
        protected _plan: PlanFacade,
        protected _dataPersistence: DataPersistence<any>,
        protected _route: ActivatedRoute,
        protected _router: Router,
        protected _toastr: ToastrService,
        @Inject(CREATE_PLAN_WIZARD_KEY) protected _wizardKey: string
    ) {}
}

export function formatWizardPlan(value: any) {
    const name = value.info.name;
    const metallicValue = value.details.metallicLevel;
    const outOfNetworkCoverage = value.details.outOfNetworkCoverage;
    const hsaEligible = value.details.hsaEligible;
    delete value.info.name;
    delete value.details.metallicLevel;
    delete value.details.outOfNetworkCoverage;
    delete value.details.hsaEligible;
    value.info.metallicLevel = metallicValue;
    value.info.hsaEligible = !!hsaEligible;
    value['coverage-details'].outOfNetworkCoverage = !!outOfNetworkCoverage;
    value.details.coinsuranceMember = (
        value.details.coinsuranceMember / 100
    ).toFixed(2);
    let plan: any = {
        name,
        fundingType: value.setup.fundingType,
        info: value.info,
        details: value.details,
        coverage: value['coverage-details'],
        employerZipCode: value.setup.employerZipCode,
    };
    if (value.plan_id) {
        plan = {
            ...plan,
            id: value.plan_id,
        };
    }
    if (value.rates?.rates) {
        plan = {
            ...plan,
            expirationDate:
                typeof value.rates.expirationDate === 'object'
                    ? value.rates.expirationDate.format(moment.HTML5_FMT.DATE)
                    : value.rates.expirationDate,
            effectiveDate:
                typeof value.rates.effectiveDate === 'object'
                    ? value.rates.effectiveDate.format(moment.HTML5_FMT.DATE)
                    : value.rates.effectiveDate,
            planRates: { ...value.rates.rates },
        };
    } else {
        plan = {
            ...plan,
            expirationDate: value.setup.expirationDate,
            effectiveDate: value.setup.effectiveDate,
        };
    }
    if (plan.planRates?.type === RateType.AgeRate) {
        delete plan.planRates.composite;
        if (!value.rates.hasTobaccoRates) {
            delete plan.planRates.tobacco;
        }
    } else if (plan.planRates?.type === RateType.Composite) {
        delete plan.planRates.age;
        delete plan.planRates.tobacco;
    }
    if (value.issuer.type === 'existing') {
        plan.issuerId = value.issuer.existingId.id;
    } else {
        plan.issuer = value.issuer.customIssuer;
    }
    return plan as Plan & { employerZipCode: string };
}

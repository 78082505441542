import {
    ActivatedStep,
    ChildStep,
    CurrentStep,
    FutureStep,
    ParentStep,
    PlaceholderStep,
    Step
} from '../+state/wizard.models';

export function getCurrentSteps(activatedStep: ActivatedStep): Step[] {
    if (!activatedStep) return [];
    const parentSteps = getParentSteps(activatedStep);
    const childSteps = getChildSteps(activatedStep);
    const currentStep = getCurrentStep(activatedStep);
    const futureSteps = getFutureSteps(activatedStep);
    const placeholderSteps = getPlaceholderSteps(activatedStep);
    const unknownSteps = getUnknownSteps(activatedStep);

    const steps: Step[] = [
        ...parentSteps,
        currentStep,
        ...childSteps,
        ...futureSteps,
        ...unknownSteps
    ];

    if (placeholderSteps.length > 0) {
        for (const placeholder of placeholderSteps) {
            steps.splice(placeholder.index, 0, ...placeholder.placeholders);
        }
    }

    return steps;
}

export function getUnknownSteps(
    activatedStep: ActivatedStep
): PlaceholderStep[] {
    const unknownSteps = [];

    let latestStep = activatedStep;

    while (latestStep.firstChild) {
        latestStep = latestStep.firstChild;
    }

    let latestRoute = latestStep.route;

    while (latestRoute) {
        const childrenKeys = Object.keys(latestRoute.children);
        if (childrenKeys.length > 1) {
            for (let i = 0; i < latestRoute.totalPossibleSteps - 1; i++) {
                unknownSteps.push({
                    placeholder: true,
                    current: false,
                    future: false,
                    complete: false
                });
            }

            break;
        }

        latestRoute =
            childrenKeys.length > 0
                ? latestRoute.children[childrenKeys[0]].route
                : null;
    }

    return unknownSteps;
}

export function getPlaceholderSteps(
    activatedStep: ActivatedStep
): { index: number; placeholders: PlaceholderStep[] }[] {
    const placeholderSteps = [];

    let currentStep = activatedStep.root;
    let currentIndex = 0;

    while (currentStep) {
        const parent = currentStep.parent;
        if (
            parent &&
            Object.keys(parent.route.children).length > 1 &&
            currentStep.route.totalPossibleSteps <
                parent.route.totalPossibleSteps - 1
        ) {
            const totalPlaceholderSteps =
                parent.route.totalPossibleSteps -
                currentStep.route.totalPossibleSteps -
                1;
            if (totalPlaceholderSteps > 0) {
                const step = {
                    index: currentIndex,
                    placeholders: []
                };
                for (let i = 0; i < totalPlaceholderSteps; i++) {
                    step.placeholders.push({
                        placeholder: true,
                        current: false,
                        future: false,
                        complete: false
                    });
                }
                currentIndex = currentIndex + totalPlaceholderSteps;
                placeholderSteps.push(step);
            }
        }
        currentIndex++;
        currentStep = currentStep.firstChild;
    }

    return placeholderSteps;
}

export function getFutureSteps(activatedStep: ActivatedStep): FutureStep[] {
    const futureSteps = [];

    let latestStep = activatedStep;

    while (latestStep.firstChild) {
        latestStep = latestStep.firstChild;
    }

    let latestRoute = latestStep.route;
    let childrenKeys = Object.keys(latestRoute.children);

    while (latestRoute) {
        if (childrenKeys.length === 1) {
            latestRoute = latestRoute.children[childrenKeys[0]].route;
            childrenKeys = Object.keys(latestRoute.children);
        } else {
            break;
        }
        futureSteps.push({
            ...latestRoute.step,
            current: false,
            placeholder: false,
            future: true,
            complete: false,
            pathFromRoot: latestRoute.pathFromRoot
        });
    }

    return futureSteps;
}

export function getParentSteps(activatedStep: ActivatedStep): ParentStep[] {
    const parents = [];

    let parent = activatedStep.parent;
    while (parent) {
        parents.push({
            ...parent.step,
            current: false,
            placeholder: false,
            future: false,
            complete: !!parent.value,
            navigable: true,
            pathFromRoot: parent.route.pathFromRoot
        });

        parent = parent.parent;
    }

    return parents.reverse();
}

export function getCurrentStep(activatedStep: ActivatedStep): CurrentStep {
    return {
        label: activatedStep.step.label,
        icon: activatedStep.step.icon,
        current: true,
        placeholder: false,
        future: false,
        navigable: false,
        complete: !!activatedStep.value,
        pathFromRoot: activatedStep.route.pathFromRoot
    };
}

export function getChildSteps(activatedStep: ActivatedStep): ChildStep[] {
    const children = [];

    let child = activatedStep.firstChild;
    while (child) {
        children.push({
            ...child.step,
            current: false,
            placeholder: false,
            future: false,
            complete: !!child.value,
            navigable: true,
            pathFromRoot: child.route.pathFromRoot
        });

        child = child.firstChild;
    }

    return children;
}

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AppModule } from '../../../../../apps/benefit-sculptor/src/app/app.module';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


import { DeleteConfirmationModalComponent } from '@besc/ui';

export function Confirmable(getterParamsFn?: string, getterParamsArgs?: string) {
	return function (_target: object, _pro1pertyKey: string, descriptor: PropertyDescriptor) {
		const originalMethod = descriptor.value;
		
		descriptor.value = async function (...args: any[]) {
			const ngbModal = AppModule?.injector.get(NgbModal);
			let params;
			if (getterParamsFn) {
				const that = this as any;
				const fn = that[getterParamsFn] as any;
				params = fn(that[getterParamsArgs ? getterParamsArgs : '']);
			}
			try {
				const modal = ngbModal.open(DeleteConfirmationModalComponent);
				modal.componentInstance.params = params;
				const result = await modal.result;
				if (result) {
					originalMethod.apply(this, args);
				}
			} catch (error) {
				console.log(`modal dismissed`)
			}
		};
		return descriptor;
	};
}

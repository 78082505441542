import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    distinctUntilChanged,
    filter,
    finalize,
    map,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
    RegisterRequest,
    RegisterResponse,
    RoleType,
    User,
    VerifyUser,
} from '../../interface';
import { Store } from '@ngrx/store';

declare let heap: any;

export interface LoginPayload {
    username: string;
    password: string;
    remember: boolean;
}

export interface AuthNotice {
    type: 'success' | 'error';
    message: string;
}

export interface AuthorizationResponse {
    token: string;
}

@Injectable({
    providedIn: 'root',
})
export class AuthFacadeService {
    private _authNotice: BehaviorSubject<AuthNotice> =
        new BehaviorSubject<AuthNotice>(null);
    private _authorization: BehaviorSubject<AuthorizationResponse> =
        new BehaviorSubject<AuthorizationResponse>(null);
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );
    private _currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(
        null
    );
    private _register: BehaviorSubject<RegisterResponse> =
        new BehaviorSubject<RegisterResponse>(null);

    loading$: Observable<boolean> = this._loading.asObservable();
    authNotice$: Observable<AuthNotice> = this._authNotice.asObservable();
    registered$: Observable<RegisterResponse> = this._register.pipe(
        filter((response) => !!response)
    );
    currentUser$: Observable<User> = this._currentUser.asObservable();
    authorization$: Observable<AuthorizationResponse> =
        this._authorization.asObservable();
    isAuthorized$: Observable<boolean> = this._currentUser.pipe(
        map((currentUser) => currentUser !== null),
        distinctUntilChanged(),
        shareReplay(1)
    );

    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _toastr: ToastrService,
        private store: Store
    ) {}

    bootstrapAuth(): Observable<User> {
        return this._http.get<{ isAuthenticated: boolean }>('auth/status').pipe(
            switchMap((response) => response.isAuthenticated ? this.loadCurrentUser() : of(null))
        )
    }

    logout() {
        this.store.dispatch({ type: 'LOGOUT' });
    }

    isLoggedIn(): boolean {
        return !!this._currentUser.getValue();
    }

    login(login: LoginPayload, recaptcha: string): void {
        this._loading.next(true);
        this._http.post('auth/login', {
            recaptcha,
            login: login.username,
            password: login.password,
        })
        .pipe(
            switchMap(() => this.loadCurrentUser()),
            finalize(() => this._loading.next(false))
        )
        .subscribe(
            () => null,
            (error) => {
                if (error?.error?.detail) {
                    this._toastr.error(error.error.detail);
                } else {
                    console.error(error.error);
                }
            }
        );
    }

    forgotPassword(email: string, recaptcha: string): void {
        this._loading.next(true);
        this._http.post<any>(
            'auth/send-reset-password-link',
            { login: email, recaptcha }
        )
        .pipe(finalize(() => this._loading.next(false)))
        .subscribe(
            () => this._router.navigate(['/auth/forgot-password/success']),
            (error) => {
                if (error?.error?.detail) {
                    this._toastr.error(error.error.detail);
                } else {
                    console.error(error.error);
                }
            }
        );
    }

    clearAuth() {
        this._currentUser.next(null);
        // BS-200 - we use `location.href` here instead of a router redirect to
        //          force a page refresh to clear the RxJS cache
        location.href = '/auth/login';
    }

    register(register: RegisterRequest): void {
        this._loading.next(true);
        this._http.post<RegisterResponse>(
            'auth/register',
            register
        ).pipe(finalize(() => this._loading.next(false)))
        .subscribe(
            (response: RegisterResponse) => {
                this._register.next(response);
            },
            (error) => {
                if (error?.error?.nonFieldErrors?.[0]) {
                    this._toastr.error(error.error.nonFieldErrors?.[0]);
                } else if (error?.error?.password) {
                    this._toastr.error(error.error.password);
                } else {
                    console.error(error.error);
                }
            }
        );
    }

    checkExistingEmail(email: string) {
        return this._http.post('auth/check-existing-email', { email });
    }

    registerEmail(email: string) {
        return this._http.post('auth/register-email', { email });
    }

    verifyEmail(verify: any) {
        return this._http.post('auth/verify-email', verify);
    }

    verifyUser(verify: VerifyUser) {
        return this._http.post('auth/verify-registration', verify);
    }

    updatePassword(verify: {
        userId: string;
        timestamp: string;
        signature: string;
        password: string;
    }) {
        return this._http.post('auth/reset-password', verify);
    }

    resetRegistered() {
        this._register.next(null);
    }

    loadCurrentUser(): Observable<User> {
        return this._http.get<User>('auth/current-user/').pipe(
            map((currentUser) => ({ ...currentUser, userType: currentUser.userType?.toLowerCase() as RoleType })),
            tap((currentUser) => this._currentUser.next(currentUser)),
            tap((currentUser) => heap.identify(currentUser.id)),
        );
    }

    getNameAndAgency(userId: string) {
        return this._http.get<{ name: string; agency: string }>(
            `auth/name-and-agency/${userId}`
        );
    }

    setPassword(data: {
        userId: string;
        timestamp: string;
        signature: string;
        password: string;
    }) {
        return this._http.post('auth/set-password', data);
    }
}

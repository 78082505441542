import { createReducer, on, Action } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';

import * as EmployeesActions from './employees.actions';
import { EmployeePlanResponse, EmployeesEntity } from './employees.models';
import { EmployeeCurrentHealthPlan, EmployeeListFilter } from '../interfaces';
import { CustomError, normalizeEffectiveDate, sortCompareFn } from '@benefit-sculptor/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import {Sort} from "@angular/material/sort";

export const EMPLOYEES_FEATURE_KEY = 'employees';

export interface State extends EntityState<EmployeesEntity> {
    selectedId?: string | number; // which Employees record has been selected
    loading: {
        employees: boolean;
        plans: boolean;
        update: boolean;
        addNew: boolean;
        delete: boolean;
        saveDependents: boolean;
    };
    issuers: {
        id: string;
        name: string;
    }[];
    error?: string | null; // last none error (if any)
    employeePlans: EmployeePlanResponse;
    effectiveDate: Moment;
    employerId: string;
    employerPlansToEmployeeIds: { [employerPlanId: string]: string[] };
    currentEmployerPlanId: string;
    currentEmployeeId: string;
    currentEmployee: {
        id: string;
        currentPlan: EmployeeCurrentHealthPlan;
        effectiveDate: Moment;
    };
    sort: Sort;
    filter: EmployeeListFilter;
}

export interface EmployeesPartialState {
    readonly [EMPLOYEES_FEATURE_KEY]: State;
}

export const employeesAdapter: EntityAdapter<EmployeesEntity> = createEntityAdapter<EmployeesEntity>();

export const initialState: State = employeesAdapter.getInitialState({
    // set initial required properties
    loading: {
        employees: false,
        plans: false,
        update: false,
        addNew: false,
        delete: false,
        saveDependents: false,
    },
    issuers: [],
    employeePlans: {},
    employerId: null,
    effectiveDate: normalizeEffectiveDate(moment()),
    employerPlansToEmployeeIds: {},
    currentEmployerPlanId: 'all',
    currentEmployeeId: null,
    currentEmployee: {
        id: null,
        currentPlan: null,
        effectiveDate: normalizeEffectiveDate(moment()),
    },
    sort: null,
    filter: null,
});

const employeesReducer = createReducer(
    initialState,
    on(EmployeesActions.loadEmployees, (state, { employerId }) => ({
        ...state,
        employerId,
        loading: {
            ...state.loading,
            employees: true,
        },
        error: null,
    })),
    on(EmployeesActions.loadEmployeesSuccess, (state, { employees, effectiveDate }) => {
        return employeesAdapter.setAll(
            employees.sort(sortCompareFn(['lastName', 'firstName'], 'asc')),
            {
                ...state,
                effectiveDate,
                loading: {
                    ...state.loading,
                    employees: false,
                },
            }
        );
    }),
    on(EmployeesActions.loadEmployeesFailure, (state, { error }) => ({
        ...state,
        employerId: null,
        error,
        loading: {
            ...state.loading,
            employees: false,
        },
    })),
    on(
        EmployeesActions.loadEmployeePlans,
        (state, { effectiveDate }) => ({
            ...state,
            error: null,
            effectiveDate,
            loading: {
                ...state.loading,
                plans: true,
            },
        })
    ),
    on(EmployeesActions.loadEmployeePlansSuccess, (state, { plans }) => {
        try {
            const employerPlansToEmployeeIds = {
                none: [],
            };
            const issuers: { [id: string]: { id: string; name: string } } = {};
            for (const employeeId of Object.keys(plans)) {
                const plan = plans[employeeId];
                if (!plan) {
                    employerPlansToEmployeeIds['none'].push(employeeId);
                    continue;
                }
                if (!plan.waivedCoverage && !issuers[plans[employeeId].issuer?.id]) {
                    issuers[plans[employeeId].issuer.id] = plans[employeeId].issuer;
                }
                if (!employerPlansToEmployeeIds[plan.id]) {
                    employerPlansToEmployeeIds[plan.id] = [];
                }
                employerPlansToEmployeeIds[plan.id].push(employeeId);
            }
            return {
                ...state,
                loading: {
                    ...state.loading,
                    plans: false,
                },
                issuers: Object.values(issuers).sort(sortCompareFn('name', 'asc')),
                employeePlans: plans,
                employerPlansToEmployeeIds,
            };
        } catch (error) {
            throw new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'loadEmployeePlansSuccess',
                    plans
                }
            });
        }
    }),
    on(EmployeesActions.loadEmployeePlansFailure, (state, { error }) => ({
        ...state,
        loading: {
            ...state.loading,
            plans: false,
        },
        error,
    })),
    on(
        EmployeesActions.setCurrentEmployerPlan,
        (state, { currentEmployerPlanId }) => ({
            ...state,
            currentEmployerPlanId,
        })
    ),
    on(
        EmployeesActions.loadCurrentEmployeePlan,
        (state, { effectiveDate }) => ({
            ...state,
            currentEmployee: {
                ...state.currentEmployee,
                effectiveDate,
            },
        })
    ),
    on(
        EmployeesActions.loadCurrentEmployeePlanSuccess,
        (state, { currentPlan }) => ({
            ...state,
            currentEmployee: {
                ...state.currentEmployee,
                currentPlan,
            },
        })
    ),
    on(EmployeesActions.setCurrentEmployee, (state, { currentEmployeeId }) => ({
        ...state,
        currentEmployee: {
            ...state.currentEmployee,
            id: currentEmployeeId,
        },
    })),
    on(EmployeesActions.updateEmployee, (state) => ({
        ...state,
        loading: {
            ...state.loading,
            update: true,
        },
    })),
    on(EmployeesActions.updateEmployeeFailure, (state) => ({
        ...state,
        loading: {
            ...state.loading,
            update: false,
        },
    })),
    on(EmployeesActions.updateEmployeeSuccess, (state, { employee }) => {
        try {
            return employeesAdapter.updateOne(
                {
                    id: employee.id,
                    changes: employee,
                },
                {
                    ...state,
                    loading: {
                        ...state.loading,
                        update: false,
                    },
                }
            );
        } catch (error) {
            throw new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'updateEmployeeSuccess',
                    employee
                }
            });
        }
    }),
    on(EmployeesActions.saveDependents, (state) => ({
        ...state,
        loading: {
            ...state.loading,
            saveDependents: true,
        },
    })),
    on(
        EmployeesActions.saveDependentsSuccess,
        (state, { dependents, employeeId }) => {
            try {
                return employeesAdapter.updateOne(
                    {
                        id: employeeId,
                        changes: {
                            dependents,
                            dependentCount: dependents.length,
                        },
                    },
                    {
                        ...state,
                        loading: {
                            ...state.loading,
                            saveDependents: false,
                        },
                    }
                );
            } catch (error) {
                throw new CustomError(error, {
                    ngrx: true,
                    data: {
                        action: 'saveDependentsSuccess',
                        dependents,
                        employeeId
                    }
                });
            }
        }
    ),
    on(EmployeesActions.saveDependentsFailure, (state, { error }) => ({
        ...state,
        loading: {
            ...state.loading,
            saveDependents: false,
        },
    })),
    on(EmployeesActions.addNewEmployee, (state, { employee }) => ({
        ...state,
        loading: {
            ...state.loading,
            addNew: true,
        },
    })),
    on(EmployeesActions.addNewEmployeeSuccess, (state, { employee }) => {
        return employeesAdapter.addOne(employee, {
            ...state,
            loading: {
                ...state.loading,
                addNew: false,
            },
        });
    }),
    on(EmployeesActions.addNewEmployeeFailure, (state, { error }) => ({
        ...state,
        loading: {
            ...state.loading,
            addNew: false,
        },
    })),
    on(EmployeesActions.deleteEmployee, (state, { employeeId }) => {
        return employeesAdapter.updateOne(
            {
                id: employeeId,
                changes: {
                    deleting: true,
                },
            },
            state
        );
    }),
    on(EmployeesActions.deleteEmployeeSuccess, (state, { employeeId }) => {
        return employeesAdapter.removeOne(employeeId, state);
    }),
    on(
        EmployeesActions.deleteEmployeeFailure,
        (state, { error, employeeId }) => {
            return employeesAdapter.updateOne(
                {
                    id: employeeId,
                    changes: {
                        deleting: false,
                    },
                },
                state
            );
        }
    ),
    on(EmployeesActions.deleteEmployeeElections, (state) => ({
        ...state,
        loading: {
            ...state.loading,
            update: true,
        },
    })),
    on(EmployeesActions.deleteEmployeeElectionsFailure, (state) => ({
        ...state,
        loading: {
            ...state.loading,
            update: false,
        },
    })),
    on(EmployeesActions.deleteEmployeeElectionsSuccess, (state, { employeeId }) => {
        try {
            state.employeePlans[employeeId] = null;
            const employee = state.entities[employeeId];
            return employeesAdapter.updateOne(
                {
                    id: employeeId,
                    changes: employee
                },
                {
                    ...state,
                    loading: {
                        ...state.loading,
                        update: false,
                    },
                }
            );
        } catch (error) {
            throw new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'deleteEmployeeElectionsSuccess',
                    state
                }
            });
        }
    }),

    on(EmployeesActions.filterEmployees, (state, { filter }) => ({
        ...state,
        filter,
    })),
    on(EmployeesActions.sortEmployees, (state, { sort }) => ({
        ...state,
        sort,
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return employeesReducer(state, action);
}

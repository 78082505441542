import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'besc-admin-dashboard',
    templateUrl: './admin-dashboard.component.html',
    styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

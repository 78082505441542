import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangeEmployeesOnPlanComponent } from './components/change-employees-on-plan/change-employees-on-plan.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
    NgbDropdownModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { DatesModule } from '@benefit-sculptor/core';
import { ContributionsComponent } from './components/contributions/contributions.component';
import { ContributionSliderComponent } from './components/contribution-slider/contribution-slider.component';
import { MatSliderModule } from '@angular/material/slider';
import { PlanDetailsAccordionComponent } from './components/plan-details-accordion/plan-details-accordion.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CompositeRatesComponent } from './components/composite-rates/composite-rates.component';
import { AccordionRatesComponent } from './components/plan-details-accordion/accordion-rates/accordion-rates.component';
import { CarrierBillingTypeComponent } from './components/carrier-billing-type/carrier-billing-type.component';
import { PlanRateModalComponent } from './components/plan-rate-modal/plan-rate-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { PlanDetailsModalComponent } from './components/plan-details-modal/plan-details-modal.component';
import { SharedComponentsModule } from 'apps/benefit-sculptor/src/app/admin/components/shared-components.module';

@NgModule({
    imports: [
        CommonModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        DragDropModule,
        MatFormFieldModule,
        MatInputModule,
        NgbDropdownModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatSelectModule,
        DatesModule,
        MatSliderModule,
        MatExpansionModule,
        NgbTooltipModule,
        NgSelectModule,
        SharedComponentsModule,
    ],
    declarations: [
        ChangeEmployeesOnPlanComponent,
        EmployeeListComponent,
        ContributionsComponent,
        ContributionSliderComponent,
        PlanDetailsAccordionComponent,
        CompositeRatesComponent,
        AccordionRatesComponent,
        CarrierBillingTypeComponent,
        PlanRateModalComponent,
        PlanDetailsModalComponent,
    ],
    exports: [
        ChangeEmployeesOnPlanComponent,
        ContributionsComponent,
        PlanDetailsAccordionComponent,
        CompositeRatesComponent,
        CarrierBillingTypeComponent,
    ],
    providers: [],
})
export class PlanModule {}

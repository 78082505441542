import { createAction, props } from '@ngrx/store';
import { EmployeesEntity, EmployeePlanResponse } from './employees.models';
import {
    CreateEmployeeModel,
    Dependent,
    Employee,
    EmployeeCurrentHealthPlan,
    EmployeeListFilter,
} from '../interfaces';
import { Moment } from 'moment';
import { Sort } from '@angular/material/sort';

export const loadEmployees = createAction(
    '[Employees] Load Employees',
    props<{
        employerId: string;
        effectiveDate?: string;
    }>()
);

export const loadEmployeesSuccess = createAction(
    '[Employees] Load Employees Success',
    props<{ employees: EmployeesEntity[]; effectiveDate?: Moment }>()
);

export const loadEmployeesFailure = createAction(
    '[Employees] Load Employees Failure',
    props<{ error: any }>()
);

export const loadEmployeePlans = createAction(
    '[Employees] Load Employee Plans',
    props<{
        employerId: string;
        effectiveDate?: Moment;
    }>()
);

export const loadEmployeePlansSuccess = createAction(
    '[Employees] Load Employee Plans Success',
    props<{ plans: EmployeePlanResponse }>()
);

export const loadEmployeePlansFailure = createAction(
    '[Employees] Load Employee Plans Failure',
    props<{ error: any }>()
);

export const setCurrentEmployerPlan = createAction(
    '[Employees] Set Current Employer Plan',
    props<{ currentEmployerPlanId }>()
);

export const setCurrentEmployee = createAction(
    '[Employees] Set Current Employee ID',
    props<{ currentEmployeeId: string }>()
);

export const loadCurrentEmployeePlan = createAction(
    '[Employees] Load Current Employee Plan',
    props<{ effectiveDate: Moment }>()
);

export const loadCurrentEmployeePlanSuccess = createAction(
    '[Employees] Load Current Employee Plan Success',
    props<{ currentPlan: EmployeeCurrentHealthPlan }>()
);

export const loadCurrentEmployeePlanFailure = createAction(
    '[Employees] Load Current Employee Plan Failure',
    props<{ error: any }>()
);

export const updateEmployee = createAction(
    '[Employees] Update Employee',
    props<{ employee: Employee }>()
);

export const updateEmployeeSuccess = createAction(
    '[Employees] Update Employee Success',
    props<{ employee: Employee }>()
);

export const updateEmployeeFailure = createAction(
    '[Employees] Update Employee Failure',
    props<{ error: any }>()
);

export const addNewEmployee = createAction(
    '[Employees] Add New Employee',
    props<{ employee: CreateEmployeeModel }>()
);

export const addNewEmployeeSuccess = createAction(
    '[Employees] Add New Employee Success',
    props<{ employee: Employee }>()
);

export const addNewEmployeeFailure = createAction(
    '[Employees] Add New Employee Failure',
    props<{ error: any }>()
);

export const deleteEmployee = createAction(
    '[Employees] Delete Employee',
    props<{ employeeId: string }>()
);

export const deleteEmployeeSuccess = createAction(
    '[Employees] Delete Employee Success',
    props<{ employeeId: string }>()
);

export const deleteEmployeeFailure = createAction(
    '[Employees] Delete Employee Failure',
    props<{ error: any; employeeId: string }>()
);

export const deleteEmployeeElections = createAction(
    '[Employees] Delete Employee Elections',
    props<{
        employeeId: string;
        employerId: string;
        effectiveDate?: Moment;
    }>()
);

export const deleteEmployeeElectionsSuccess = createAction(
    '[Employees] Delete Employee Elections Success',
    props<{ 
        employeeId: string;
        employerId: string;
        effectiveDate?: Moment;
    }>()
);

export const deleteEmployeeElectionsFailure = createAction(
    '[Employees] Delete Employee Elections Failure',
    props<{ error: any }>()
);

export const saveDependents = createAction(
    '[Employees] Save Dependents',
    props<{ employeeId: string; dependents: Dependent[] }>()
);

export const saveDependentsSuccess = createAction(
    '[Employees] Save Dependents Success',
    props<{ employeeId: string; dependents: Dependent[] }>()
);

export const saveDependentsFailure = createAction(
    '[Employees] Save Dependents Failure',
    props<{ error: any; employeeId: string }>()
);

export const filterEmployees = createAction(
    '[Employees] Filter Employees',
    props<{
        filter: EmployeeListFilter;
    }>()
);

export const sortEmployees = createAction(
    '[Employees] Sort Employees',
    props<{
        sort: Sort;
    }>()
);

import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Navigation, NavResolver } from '@benefit-sculptor/navigation';
import { Observable } from 'rxjs';
import { RouteParserService } from '@benefit-sculptor/router';
import { AgencyService, AgencyStatus } from '@besc/agency';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmployerNavResolver {

  constructor(
    private _routeParser: RouteParserService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _agency: AgencyService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<Navigation | Observable<Navigation>>
    | Promise<Navigation | Observable<Navigation>>
    | Navigation
    | Observable<Navigation> {
    const params = this._routeParser.getParamsFromRoute(route);
    return this._agency.userAgency$.pipe(
      map((agency) => {
        return {
          items: [
            {
              label: 'Employers',
              icon: ['fad', 'list'],
              disabled: agency.status !== AgencyStatus.ACTIVE,
              link: ['/app/small-employers/list'],
              notExact: true,
              separator: true,
            },
            {
              label: 'Employees',
              icon: ['fad', 'users'],
              disabled: typeof params.employerId === 'undefined',
              notExact: true,
              link: [
                '/app/small-employers/:employerId/employees',
              ],
            },
            {
              label: 'Plans',
              icon: ['fad', 'id-card'],
              notExact: true,
              disabled: typeof params.employerId === 'undefined',
              link: ['/app/small-employers/:employerId/plans'],
            },
            // {
            //   label: 'Quotes',
            //   icon: ['fad', 'chart-bar'],
            //   notExact: true,
            //   disabled: typeof params.employerId === 'undefined',
            //   link: ['/app/small-employers/:employerId/quotes'],
            // }
          ],
        } as Navigation;
      }),
      first()
    );
  }

}

import * as moment from 'moment';
import { Moment } from 'moment';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Params } from '@angular/router';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const DEFAULT_MAX_DATE = moment();
export const DEFAULT_MIN_DATE = moment().subtract(100, 'y');
export const MM_DD_YYYY_PATTERN = /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/;

export const DATE_FORMATS_PROVIDER = {
    provide: MAT_DATE_FORMATS,
    useValue: {
        parse: {
            dateInput: [moment.HTML5_FMT.DATE, 'l', 'LL'],
        },
        display: {
            dateInput: 'L',
            monthYearLabel: 'MMM YYYY',
            dateA11yLabel: 'LL',
            monthYearA11yLabel: 'MMMM YYYY',
        },
    },
};

export enum DatePresets {
    ThisYear = 'this_year',
    LastYear = 'last_year',
    NextYear = 'next_year',
    Yesterday = 'yesterday',
    Today = 'today',
    Tomorrow = 'tomorrow',
    LastWeek = 'last_week',
    ThisWeek = 'this_week',
    NextWeek = 'next_week',
    LastMonth = 'last_month',
    ThisMonth = 'this_month',
    NextMonth = 'next_month',
    Custom = 'custom',
}

export interface DatePreset {
    type: DatePresets;
    label: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export const DATE_PRESETS: { [key in DatePresets]?: DatePreset } = {
    [DatePresets.ThisYear]: {
        type: DatePresets.ThisYear,
        label: 'This Year',
        startDate: moment().startOf('y'),
        endDate: moment().endOf('y'),
    },
    [DatePresets.LastYear]: {
        type: DatePresets.LastYear,
        label: 'Last Year',
        startDate: moment().subtract(1, 'y').startOf('y'),
        endDate: moment().subtract(1, 'y').endOf('y'),
    },
    [DatePresets.NextYear]: {
        type: DatePresets.NextYear,
        label: 'Next Year',
        startDate: moment().add(1, 'y').startOf('y'),
        endDate: moment().add(1, 'y').endOf('y'),
    },
    [DatePresets.Yesterday]: {
        type: DatePresets.Yesterday,
        label: 'Yesterday',
        startDate: moment().subtract(1, 'd').startOf('day'),
        endDate: moment().subtract(1, 'd').endOf('day'),
    },
    [DatePresets.Today]: {
        type: DatePresets.Today,
        label: 'Today',
        startDate: moment().startOf('d'),
        endDate: moment().endOf('d'),
    },
    [DatePresets.Tomorrow]: {
        type: DatePresets.Tomorrow,
        label: 'Tomorrow',
        startDate: moment().add(1, 'd').startOf('d'),
        endDate: moment().add(1, 'd').endOf('d'),
    },
    [DatePresets.LastWeek]: {
        type: DatePresets.LastWeek,
        label: 'Last Week',
        startDate: moment().subtract(1, 'w').startOf('w'),
        endDate: moment().subtract(1, 'w').endOf('w'),
    },
    [DatePresets.ThisWeek]: {
        type: DatePresets.ThisWeek,
        label: 'This Week',
        startDate: moment().startOf('w'),
        endDate: moment().endOf('w'),
    },
    [DatePresets.NextWeek]: {
        type: DatePresets.NextWeek,
        label: 'This Year',
        startDate: moment().add(1, 'w').startOf('w'),
        endDate: moment().add(1, 'w').endOf('w'),
    },
    [DatePresets.LastMonth]: {
        type: DatePresets.LastMonth,
        label: 'This Year',
        startDate: moment().subtract(1, 'm').startOf('m'),
        endDate: moment().subtract(1, 'm').endOf('m'),
    },
    [DatePresets.ThisMonth]: {
        type: DatePresets.ThisMonth,
        label: 'This Year',
        startDate: moment().startOf('m'),
        endDate: moment().endOf('m'),
    },
    [DatePresets.NextMonth]: {
        type: DatePresets.NextMonth,
        label: 'This Year',
        startDate: moment().add(1, 'm').startOf('m'),
        endDate: moment().add(1, 'm').endOf('m'),
    },
};

export function formatMomentProperties(
    data: any,
    format: string = moment.HTML5_FMT.DATE
) {
    for (const prop of Object.keys(data)) {
        if (data.hasOwnProperty(prop)) {
            if (moment.isMoment(data[prop])) {
                data[prop] = data[prop].format(format);
            } else if (typeof data[prop] === 'object' && data[prop] !== null) {
                data[prop] = formatMomentProperties(data[prop]);
            } else if (Array.isArray(data[prop])) {
                data[prop] = data[prop].map((value) => {
                    if (moment.isMoment(value)) {
                        return value.format(format);
                    } else if (typeof value === 'object' && value !== null) {
                        return formatMomentProperties(value);
                    }
                });
            }
        }
    }

    return data;
}

export function getDatePresetFromQueryParams(queryParams: Params) {
    if (queryParams.range) {
        return getDatePreset(queryParams.get('range') as DatePresets);
    }
    if (
        queryParams.hasOwnProperty('startDate') &&
        queryParams.hasOwnProperty('endDate')
    ) {
        return checkDatesForPreset(
            moment(queryParams.startDate, moment.HTML5_FMT.DATE),
            moment(queryParams.endDate, moment.HTML5_FMT.DATE)
        );
    } else if (
        queryParams.hasOwnProperty('startDate') &&
        !queryParams.hasOwnProperty('endDate')
    ) {
        return checkStartDateForPreset(
            moment(queryParams.startDate, moment.HTML5_FMT.DATE)
        );
    } else if (
        queryParams.hasOwnProperty('endDate') &&
        !queryParams.hasOwnProperty('startDate')
    ) {
        return checkEndDateForPreset(
            moment(queryParams.endDate, moment.HTML5_FMT.DATE)
        );
    }

    return getDatePreset(DatePresets.NextYear);
}

export function getDatePresets(presets: DatePresets[] = []): DatePreset[] {
    let presetList = [];
    if (presets.length === 0) {
        presetList = Object.values(DATE_PRESETS);
    } else {
        presetList = presets.map((presetKey) => DATE_PRESETS[presetKey]);
    }

    return presetList.map((preset) => ({
        ...preset,
        startDate: preset.startDate.clone(),
        endDate: preset.endDate.clone(),
    }));
}

export function getDatePreset(presetKey: DatePresets): DatePreset {
    const preset = DATE_PRESETS[presetKey];
    return {
        ...preset,
        startDate: preset.startDate.clone(),
        endDate: preset.endDate.clone(),
    };
}

export function checkDatesForPreset(
    startDate: moment.Moment,
    endDate: moment.Moment,
    presets: DatePresets[] = []
): DatePreset {
    let matchedPreset: DatePreset = null;
    for (const preset of getPresetList(presets)) {
        if (
            preset.startDate.isSame(startDate, 'd') &&
            preset.endDate.isSame(endDate, 'd')
        ) {
            matchedPreset = preset;
        }
    }

    if (!matchedPreset) {
        return {
            type: DatePresets.Custom,
            label: 'custom',
            startDate: startDate.clone(),
            endDate: endDate.clone(),
        };
    }

    return {
        ...matchedPreset,
        startDate: matchedPreset.startDate.clone(),
        endDate: matchedPreset.endDate.clone(),
    };
}

export function checkStartDateForPreset(
    startDate: moment.Moment,
    presets: DatePresets[] = []
): DatePreset {
    let matchedPreset: DatePreset = null;
    for (const preset of getPresetList(presets)) {
        if (preset.startDate.isSame(startDate, 'd')) {
            matchedPreset = preset;
        }
    }

    if (!matchedPreset) {
        return {
            type: DatePresets.Custom,
            label: 'custom',
            startDate: startDate.clone(),
            endDate: null,
        };
    }

    return {
        ...matchedPreset,
        startDate: matchedPreset.startDate.clone(),
        endDate: matchedPreset.endDate.clone(),
    };
}

export function checkEndDateForPreset(
    endDate: moment.Moment,
    presets: DatePresets[] = []
): DatePreset {
    let matchedPreset: DatePreset = null;
    for (const preset of getPresetList(presets)) {
        if (preset.startDate.isSame(endDate, 'd')) {
            matchedPreset = preset;
        }
    }

    if (!matchedPreset) {
        return {
            type: DatePresets.Custom,
            label: 'custom',
            startDate: null,
            endDate: endDate.clone(),
        };
    }

    return {
        ...matchedPreset,
        startDate: matchedPreset.startDate.clone(),
        endDate: matchedPreset.endDate.clone(),
    };
}

export function getPresetList(presets): DatePreset[] {
    let presetList = [];
    if (presets.length === 0) {
        presetList = Object.values(DATE_PRESETS);
    } else {
        presetList = presets.map((presetKey) => DATE_PRESETS[presetKey]);
    }

    return presetList;
}

export function normalizeEffectiveDate(date: Moment): Moment {
    return date.clone().startOf('month');
}

export function normalizeTerminationDate(date: Moment): Moment {
    return date.clone().endOf('month');
}

export function getEffectiveDateForCurrentMonth(): Moment {
    return normalizeEffectiveDate(moment());
}

export function getTerminationDateForCurrentMonth(): Moment {
    return normalizeTerminationDate(moment());
}

export function getNgbDateStructFromMoment(date: Moment): NgbDateStruct {
    if (!date) {
        return null;
    }
    return new NgbDate(date.year(), date.month() + 1, date.date());
}

export function getMomentFromDateStruct(date: NgbDateStruct) {
    if (!date) {
        return null;
    }
    return moment(new Date(date.year, date.month - 1, date.day));
}

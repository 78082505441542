<div class="modal-header">
    <h4 class="modal-title">Add New Agency Employee</h4>
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm"
        aria-label="Close"
        (click)="dismiss('Cross click')"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="newAgencyEmployeeForm" [attr.disabled]="true">
        <div class="row">
            <div class="col-md mb-4">
                <label for="firstName" class="form-label required"
                    >First Name</label
                >
                <input
                    id="firstName"
                    class="form-control"
                    placeholder="First Name"
                    type="text"
                    formControlName="firstName"
                    name="firstName"
                    required
                    bescInputValidation
                />
            </div>
            <div class="col-md mb-4">
                <label for="lastName" class="form-label required"
                    >Last Name</label
                >
                <input
                    id="lastName"
                    class="form-control"
                    placeholder="Last Name"
                    type="text"
                    formControlName="lastName"
                    name="lastName"
                    required
                    bescInputValidation
                />
            </div>
        </div>
        <div class="mb-4">
            <label for="email" class="form-label required">Email</label>
            <input
                id="email"
                class="form-control"
                placeholder="Email"
                type="text"
                formControlName="email"
                name="email"
                required
                bescInputValidation
                bescCheckExistingEmail
            />
        </div>
        <div class="mb-4">
            <label for="jobTitle" class="form-label">Job Title</label>
            <input
                id="jobTitle"
                class="form-control"
                placeholder="Job Title"
                type="text"
                formControlName="jobTitle"
                name="jobTitle"
            />
        </div>
    </form>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-secondary"
        (click)="dismiss('Close click')"
    >
        Close
    </button>
    <button
        [disabled]="newAgencyEmployeeForm.invalid || (loading$ | async)"
        type="button"
        class="btn btn-primary"
        [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                loading$ | async
        }"
        (click)="addNewAgencyEmployee()"
    >
        Create
    </button>
</div>

<div class="registration-container">
    <div class="registration-content">
        <div class="logo">
            <img src="/assets/images/BS-BenefitSculptorLogo-Full.png" alt="Benefit Sculptor" />
        </div>
        <ng-content></ng-content>
    </div>
    <div class="agency-info-container">
        <div *ngIf="!agencyRegistration" class="agency-info">
            <div class="user-info">
                <img src="/assets/icons/profile.svg">
                <div class="user-blank"></div>
            </div>
            <div class="agency">
                <div class="agency-blank"></div>
            </div>
        </div>
        <div *ngIf="agencyRegistration" class="agency-info">
            <div class="user-info">
                <img src="/assets/icons/profile.svg">
                <div class="username">{{ agencyRegistration.customerName }}</div>
            </div>
            <div class="agency">
                <div class="agency-name">{{ agencyRegistration.agencyName }}</div>
            </div>
        </div>
    </div>
</div>
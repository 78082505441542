import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Data,
    Params,
} from '@angular/router';

export function getRouteToFurthestLeaf(
    route: ActivatedRoute
): { route: ActivatedRoute; leafRoute: ActivatedRoute; data: Data } {
    const { route: leafRoute, data } = _traverseRouteForData(route);
    return { route, leafRoute, data };
}

export function getRouteFromRoot(
    route: ActivatedRoute
): { route: ActivatedRoute; leafRoute: ActivatedRoute; data: Data } {
    const { route: leafRoute, data } = _traverseRouteForData(route, true);
    return { route, leafRoute, data };
}

export function getCurrentRouteData(
    route: ActivatedRoute
): { route: ActivatedRoute; leafRoute: ActivatedRoute; data: Data } {
    const rootData = getRouteFromRoot(route);
    const leafData = getRouteToFurthestLeaf(route);
    return {
        route: route,
        leafRoute: leafData.leafRoute,
        data: {
            ...rootData.data,
            ...leafData.data,
        },
    };
}

export function getAllRouteData(route: ActivatedRoute): Data {
    return {
        ...getRouteDataFromRoot(route),
        ...getRouteDataToFurthestLeaf(route),
    };
}

export function getParamsFromRoute(
    route: ActivatedRoute | ActivatedRouteSnapshot,
    keys?: string[]
): any {
    let value: Params = {};

    if (!keys) {
        keys = ['*'];
    }

    if (route instanceof ActivatedRoute || route.hasOwnProperty('snapshot')) {
        route = (route as ActivatedRoute).snapshot;
    }

    _traverseRoute(
        route.root,
        (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
            if (keys.length === 0) {
                return true;
            }

            if (keys.includes('*')) {
                value = { ...value, ...activatedRouteSnapshot.params };
            } else {
                for (let i = 0; i < keys.length; ++i) {
                    if (activatedRouteSnapshot.paramMap.has(keys[i])) {
                        value[keys[i]] = activatedRouteSnapshot.paramMap.get(
                            keys[i]
                        );

                        keys.splice(i, 1);
                        i--;
                    }
                }
            }

            return false;
        }
    );

    return value;
}

export function getRouteDataToFurthestLeaf(route: ActivatedRoute): Data {
    return getRouteToFurthestLeaf(route).data;
}

export function getRouteDataFromRoot(route: ActivatedRoute): Data {
    return getRouteFromRoot(route).data;
}

function _traverseRoute(
    route: ActivatedRoute | ActivatedRouteSnapshot,
    perItemCall: (route: ActivatedRoute | ActivatedRouteSnapshot) => boolean,
    key?: 'firstChild' | 'parent'
): void {
    if (!key) {
        key = 'firstChild';
    }
    let traversableRoute = route;
    while (traversableRoute) {
        if (perItemCall(traversableRoute)) {
            break;
        }
        traversableRoute = traversableRoute[key];
        if (!traversableRoute) {
            break;
        }
    }
}

function _traverseRouteForData(
    route: ActivatedRoute,
    fromRoot = false
): { route: ActivatedRoute; data: Data } {
    let traversableRoute = route;
    let data: Data = {};
    let currentRoute: ActivatedRoute = route;
    if (fromRoot) {
        traversableRoute = route.root;
    }
    _traverseRoute(
        traversableRoute,
        (activatedRoute: ActivatedRoute) => {
            if (activatedRoute.snapshot && activatedRoute.snapshot.data) {
                data = { ...data, ...activatedRoute.snapshot.data };
                currentRoute = activatedRoute;
            }
            if (fromRoot && activatedRoute === route) {
                return true;
            }

            return false;
        },
        'firstChild'
    );

    return { route: currentRoute, data };
}

export interface RegisterRequest {
    firstName: string;
    lastName: string;
    agencyName?: string;
    email: string;
    password: string;
    zipCode?: string;
    recaptcha?: string;
    jobTitle?: string;
    agencyId?: string;
}

export interface RegisterResponse {
    id: string;
    email: string;
    verified: boolean;
}

export interface VerifyUser {
    userId: string;
    timestamp: string;
    signature: string;
    recaptcha?: string;
}

export enum RoleType {
    ADMIN_ONLY = 'admin_only',
    ADMIN_AGENT = 'admin_agent',
    AGENT = 'agent_only',
    SUB_AGENT = 'sub_agent',
}
export enum UserRoleType {
    ADMIN_AGENT = 'admin_agent',
    AGENT = 'agent_only',
    SUB_AGENT = 'sub_agent',
}

export function convertRole(role: RoleType | UserRoleType): ReturnedRole {
    switch (role.toLowerCase()) {
        case 'admin_only':
            return 'Admin';
        case 'admin_agent':
            return 'Admin/Agent';
        case 'agent_only':
            return 'Agent';
        case 'sub_agent':
            return 'Sub-Agent';
    }
}

export type ReturnedRole = 'Admin' | 'Admin/Agent' | 'Agent' | 'Sub-Agent';

export interface User {
    id:                     string;
    phoneNumber:            string;
    address:                null;
    userType?:              RoleType;
    avatar:                 null;
    zipCode:                string;
    isAdmin?:               boolean;
    agencyId:               string;
    setupAttributes?:       boolean;
    firstName:              string;
    lastName:               string;
    email:                  string;
    isActive:               boolean;
    hasSetPassword:         boolean;
    passwordSetEmailSentAt: null;
}

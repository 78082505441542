<div class="player-container">
    <div class="player-header">
        <div>
            <p class="player-header__description">
                To help you navigate our site, we've created a series of short videos that will guide you through the key areas of our site.
            </p>
            <p class="player-header__contact">
                For help, contact <a class="player-header__email" href="mailto:support@benefitsculptor.com" target="_blank">support@benefitsculptor.com</a>
            </p>
        </div>
        <bs-close-button (clickEvent)="emitClose()"></bs-close-button>
    </div>
    <div class="row no-gutters video-list">
        <ng-container *ngIf="{ value: selectedVideo$$ | async } as selectedVideo">
            <div class="col-6">
                <div class="video-player-container">
                    <div class="video-player" *ngIf="selectedVideo.value" [innerHTML]="selectedVideo.value.embed?.html! | safe:'html' "></div>
                    <p class="video-hint">To view video in another tab, click on the title of the video below</p>
                </div>
            </div>
            <div class="col-6">
                <div class="video-list-container" *ngIf="categories$ | async as categories; else loadingTemplate">
                    <div class="video-list-header">
                        <img alt="Get Started" src="./assets/images/home/playlist.svg" />
                        <span class="video-list-header__count" *ngIf="allCategoriesPanelsAreClosed$$ | async; else videoCounterTemplate">
                            {{ selectedVideoCategoryIndex$$.value + 1 }} / {{ categories.length }}
                        </span>
                        <ng-template #videoCounterTemplate>
                            <span class="video-list-header__count">
                                {{ selectedVideoIndex$$.value + 1 }} / {{ categories[selectedVideoCategoryIndex$$.value].videos.length }}
                            </span>
                        </ng-template>
                    </div>
                    <div class="video-list-body">
                        <ngb-accordion [activeIds]="CATEGORY_PANEL_ID_PREFIX + 0" (panelChange)="panelChange($event, categories)" #ngbAccordion>
                            <ngb-panel *ngFor="let category of categories; let categoryIndex = index" id="{{CATEGORY_PANEL_ID_PREFIX + categoryIndex}}">
                                <ng-template ngbPanelHeader let-opened="opened">
                                    <button ngbPanelToggle class="accordion-header">
                                        {{category.name}}
                                        <i *ngIf="!opened" class="fa fa-chevron-down accordion-icon" aria-hidden="true"></i>
                                        <i *ngIf="opened" class="fa fa-chevron-up accordion-icon" aria-hidden="true"></i>
                                    </button>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div
                                        *ngFor="let video of category.videos; let i = index"
                                        [ngClass]="video.uri === selectedVideo.value?.uri ? 'video-item active' : 'video-item'"
                                        (click)="onSelectVideo(video, i, categoryIndex)"
                                    >
                                        <span class="video-name">{{ video.name }}</span>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
                <ng-template #loadingTemplate>
                    <div class="loading">
                        <mat-spinner [diameter]="40" color="primary"></mat-spinner>
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </div>
</div>

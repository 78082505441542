import { Component, Input } from '@angular/core';
import { AgencyUser } from '@besc/agency';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'besc-delete-agency-user-dialog',
    templateUrl: './delete-agency-user-dialog.component.html',
    styleUrls: ['./delete-agency-user-dialog.component.css'],
})
export class DeleteAgencyUserDialogComponent {
    private _working = new BehaviorSubject(false);

    @Input() user: AgencyUser;
    working$ = this._working.asObservable();

    constructor(
        private _http: HttpClient,
        private _activeModal: NgbActiveModal
    ) {}

    deleteUser() {
        this._http.delete(`/users/${this.user.id}`).subscribe(() => {
            this._activeModal.close();
            this._working.next(false);
        });
    }

    dismiss(reason: string) {
        this._activeModal.dismiss(reason);
    }
}

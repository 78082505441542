import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'besc-settings-menu',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  goAccountSettings() {
    this._router.navigate(['/app/account-settings'])
  }

  goAgencyInformation() {
    this._router.navigate(['/app/manage/agency/detail'])
  }
}

import { Injectable } from '@angular/core';
import { Dependent, Employee, EmployeeCurrentHealthPlan } from '../interfaces';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class EmployeeFacade {
    private _getEmployees: BehaviorSubject<{
        employerId: string;
        effectiveDate?: Moment;
    }> = new BehaviorSubject<{
        employerId: string;
        effectiveDate?: Moment;
    }>(null);

    all$: Observable<Employee[]> = this._getEmployees.pipe(
        switchMap(({ employerId, effectiveDate }) => {
            if (employerId === null) {
                return of([]);
            }

            let params = new HttpParams();
            if (effectiveDate) {
                params = params.append(
                    'effectiveDate',
                    effectiveDate.format(moment.HTML5_FMT.DATE)
                );
            }

            return this._http.get<Employee[]>(
                'employers/' + employerId + '/employees',
                {
                    params,
                }
            );
        }),
        map((employees) =>
            employees.map((employee) => {
                employee.members = 1;

                if (employee.dependents) {
                    employee.members = employee.dependents.length + 1;
                }

                employee.fullName =
                    employee.firstName + ' ' + employee.lastName;
                employee.reversedName =
                    employee.lastName + ', ' + employee.firstName;

                return employee;
            })
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    constructor(private _http: HttpClient) {}

    getEmployees(employerId: string, effectiveDate: Moment = null) {
        this._getEmployees.next({
            employerId,
            effectiveDate,
        });
    }

    getEmployeesRequest(employerId: string, effectiveDate: string = null) {
        let params = new HttpParams();
        if (effectiveDate) {
            params = params.append('effectiveDate', effectiveDate);
        }
        return this._http.get<Employee[]>(
            'employers/' + employerId + '/employees',
            {
                params,
            }
        );
    }

    addNew(employerId: string, newEmployee: any): Observable<Employee> {
        return this._http.post<Employee>(
            'employers/' + employerId + '/employees',
            newEmployee
        );
    }

    delete(id: string) {
        return this._http.delete('employees/' + id);
    }

    get(employeeId: string) {
        return this._http.get('employees/' + employeeId);
    }

    update(value: Employee): Observable<Employee> {
        return this._http.patch<Employee>('employees/' + value.id, value);
    }

    updateDependents(value: Dependent[], employeeId: string) {
        return this._http.put<Dependent[]>(
            'employees/' + employeeId + '/dependents',
            value
        );
    }

    getPlans(employeeId: string, effectiveDate: Moment) {
        const params = new HttpParams().append(
            'effectiveDate',
            effectiveDate.format(moment.HTML5_FMT.DATE)
        );
        return this._http.get('employees/' + employeeId + '/plans', {
            params,
        });
    }

    getCurrentPlan(
        employeeId: string,
        effectiveDate: Moment
    ): Observable<EmployeeCurrentHealthPlan> {
        const params = new HttpParams().append(
            'effectiveDate',
            effectiveDate.format(moment.HTML5_FMT.DATE)
        );
        return this._http.get<EmployeeCurrentHealthPlan>(
            'employees/' + employeeId + '/current-plan',
            {
                params,
            }
        );
    }

    uploadEmployees(file: File, employerId: string) {
        const formData = new FormData();

        formData.append('employee_upload_file', file);
        formData.append('employer_id', employerId);

        return this._http.post<{
            numEmployeesCreated: number;
            numDependentsCreated: number;
        }>('/api/employer-plans/employee_upload/', formData);
    }
}

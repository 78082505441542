<span
    [ngClass]="{
        'loading-indicator': loading
    }"
>
    <ng-content></ng-content>
    <span
        *ngIf="loading"
        class="spinner-border spinner-border-sm align-middle ms-2"
    ></span
></span>

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
    FormControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as clone from 'clone';

import { SmallEmployer } from '@benefit-sculptor/small-employer';
import { BescValidators } from '@benefit-sculptor/core';
import { getStateAndCityFrom } from '@besc/shared';
import { User, RoleType } from '@benefit-sculptor/auth';
import { ManageService } from '@besc/admin';

import { EmployerService } from '../../services/employer.service';
import { UserLicenseInfo } from 'libs/admin/src/lib/models/Users_License.model';

@Component({
    selector: 'besc-add-new-group-dialog',
    templateUrl: './add-new-group-dialog.component.html',
    styleUrls: ['./add-new-group-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddNewGroupDialogComponent implements OnInit {
    private _loading$$ = new BehaviorSubject<boolean>(false);
    newEmployerForm: UntypedFormGroup = this._fb.group({
        companyName: ['', [Validators.required, Validators.maxLength(255)]],
        contactInfo: this._fb.group({
            zipCode: [null, BescValidators.requiredZipCode],
        }),
    });
    loading$ = this._loading$$.asObservable();
    users$: Observable<UserLicenseInfo[]>;

    currentUser: User;
    roleType = RoleType;

    constructor(
        private _activeModal: NgbActiveModal,
        private _fb: UntypedFormBuilder,
        private _employers: EmployerService,
        private _toastr: ToastrService,
        private _manageService: ManageService
    ) {}

    ngOnInit(): void {
        if (this.currentUser.userType !== RoleType.AGENT) {
            this.loadUsers();
            this.addUserFieldToForm();
        }
    }

    addUserFieldToForm(): void {
        this.newEmployerForm.addControl('user', new FormControl('', Validators.required));
    }
    
    loadUsers(): void {
        this._loading$$.next(true);
        this.users$ = this._manageService.getUsers()
        .pipe(
            map((data) => data.users.filter((user) => [RoleType.AGENT, RoleType.ADMIN_AGENT].includes(user.role))),
            finalize(() => this._loading$$.next(false))
        )
    }

    close(result: any): void {
        this._activeModal.close(result);
    }

    dismiss(reason: any): void {
        this._activeModal.dismiss(reason);
    }

    createEmployee() {
        if (this.newEmployerForm.valid) {
            this._loading$$.next(true);
            const smallEmployer = clone(this.newEmployerForm.value);

            getStateAndCityFrom(smallEmployer.contactInfo.zipCode).then(
                (data) => {
                    smallEmployer.contactInfo = {
                        ...smallEmployer.contactInfo,
                        state: data['state'],
                        city: data['city'],
                    };

                    this._employers
                        .addNew({ ...smallEmployer})
                        .pipe(finalize(() => this._loading$$.next(false)))
                        .subscribe((employer: SmallEmployer) => {
                            this._activeModal.close(employer);
                            this._toastr.success(
                                'Successfully Create New Employer'
                            );
                        });
                }
            );
        }
    }
}

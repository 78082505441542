import { Address, Gender } from '@benefit-sculptor/core';
import { Moment } from 'moment';

interface PlanCost {
    total: number;
    employer: number;
}

export interface EmployeeContactInfo {
    address: string;
    city: string;
    postalCode: string;
    email?: string;
    phoneBusiness?: string;
    phonePersonal?: string;
}

export interface DependentHealthElection {
    dependentId: string;
    employeeElectionId?: string;
    enrolled: boolean;
    effectiveDate: string;
    terminationDate: string;
}

export interface EmployeeCurrentHealthPlan {
    id: string;
    electionId: string;
    name: string;
    planGenericName: string;
    healthPlan: {
        id: string;
        name: string;
        networkName: string;
    };
    issuer: {
        id: string;
        name: string;
    };
    effectiveDate: string;
    terminationDate: string;
    dependentElections: DependentHealthElection[];
    monthlyCost?: PlanCost;
    waivedCoverage?: boolean;
}

export interface EmployeeHealthElection {
    id: string;
    planId: string;
    employeeId: string;
    effectiveDate: string | Moment;
    terminationDate: string | Moment;
    waivedCoverage: boolean;
}

export interface Employee {
    id: string;
    firstName: string;
    lastName: string;
    contactInfo: EmployeeContactInfo;
    fullName?: string;
    reversedName?: string;
    dateOfBirth: string;
    gender: Gender;
    tobaccoUse: boolean;
    employerId: string;
    // plan: string;
    // effective: string;
    dependentCount?: number;
    currentHealthPlan?: EmployeeCurrentHealthPlan;
    healthElections?: EmployeeHealthElection[];
    dependents: Dependent[];
    employmentStatus: EmploymentStatus;
}

export type CreateEmployeeModel = Pick<
    Employee,
    | 'firstName'
    | 'lastName'
    | 'contactInfo'
    | 'dateOfBirth'
    | 'gender'
    | 'tobaccoUse'
    | 'dependents'
    | 'employmentStatus'
>;

export enum HourlyStatus {
    FullTime = 'full_time',
    PartTime = 'part_time',
}

export interface EmploymentStatus {
    id: string;
    employee: string;
    fulltimeHireDate: string;
    hourlyStatus: HourlyStatus;
    originalHireDate: string;
    reductionInHoursDate: string;
    terminationDate: string;
}

export interface Dependent {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: Gender;
    enrolledByDefault: boolean;
    relationship: Relationship;
    tobaccoUse: boolean;
}

export enum Relationship {
    SPOUSE = 'spouse',
    CHILD = 'child',
}

export interface Name {
    first: string;
    middle?: string;
    last: string;
    full?: string;
}

export interface EmployeeListFilter {
    nameContains?: string;
    carrier?: string[];
    plan?: string[];
    effectiveAfter?: string;
}

import {HttpParams} from '@angular/common/http';

export function buildQueryParamsWithArrays(
    query: any,
    join = true
): HttpParams {
    const keys = Object.keys(query);
    let params = new HttpParams();
    for (let i = 0; i < keys.length; i++) {
        if (query.hasOwnProperty(keys[i])) {
            const key = keys[i];
            if (Array.isArray(query[key])) {
                if (join) {
                    params = params.append(key, query[key].join(','));
                } else {
                    for (const value of query[key]) {
                        params = params.append(`${key}[]`, value);
                    }
                }
            } else if (query[key] !== null) {
                params = params.append(key, query[key]);
            }
        }
    }

    return params;
}

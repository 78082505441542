import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirstLetterPipe } from './first-letter.pipe';
import { GetObjectPipe } from './get-object.pipe';
import { JoinPipe } from './join.pipe';
import { SafePipe } from './safe.pipe';
import { TimeElapsedPipe } from './time-elapsed.pipe';
import { UserRolePipe } from './user-role.pipe';

@NgModule({
    declarations: [
        FirstLetterPipe,
        GetObjectPipe,
        JoinPipe,
        SafePipe,
        TimeElapsedPipe,
        UserRolePipe
    ],
    imports: [CommonModule],
    exports: [
        FirstLetterPipe,
        GetObjectPipe,
        JoinPipe,
        SafePipe,
        TimeElapsedPipe,
        UserRolePipe
    ]
})
export class CustomPipesModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthYearPickerComponent } from './components/month-year-picker/month-year-picker.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EffectiveDateDirective } from './directives/effective-date.directive';
import { TerminationDateDirective } from './directives/termination-date.directive';
import { ExpirationDateDirective } from './directives/expiration-date-directive';
import {
    NgbDatepickerModule,
    NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

export * from './components/month-year-picker/month-year-picker.component';

@NgModule({
    declarations: [
        MonthYearPickerComponent,
        EffectiveDateDirective,
        TerminationDateDirective,
        ExpirationDateDirective,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        NgbDatepickerModule,
        FontAwesomeModule,
        NgbDropdownModule,
    ],
    exports: [
        MonthYearPickerComponent,
        EffectiveDateDirective,
        TerminationDateDirective,
        ExpirationDateDirective,
    ],
})
export class DatesModule {}

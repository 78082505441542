export const ERROR_MAPPING: { [key: string]: (error) => string } = {
    required: (error) => `This field is required`,
    url: (error) => `Incorrectly formatted URL`,
    email: (error) => `Incorrectly formatted email`,
    numeric: (error) => `You can only use numbers`,
    min: (error) => {
        return `This value cannot be less than ${error.min}`;
    },
    max: (error) => {
        return `This value cannot be more than ${error.max}`;
    },
    minlength: (error) =>
        `This can't be shorter than ${error.requiredLength} characters`,
    maxlength: (error) =>
        `This can't be longer than than ${error.requiredLength} characters`,
    pattern: (error) => {
        return `This field doesn't meet the needed requirements`;
    },
    complexity: (error) => {
        return "This password doesn't meet all the criteria below";
    },
    ConfirmPassword: (error) => {
        return 'Passwords do not match';
    },
    existing: (error) => {
        return 'This email already exists.';
    },
};

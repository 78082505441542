<p class="slider-label">{{ label }}</p>
<div class="row contribution-slider" [formGroup]="sliderForm">
    <div class="col-lg-3">
        <label class="form-label">Contribution Type</label>
        <ng-select formControlName="type" placeholder="Contribution Type..." [clearable]="false" required>
            <ng-option [value]="'percentage'">Percentage</ng-option>
            <ng-option [value]="'defined_contribution'">Defined</ng-option>
            <ng-option [value]="'fixed_withholding'">Fixed</ng-option>
        </ng-select>
        <span class="form-text" *ngIf="sliderForm.controls.type.value === 'percentage'">
            Percentage the Employee Pays
        </span>
    </div>
    <div class="col-lg-6" *ngIf="sliderConfig$ | async as sliderConfig">
        <label for="customRange1" class="form-label">Contribution Value</label>
        <input type="range" [max]="sliderConfig.max" [min]="sliderConfig.min" [step]="sliderConfig.step"
            formControlName="sliderValue" class="form-range" id="customRange1" />
    </div>
    <div class="col-lg-3">
        <label for="contributionValue" class="form-label">Value</label>
        <div class="input-group">
            <span class="input-group-text" *ngIf="sliderForm.value.type !== 'percentage'">$</span>
            <input id="contributionValue" class="form-control" type="number" formControlName="numberValue"
                placeholder="Termination Date" />
            <span class="input-group-text" *ngIf="sliderForm.value.type === 'percentage'">%</span>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as WizardActions from './wizard.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import {
    ActivatedStep,
    Step,
    StepRoute,
    StepRouteDefinition,
    Steps,
    WizardState,
} from './wizard.models';

@Injectable()
export abstract class WizardFacade {
    protected _currentActivatedStep$: BehaviorSubject<ActivatedStep> = new BehaviorSubject<ActivatedStep>(null);
    abstract steps$: Observable<Step[]>;
    abstract currentStep$: Observable<ActivatedStep>;
    abstract stepRouting$: Observable<StepRoute>;
    abstract currentWizardProgress$: Observable<number>;
    abstract wizardState$: Observable<WizardState>;
    abstract navigating$: Observable<boolean>;
    abstract wizardKey: string;

    constructor(
        protected _store: Store<any>,
    ) { }

    get activatedStep(): ActivatedStep {
        return this._currentActivatedStep$.getValue();
    }

    dispatchToWizard(action: any, props?: any) {
        this._store.dispatch(action({ ...props, key: this.wizardKey }));
    }

    navigateToStep(path: string[]) {
        this.dispatchToWizard(WizardActions.navigateToStep, { path });
    }

    finishedNavigating() {
        Promise.resolve(null).then(() =>
            this.dispatchToWizard(WizardActions.finishedNavigating, {})
        );
    }

    navigateToNextStep(value: any) {
        this.dispatchToWizard(WizardActions.goToNextStep, { value });
    }

    navigateToPreviousStep(value?: any) {
        this.dispatchToWizard(WizardActions.goToPreviousStep, { value });
    }

    finishWizard() {
        this.dispatchToWizard(WizardActions.finishWizard, {});
    }

    setStepRouting(stepRouting: StepRouteDefinition, steps: Steps) {
        this.dispatchToWizard(WizardActions.setStepRouting, {
            stepRouting,
            steps,
        });
    }

    setInitialState(state?: Partial<WizardState>) {
        this.dispatchToWizard(WizardActions.resetState, { state });
    }

    setStepValue(stepKey: string, value: any) {
        this.dispatchToWizard(WizardActions.setStepValue, { stepKey, value });
    }

    setValue(value: any) {
        this.dispatchToWizard(WizardActions.setValue, { value });
    }

    setCurrentValueToForm(form: UntypedFormGroup) {
        const currentValue = this.activatedStep.value;
        if (currentValue) {
            form.setValue(currentValue);
            form.markAsPristine();
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthFacadeService, RecaptchaService, VerifyUser } from '@benefit-sculptor/auth';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, of } from 'rxjs';
import {
    filter,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';

@Component({
    selector: 'besc-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, OnDestroy {
    private _destroy = new Subject();
    type$: Observable<any> = this._route.paramMap.pipe(
        map((paramMap) => paramMap.get('type')),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    constructor(
        private _route: ActivatedRoute,
        private _auth: AuthFacadeService,
        private _router: Router,
        private _toastr: ToastrService,
        private _recaptcha: RecaptchaService,
    ) {}

    async ngOnInit(): Promise<void> {
        const recaptcha = await this._recaptcha.getRecaptcha('verifyUser');
        this.type$
            .pipe(
                tap((type) => {
                    if (!['user', 'email'].includes(type)) {
                        this._router.navigate(['/auth/login']);
                    }
                }),
                switchMap((type) => {
                    const params = this._route.snapshot.queryParamMap;
                    if (type === 'user') {
                        const verify: VerifyUser = {
                            userId: params.get('user_id'),
                            timestamp: params.get('timestamp'),
                            signature: params.get('signature'),
                            recaptcha,
                        };
                        return this._auth.verifyUser(verify);
                    }

                    return of(null);
                }),
                filter((verify) => !!verify),
                takeUntil(this._destroy)
            )
            .subscribe(
                (response) => {
                    this._toastr.success('Successfully Verified');
                    const queryParams = this._route.snapshot.queryParamMap;
                    const showSetPassword =
                        !!queryParams.get('pass_signature') &&
                        !!queryParams.get('pass_timestamp');

                    if (showSetPassword) {
                        this._router.navigate(['auth/verify/set-password'], {
                            queryParamsHandling: 'preserve',
                        });
                    } else {
                        setTimeout(
                            () => this._router.navigate(['/auth/login']),
                            2000
                        );
                    }
                },
                (error) => {
                    this._toastr.error(error.error.detail, 'Failed to Verify');
                    this._router.navigate(['/auth/login']);
                }
            );
    }

    ngOnDestroy() {
        this._destroy.next();
    }
}

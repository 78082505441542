import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { RouteParserService } from '@benefit-sculptor/router';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotAdminGuard implements CanActivate, CanActivateChild {
    constructor(
        private _user: UserService,
        private _router: Router,
        private _routeParser: RouteParserService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._user.isAdmin$.pipe(
            map((isAdmin) => 
                isAdmin
                    ? this._router.createUrlTree(next.data.redirectIfAdmin)
                    : true
            )
        );
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        const data = this._routeParser.getAllRouteData(next);
        return this._user.isAdmin$.pipe(
            map((isAdmin) => {
                const newRoute = this._router.createUrlTree(
                    data.redirectIfAdmin
                );
                return isAdmin ? newRoute : true;
            })
        );
    }
}

import { ActivatedStep, StepRoute } from '../+state/wizard.models';
import { deepEqual } from '../../helpers/';

export function transformRouteToActivatedStep(
    path: string[],
    route: StepRoute,
    value: { [key: string]: any }
) {
    const valueKeys = Object.keys(value);

    const { activatedStep, currentRoute } = createActivatedStepWithValue(
        [...path],
        route,
        value
    );

    if (path.length < valueKeys.length && currentRoute) {
        const missing = valueKeys.filter(item => !path.includes(item));

        setChildActivatedSteps(
            currentRoute,
            activatedStep,
            missing as string[],
            value
        );
    }

    addActivatedStepIfLastIsComplete(activatedStep);

    return activatedStep;
}

export function addActivatedStepIfLastIsComplete(activatedStep: ActivatedStep) {

    if (!activatedStep) return;

    let lastStep = activatedStep;
    while (lastStep.firstChild) {
        lastStep = lastStep.firstChild;
    }

    const childKeys = Object.keys(lastStep.route.children);
    if (lastStep.value && childKeys.length === 1) {
        generateActivatedStepFromRoute(
            lastStep.route.children[childKeys[0]].route,
            lastStep
        );
    } else if (lastStep.value && childKeys.length > 1) {
        for (const child of childKeys) {
            if (lastStep.route.children[child].condition(lastStep.value)) {
                generateActivatedStepFromRoute(
                    lastStep.route.children[child].route,
                    lastStep
                );
            }
        }
    }
}

export function createActivatedStepWithValue(
    path: string[],
    route: StepRoute,
    value: any
) {
    let currentPath = path.shift();
    let currentRoute = route;
    let activatedStep: ActivatedStep = null;
    while (currentPath && currentRoute) {
        if (currentRoute.key !== currentPath) {
            throw new Error('Step Route does not match the passed in path');
        }

        activatedStep = generateActivatedStepFromRoute(
            currentRoute,
            activatedStep
        );
        if (value.hasOwnProperty(currentPath)) {
            activatedStep.value = value[currentPath];
        }

        currentPath = path.shift();
        if (currentPath) {
            currentRoute = currentRoute.children[currentPath]?.route;
        }
    }

    return {
        currentRoute,
        activatedStep
    };
}

export function setChildActivatedSteps(
    currentRoute: StepRoute,
    activatedStep: ActivatedStep,
    missing: string[],
    value: { [key: string]: any }
) {
    let childActivatedStep = activatedStep;

    while (currentRoute) {
        const childrenKeys = Object.keys(currentRoute.children);
        let childKey = null;
        for (const missingItem of missing) {
            if (childrenKeys.includes(missingItem)) {
                childKey = missingItem;
                break;
            }
        }

        if (childKey) {
            currentRoute = currentRoute.children[childKey].route;
            childActivatedStep = generateActivatedStepFromRoute(
                currentRoute,
                childActivatedStep
            );
            childActivatedStep.value = value[childKey];
        } else {
            currentRoute = null;
        }
    }
}

export function getNextStepPath(
    value: any,
    currentActivatedStep: ActivatedStep
): null | string[] {
    currentActivatedStep.value = value;
    const childrenKeys = Object.keys(currentActivatedStep.route.children);
    if (childrenKeys.length === 1) {
        if (currentActivatedStep.firstChild) {
            return currentActivatedStep.firstChild.route.pathFromRoot;
        }
        return currentActivatedStep.route.children[childrenKeys[0]].route
            .pathFromRoot;
    } else if (childrenKeys.length === 0) {
        return currentActivatedStep.route.pathFromRoot;
    }

    for (const child of childrenKeys) {
        if (currentActivatedStep.route.children[child].condition(value)) {
            if (
                currentActivatedStep.firstChild &&
                deepEqual(
                    currentActivatedStep.route.children[child].route
                        .pathFromRoot,
                    currentActivatedStep.firstChild.route.pathFromRoot
                )
            ) {
                return currentActivatedStep.firstChild.route.pathFromRoot;
            }
            return currentActivatedStep.route.children[child].route
                .pathFromRoot;
        }
    }

    return currentActivatedStep.route.pathFromRoot;
}

export function generateActivatedStepFromRoute(
    stepRoute: StepRoute,
    parent?: ActivatedStep
): ActivatedStep {
    const step = {
        route: stepRoute,
        step: stepRoute.step,
        key: stepRoute.key,
        firstChild: null,
        root: null,
        parent: null,
        value: null
    };
    step.root = step;
    if (parent) {
        step.root = parent.root;
        step.parent = parent;
        parent.firstChild = step;
    }
    return step;
}

export function getStepFromActivatedStep(
    activatedStep: ActivatedStep,
    step: string
) {
    let route = activatedStep.root;

    while (route) {
        if (route.key === step) {
            return route;
        }

        route = activatedStep.firstChild;
    }

    return null;
}

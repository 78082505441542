
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { API_LIST } from '../../helpers';

enum REQUEST_METHODS {
    GET = '-GET',
    POST = '-POST',
};

@Injectable()
export class ApiCacheInterceptor implements HttpInterceptor {

    private cache = new Map<string, HttpResponse<any>>();

    private endpointsToCache = new Set([
        API_LIST.SUBSCRIPTION_SUMMARY + REQUEST_METHODS.GET
    ]);
    
    // key --> endpoint that triggers refreshing. value --> endpoint(s) to be refreshed
    private endpointsToRefreshOn: Record<string, string[]> = {
        [API_LIST.ADD_USER_LICENSES + REQUEST_METHODS.POST]: [API_LIST.SUBSCRIPTION_SUMMARY + REQUEST_METHODS.GET],
        [API_LIST.ADD_STATES_LICENSES + REQUEST_METHODS.POST]: [API_LIST.SUBSCRIPTION_SUMMARY + REQUEST_METHODS.GET],
    };
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const url = req.url.replace('/api/', '') + '-' + req.method;
        this.refreshCache(url);
        if (this.endpointsToCache.has(url)) {
            const cachedResponse = this.cache.get(url);
            const refresh = req.params.get('refresh');

            if (cachedResponse && !refresh) {
                return of(cachedResponse);
            }

            return next.handle(req).pipe(
                tap((res) => {
                    if (res instanceof HttpResponse) {
                        this.cache.set(url, res);
                    }
                })
            )
        }

        return next.handle(req);
    }

    refreshCache(url: string): void {
        const endpointsToBeRefreshed = this.endpointsToRefreshOn[url] ?? [];
        endpointsToBeRefreshed.forEach(endpoint => this.cache.set(endpoint, null));
    }
}
import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../header/header.service';

@Component({
  selector: 'besc-navigation-title',
  templateUrl: './navigation-title.component.html',
  styleUrls: ['./navigation-title.component.scss']
})
export class NavigationTitleComponent implements OnInit {

  title$ = this._header.navigationTitle$;

  constructor(
    private _header: HeaderService
  ) { }

  ngOnInit() {
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromEmployees from './+state/employees.reducer';
import { EmployeesEffects } from './+state/employees.effects';
import { EmployeesFacade } from './+state/employees.facade';
import { DataPersistence } from '@nrwl/angular';
import { RefreshEmployeesComponent } from './components/refresh-employees/refresh-employees.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromEmployees.EMPLOYEES_FEATURE_KEY,
            fromEmployees.reducer
        ),
        EffectsModule.forFeature([EmployeesEffects]),
        FontAwesomeModule,
        MatTooltipModule,
    ],
    providers: [EmployeesFacade, DataPersistence],
    declarations: [RefreshEmployeesComponent],
    exports: [RefreshEmployeesComponent],
})
export class EmployeeModule {}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { DeleteConfirmationModalParams } from '../models/DeleteConfirmationModalParams.model';

@Component({
    selector: 'besc-delete-confirmation-modal',
    templateUrl: './delete-confirmation-modal.component.html',
    styleUrls: ['./delete-confirmation-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteConfirmationModalComponent {

    @Input() params!: DeleteConfirmationModalParams

    constructor(private _modal: NgbActiveModal) {}

    close(result: boolean) {
        this._modal.close(result);
    }
}

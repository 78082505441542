import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerListComponent } from './components/employer-list/employer-list.component';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    NgbDropdownModule,
    NgbModalModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FormHelpersModule } from '@benefit-sculptor/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddNewGroupDialogComponent } from './components/add-new-group-dialog/add-new-group-dialog.component';
import { DeleteEmployerModalComponent } from './components/delete-employer-modal/delete-employer-modal.component';
import { EmployerStatusBadgeComponent } from './components/employer-status-badge/employer-status-badge.component';
import { SmallEmployersSelectorComponent } from './components/small-employers-selector/small-employers-selector.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpinnerModule } from '@benefit-sculptor/spinner';

@NgModule({
    declarations: [
        EmployerListComponent,
        AddNewGroupDialogComponent,
        DeleteEmployerModalComponent,
        EmployerStatusBadgeComponent,
        SmallEmployersSelectorComponent,
    ],
    imports: [
        CommonModule,
        MatTableModule,
        FontAwesomeModule,
        NgbDropdownModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatSortModule,
        MatSnackBarModule,
        RouterModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        NgbModalModule,
        NgbTooltipModule,
        NgxMatSelectSearchModule,
        NgSelectModule,
        FormHelpersModule,
        SpinnerModule,
    ],
    exports: [
        EmployerListComponent,
        EmployerStatusBadgeComponent,
        SmallEmployersSelectorComponent,
    ],
})
export class EmployerModule {}

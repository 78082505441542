import { createAction, props } from '@ngrx/store';
import {
    AgeRateCurve,
} from './create-plans.models';
import {
    CreateExistingPlanRequest,
    EmployerPlan,
    Issuer,
    SimplePlan
} from '@besc/plan';
import { CreatePlansStep } from '../steps/steps';

export const loadCreatePlans = createAction('[CreatePlans] Load CreatePlans');

export const selectCurrentStep = createAction(
    '[CreatePlans] Select Current State',
    props<{
        step: CreatePlansStep;
    }>()
);

export const loadAgeRateCurve = createAction(
    '[CreatePlans] Load age rate curve',
    props<{
        zipCode: string;
        effectiveDate: string;
    }>()
);

export const setAgeRateCurve = createAction(
    '[CreatePlans] Set Age Rate Curve',
    props<{
        ageRateCurve: AgeRateCurve;
    }>()
);

export const setAllAgeRateCurves = createAction(
    '[CreatePlans] Set All Age Rate Curves',
    props<{
        curves: AgeRateCurve[];
    }>()
);

export const loadAgeRateCurveFailure = createAction(
    '[CreatePlans] Load Age Rate Curve Failure',
    props<{
        zipCode: string;
        effectiveDate: string;
    }>()
);

export const loadCurrentIssuers = createAction(
    '[CreatePlans] Load current issuers',
    props<{
        zipCode: string;
        year: string;
    }>()
);

export const setCurrentIssuers = createAction(
    '[CreatePlans] Set Issuers',
    props<{
        issuers: Issuer[];
    }>()
);

export const loadPlanList = createAction(
    '[CreatePlans] Load plan list',
    props<{
        zipCode: string;
        year: string;
    }>()
);

export const loadPlanListSuccess = createAction(
    '[CreatePlans] Loan plan list success',
    props<{
        plans: SimplePlan[];
    }>()
);

export const resetPlanList = createAction('[CreatePlans] Reset plan list');

export const cloneExistingPlan = createAction(
    '[CreatePlans] Clone existing plan',
    props<{
        planId: string;
        zipCode: string;
        effectiveDate: string;
        employerId: string;
    }>()
);

export const clonedExistingPlan = createAction(
    '[CreatePlans] Cloned existing plan'
);

export const saveExistingPlan = createAction(
    '[CreatePlans] Save Existing Plan',
    props<{
        employerId: string;
        plan: CreateExistingPlanRequest;
    }>()
);

export const saveExistingPlanSuccess = createAction(
    '[CreatePlans] Save Existing Plan Success',
    props<{
        employerPlan: EmployerPlan;
    }>()
);

export const saveExistingPlanFailure = createAction(
    '[CreatePlans] Save Existing Plan Failure'
);

import { SortDirection } from '@angular/material/sort';
import * as dotProp from 'dot-prop';
import { isNumeric } from './numbers';

export function sortCompareFn(
    compareKeys: string | string[],
    direction: SortDirection
) {
    return (a, b) => {
        let [varA, varB] = getValuesForComparison(a, b, compareKeys);

        if (varA === '' || varB === '') {
            return 0;
        }

        if (isNumeric(varA) && isNumeric(varB)) {
            varA = Number(varA) as any;
            varB = Number(varB) as any;
        }

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return direction === 'desc' ? comparison * -1 : comparison;
    };
}

function getValuesForComparison(
    first: any,
    second: any,
    compareKeys: string | string[]
) {
    if (!Array.isArray(compareKeys)) {
        compareKeys = [compareKeys];
    }
    let varA = '';
    let varB = '';
    for (const compareKey of compareKeys) {
        if (
            !dotProp.has(first, compareKey) ||
            !dotProp.has(second, compareKey)
        ) {
            continue;
        }
        const compareValueA =
            typeof dotProp.get(first, compareKey) === 'string'
                ? (dotProp.get(first, compareKey) as string).toUpperCase()
                : dotProp.get(first, compareKey);
        varA = varA + compareValueA;
        const compareValueB =
            typeof dotProp.get(second, compareKey) === 'string'
                ? (dotProp.get(second, compareKey) as string).toUpperCase()
                : dotProp.get(second, compareKey);
        varB = varB + compareValueB;
    }

    return [varA, varB];
}

/** Clamps a number between zero and a maximum. */
function clamp(value: number, max: number): number {
    return Math.max(0, Math.min(max, value));
}

export function arrayChunk<T>(array: T[], size: number): T[][] {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

// Imported from https://github.com/epoberezkin/fast-deep-equal
export function deepEqual(a: any, b: any) {
    if (a === b) return true;

    if (a && b && typeof a === 'object' && typeof b === 'object') {
        const arrA = Array.isArray(a),
            arrB = Array.isArray(b);
        let i, length, key;

        if (arrA && arrB) {
            length = a.length;
            if (length !== b.length) return false;
            for (i = length; i-- !== 0; )
                if (!deepEqual(a[i], b[i])) return false;
            return true;
        }

        if (arrA !== arrB) return false;

        const dateA = a instanceof Date,
            dateB = b instanceof Date;
        if (dateA !== dateB) return false;
        if (dateA && dateB) return a.getTime() == b.getTime();

        const regexpA = a instanceof RegExp,
            regexpB = b instanceof RegExp;
        if (regexpA !== regexpB) return false;
        if (regexpA && regexpB) return a.toString() == b.toString();

        const keys = Object.keys(a);
        length = keys.length;

        if (length !== Object.keys(b).length) return false;

        for (i = length; i-- !== 0; )
            if (!Object.prototype.hasOwnProperty.call(b, keys[i])) return false;

        for (i = length; i-- !== 0; ) {
            key = keys[i];
            if (!deepEqual(a[key], b[key])) return false;
        }

        return true;
    }

    return a !== a && b !== b;
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthFacadeService, RoleType, UserService } from '@benefit-sculptor/auth';
import { AgencyService } from '@besc/agency';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewActivityAsComponent } from '../view-activity-as/view-activity-as.component';

@Component({
    selector: 'besc-user-profile-menu',
    templateUrl: './user-profile-menu.component.html',
    styleUrls: ['./user-profile-menu.component.scss'],
})
export class UserProfileMenuComponent implements OnInit {
    user$ = this._user.currentUser$;
    isAgencyOwner$ = this._agency.isAgencyOwner$;
    currentUser;
    isAdmin;
    users;
    allUsers;
    selectedActivityUser;
    filterText = '';

    @ViewChild('activityPopover') activityPopover;

    constructor(
        private _user: UserService,
        private _auth: AuthFacadeService,
        private _agency: AgencyService,
        protected _http: HttpClient,
        private _modal: NgbModal,
        private _router: Router
    ) { }

    ngOnInit() {
        this.user$.subscribe((user) => {
            this.currentUser = user;
            this.isAdmin = this.currentUser.userType.toLowerCase() === RoleType.ADMIN_ONLY || this.currentUser.userType.toLowerCase() === RoleType.ADMIN_AGENT;
        });

        this.getUsers().subscribe(data => {
            const users = data.users.filter(user => user.role.toLowerCase() === RoleType.AGENT);
            this.allUsers = users;
            this.users = users;
        });
    }

    search(text: string) {
        this.filterText = text.toLowerCase();

        this.users = this.allUsers.filter(user =>
            user.firstName.toLowerCase().includes(this.filterText) || user.lastName.toLowerCase().includes(this.filterText)
        );
    }

    logout() {
        this._auth.logout();
    }

    userProfileClass(user) {
        if (user && user.userType) {
            return 'bs-tag-' + user.userType.toLowerCase()
        }

        return 'bs-tag-agent_only'
    }

    goToAdminCenter() {
        if (this.currentUser.userType.toLowerCase() === RoleType.ADMIN_ONLY) {
            this._router.navigate(['/app/home']);
        } else {
            this._router.navigate(['/app/admin-center']);
        }
    }

    getUsers(): Observable<any> {
        return this._http.get<any>('/admin/users');
    }

    getUserAgency(userId): Observable<any> {
        return this._http.get<any>(`/agencies/${userId}/agency`);
    }

    avatarName(user) {
        let f = String(user.firstName)
        let l = String(user.lastName)
        return f.charAt(0).toUpperCase() + l.charAt(0).toUpperCase()
    }

    isSelectedUser(user) {
        return this.selectedActivityUser && user.userId === this.selectedActivityUser.userId
    }

    selectActivityUser(user) {
        this.selectedActivityUser = user;
    }

    confirmSelectActivityUser() {
        this.activityPopover.close();
        const ref = this._modal.open(ViewActivityAsComponent, { modalDialogClass: 'view-activity-as-dialog' });
        ref.componentInstance.selectedUser = this.selectedActivityUser;
        ref.result.then(
            result => {
                if (result) {
                    this.getUserAgency(result.userId).subscribe(data => {
                        localStorage.setItem('AS_USER', JSON.stringify(result));
                        localStorage.setItem('AS_USER_AGENCY', data.agencyId);
                        window.location.reload();
                    })
                } else {
                    this.selectedActivityUser = null;
                }
            }
        )
    }
}

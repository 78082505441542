import { Component, Input } from '@angular/core';
import { Agency, AgencyUser } from '@besc/agency';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserService } from '@benefit-sculptor/auth';
import { withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'besc-make-employee-owner',
    templateUrl: './transfer-ownership-dialog.component.html',
    styleUrls: ['./transfer-ownership-dialog.component.css'],
})
export class TransferOwnershipDialogComponent {
    private _working = new BehaviorSubject(false);

    @Input() newOwner: AgencyUser;
    @Input() agency: Agency;

    user$ = this._user.currentUser$;
    working$ = this._working.asObservable();

    constructor(
        private _user: UserService,
        private _http: HttpClient,
        private _activeModal: NgbActiveModal,
        private _router: Router
    ) {}

    transferOwnership() {
        this._http
            .post(`/agencies/${this.agency.id}/transfer-ownership`, {
                userId: this.newOwner.id,
            })
            .pipe(withLatestFrom(this.user$))
            .subscribe(([updatedAgency, user]) => {
                if (updatedAgency) {
                    this._activeModal.close(updatedAgency);
                    this._working.next(false);
                    if (!user.isAdmin) {
                        this._router.navigate(['/app/small-employers/list']);
                    }
                }
            });
    }

    dismiss(reason: string) {
        this._activeModal.dismiss(reason);
    }
}

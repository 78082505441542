import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    CanLoad,
    UrlSegment,
    Route,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { filter, map } from 'rxjs/operators';
import { RouteParserService } from '@benefit-sculptor/router';

@Injectable({
    providedIn: 'root',
})
export class IsAdminGuard implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _user: UserService,
        private _router: Router,
        private _routeParser: RouteParserService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._user.currentUser$.pipe(
            filter((user) => !!user),
            map((user) =>
                user.isAdmin
                    ? true
                    : this._router.createUrlTree(next.data.redirectIfNotAdmin)
            )
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        const data = this._routeParser.getAllRouteData(next);
        return this._user.currentUser$.pipe(
            filter((user) => !!user),
            map((user) =>
                user.isAdmin
                    ? true
                    : this._router.createUrlTree(data.redirectIfNotAdmin)
            )
        );
    }

    canLoad(route: Route) {
        this._user.currentUser$.subscribe();
        return true;
    }
}

import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { HttpClient, HttpEvent, HttpEventType, HttpParams, HttpResponse } from '@angular/common/http';
import {
    CreateEmployeeModel,
    Dependent,
    Employee,
    EmployeeCurrentHealthPlan,
    EmployeePlanResponse
} from '@besc/employee';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class EmployeeService {
    constructor(private _http: HttpClient) {}

    getEmployees(employerId: string, effectiveDate: string = null) {
        let params = new HttpParams();
        if (effectiveDate) {
            params = params.append('effectiveDate', effectiveDate);
        }
        return this._http.get<Employee[]>(
            'employers/' + employerId + '/employees',
            {
                params,
                observe: 'events',
                responseType: 'text' as 'json',
            }
        ).pipe(
            filter((event: HttpEvent<any>) => event.type === HttpEventType.Response),
            map((event: HttpResponse<string>) => JSON.parse(event.body))
        );
    }

    getEmployeePlans(employerId: string, effectiveDate: string = null) {
        let params = new HttpParams();
        if (effectiveDate) {
            params = params.append('effectiveDate', effectiveDate);
        }
        return this._http.get<EmployeePlanResponse>(
            'employers/' + employerId + '/employees/plans',
            {
                params,
            }
        );
    }

    addNew(
        employerId: string,
        newEmployee: CreateEmployeeModel
    ): Observable<Employee> {
        return this._http.post<Employee>(
            'employers/' + employerId + '/employees',
            newEmployee
        );
    }

    delete(id: string) {
        return this._http.delete('employees/' + id);
    }

    deleteEmployeeElections(electionId: string) {
        return this._http.delete(`employer-plans/employees/${electionId}`);
    }

    get(employeeId: string) {
        return this._http.get('employees/' + employeeId);
    }

    update(value: Employee): Observable<Employee> {
        return this._http.patch<Employee>('employees/' + value.id, value);
    }

    updateDependents(value: Dependent[], employeeId: string) {
        return this._http.put<Dependent[]>(
            'employees/' + employeeId + '/dependents',
            value
        );
    }

    getPlans(employeeId: string, effectiveDate: Moment) {
        const params = new HttpParams().append(
            'effectiveDate',
            effectiveDate.format(moment.HTML5_FMT.DATE)
        );
        return this._http.get('employees/' + employeeId + '/plans', {
            params,
        });
    }

    getCurrentPlan(
        employeeId: string,
        effectiveDate: Moment
    ): Observable<EmployeeCurrentHealthPlan> {
        const params = new HttpParams().append(
            'effectiveDate',
            effectiveDate.format(moment.HTML5_FMT.DATE)
        );
        return this._http.get<EmployeeCurrentHealthPlan>(
            'employees/' + employeeId + '/current-plan',
            {
                params,
            }
        );
    }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LayoutService {
    private _asideOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    asideOpen$ = this._asideOpen.pipe(distinctUntilChanged(), shareReplay(1));

    constructor() {}

    setAsideOpen(isOpen: boolean) {
        this._asideOpen.next(isOpen);
    }

    getAsideOpen(): boolean {
        return this._asideOpen.getValue();
    }

    toggleAside() {
        this._asideOpen.next(!this._asideOpen.getValue());
    }
}

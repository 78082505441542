import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AuthComponent } from './components/auth/auth.component';
import { NoSideLayoutComponent } from '@besc/layout';
import { VerifyComponent } from './pages/verify/verify.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { ForgotPasswordSuccessComponent } from './pages/forgot-password-success/forgot-password-success.component';

const routes: Routes = [
    {
        path: 'verify/set-password',
        component: SetPasswordComponent,
        data: {
            type: 'set',
        },
    },
    {
        path: 'verify/reset-password',
        component: SetPasswordComponent,
        data: {
            type: 'reset',
        },
    },
    {
        path: '',
        component: AuthComponent,
        data: {
            layout: NoSideLayoutComponent,
        },
        children: [
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'verify/:type',
                component: VerifyComponent,
            },
            {
                path: 'forgot-password',
                children: [
                    {
                        path: '',
                        component: ForgotPasswordComponent,
                    },
                    {
                        path: 'success',
                        component: ForgotPasswordSuccessComponent,
                    },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AuthRoutingModule {}

import {
    Steps,
    StepRoute,
    StepRouteChild,
    StepRouteDefinition
} from '../+state/wizard.models';

export function transformDefinitionToRoute(
    definition: StepRouteDefinition,
    steps: Steps,
    parent: StepRoute = null,
    root: StepRoute = null,
    pathFromRoot: string[] = []
): StepRoute {
    const defaultCondition = (step: any) => true;
    const children = {};
    const route = {
        step: steps[definition.step],
        key: definition.step,
        children,
        parent,
        root,
        totalPossibleSteps: 0,
        pathFromRoot: [...pathFromRoot, definition.step]
    };
    if (definition.children) {
        for (const child of definition.children) {
            route.children[child.route.step] = {
                route: transformDefinitionToRoute(
                    child.route,
                    steps,
                    route,
                    root ? root : route,
                    [...pathFromRoot, definition.step]
                ),
                condition: child.condition
            };
            // route.children.push();
        }
    } else if (definition.child) {
        route.children[definition.child.step] = {
            route: transformDefinitionToRoute(
                definition.child,
                steps,
                route,
                root ? root : route,
                [...pathFromRoot, definition.step]
            ),
            condition: defaultCondition
        };
    }
    if (Object.values(route.children).length > 0) {
        route.totalPossibleSteps =
            1 +
            (Object.values(route.children) as StepRouteChild[]).reduce(
                (original, child) =>
                    original < child.route.totalPossibleSteps
                        ? child.route.totalPossibleSteps
                        : original,
                0
            );
    } else {
        route.totalPossibleSteps = 1;
    }
    return route;
}

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
    NgbDateParserFormatter,
    NgbDatepicker,
    NgbDatepickerI18n,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import {
    getMomentFromDateStruct,
    getNgbDateStructFromMoment,
} from '../../../helpers';
import { NgbDateOnlyMonthService } from '../../ngbdate-only-month-parser.service';
import { NgbCustomDateParserService } from '../../ngb-custom-date-parser.service';

@Component({
    selector: 'besc-month-year-picker',
    templateUrl: './month-year-picker.component.html',
    styleUrls: ['./month-year-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MonthYearPickerComponent),
            multi: true,
        },
        {
            provide: NgbDateParserFormatter,
            useFactory: (comp: MonthYearPickerComponent) => comp.formatMMYYYY ? new NgbDateOnlyMonthService() : new NgbCustomDateParserService(),
            deps: [MonthYearPickerComponent]
        }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MonthYearPickerComponent
    implements
        OnInit,
        OnDestroy,
        OnChanges,
        ControlValueAccessor,
        AfterViewInit
{
    private _onDestroy$ = new Subject();
    private _onChange: (value: moment.Moment) => void;
    private _onTouched: () => void;

    dateForm: UntypedFormGroup = new UntypedFormGroup({
        date: new UntypedFormControl(null, { updateOn: 'blur' }),
    });

    @ViewChild('dp', { static: true, read: NgbDatepicker })
    datepicker: NgbDatepicker;

    @Input() placeholder: string;
    @Input() required = false;
    @Input() max: moment.Moment;
    @Input() min: moment.Moment;
    @Input() label: string;
    @Input() readonly: boolean;
    @Input() formatMMYYYY: boolean;
    @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

    maxDate: NgbDateStruct = null;
    minDate: NgbDateStruct = null;
    currentYear = moment().year();

    constructor(public i18n: NgbDatepickerI18n) {}

    ngOnInit() {
        this.dateForm
            .get('date')
            .valueChanges.pipe(
                distinctUntilChanged(
                    (prev, curr) => !!prev && !!curr && curr.isSame(prev)
                ),
                takeUntil(this._onDestroy$)
            )
            .subscribe((date) => {
                Promise.resolve().then(() => {
                    this._onChange(date);
                    this._onTouched();
                    if (this.onChange) {
                        this.onChange.emit();
                    }
                });
            });
    }

    ngAfterViewInit() {
        if (this.datepicker.model.selectedDate) {
            Promise.resolve().then(() => {
                const firstOfTheYear = getMomentFromDateStruct(this.datepicker.model.selectedDate).startOf('year');
                this.datepicker.navigateTo(getNgbDateStructFromMoment(firstOfTheYear));
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            (changes.min && !changes.min.isFirstChange()) ||
            (changes.max && !changes.max.isFirstChange())
        ) {
            const dateControl = this.dateForm.get('date');
            const value = dateControl.value;
            if (
                changes.min &&
                dateControl.value &&
                value.isBefore(changes.min.currentValue)
            ) {
                dateControl.setValue(changes.min.currentValue, {
                    emitEvent: false,
                });
                Promise.resolve().then(() => {
                    this._onChange(changes.min.currentValue);
                    this._onTouched();
                });
            } else if (
                changes.max &&
                dateControl.value &&
                value.isAfter(changes.max.currentValue)
            ) {
                dateControl.setValue(changes.max.currentValue?.clone(), {
                    emitEvent: false,
                });
                Promise.resolve().then(() => {
                    this._onChange(changes.max.currentValue);
                    this._onTouched();
                });
            }
        }
    }

    ngOnDestroy() {
        this._onDestroy$.next();
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.dateForm.disable();
        } else {
            this.dateForm.enable();
        }
    }

    writeValue(date: moment.Moment): void {
        Promise.resolve().then(() => {
            this.dateForm.get('date').setValue(date?.clone() || null, {
                emitEvent: false,
            });
            this.dateForm.markAsPristine();
            if (moment.isMoment(date)) {
                const firstOfTheYear = date.clone().startOf('year');
                this.datepicker.navigateTo(
                    getNgbDateStructFromMoment(firstOfTheYear)
                );
            }
        });
    }

    selectMonth(month: NgbDateStruct) {
        this.dateForm.get('date').setValue(getMomentFromDateStruct(month));
    }

    navigateCalendar(value: number) {
        const { state, calendar } = this.datepicker;
        this.datepicker.navigateTo(
            calendar.getNext(state.firstDate, 'y', value)
        );
    }

    goToToday() {
        const { calendar } = this.datepicker;
        const firstOfTheYear = getMomentFromDateStruct(
            calendar.getToday()
        ).startOf('year');
        this.datepicker.navigateTo(getNgbDateStructFromMoment(firstOfTheYear));
    }

    disabled(month): boolean {
        const momentMonth = getMomentFromDateStruct(month);
        if (this.min && this.max) {
            return !momentMonth.isBetween(this.min, this.max, 'month', '[]');
        }
        if (this.min) {
            return momentMonth.isBefore(this.max, 'month');
        }
        if (this.max) {
            return momentMonth.isAfter(this.max, 'month');
        }
        return false;
    }
}

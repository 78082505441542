import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Router,
} from '@angular/router';
import { RouteParserService } from '@benefit-sculptor/router';
import { Navigation, NavigationItem } from './navigation.service';
import { EMPTY, isObservable, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NavigationParserService {
    constructor(private _routeParser: RouteParserService) {}

    getNavigationObservableFromRoute(
        route: ActivatedRoute
    ): Observable<Navigation> {
        const data = this._routeParser.getAllRouteData(route);
        if (data.navigation) {
            const navigation = !isObservable(data.navigation)
                ? of(data.navigation)
                : data.navigation;

            return navigation.pipe(
                map((nav: Navigation) => {
                    if (nav.parent) {
                        nav.parent = {
                            ...nav.parent,
                            link: this.transformLinkWithParams(
                                nav.parent.link,
                                route.snapshot
                            ),
                        };
                    }

                    return {
                        ...nav,
                        items: this.transformNavItemsWithParams(
                            nav.items,
                            route.snapshot
                        ),
                    };
                })
            );
        }

        return EMPTY;
    }

    getHeaderNavigationObservableFromRouter(
        route: ActivatedRoute
    ): Observable<Navigation> {
        const data = this._routeParser.getAllRouteData(route);
        if (data.headerNavigation) {
            const headerNavigation = !isObservable(data.headerNavigation)
                ? of(data.headerNavigation)
                : data.headerNavigation;

            return headerNavigation.pipe(
                map((nav: Navigation) => ({
                    ...nav,
                    items: this.transformNavItemsWithParams(
                        nav.items,
                        route.snapshot
                    ),
                }))
            );
        }

        return of(null);
    }

    transformNavItemsWithParams(
        items: NavigationItem[],
        route: ActivatedRouteSnapshot
    ): NavigationItem[] {
        return items.map((item) => ({
            ...item,
            link: this.transformLinkWithParams(item.link, route),
        }));
    }

    transformLinkWithParams(
        commands: any[],
        route: ActivatedRouteSnapshot
    ): any[] {
        const params = this._routeParser.getParamsFromRoute(route);
        const finalCommands: any[] = [];

        for (let command of commands) {
            if (command.includes(':')) {
                const commandParams = command.matchAll(/\:(.[a-zA-Z\d]+)/g);
                for (const commandParam of commandParams) {
                    const paramKey = commandParam[1];
                    if (params.hasOwnProperty(paramKey)) {
                        command = command.replace(
                            ':' + paramKey,
                            params[paramKey]
                        );
                    } else {
                        command = command
                            .replace(':' + paramKey, '')
                            .replace('//', '/');

                        if (command === '') {
                            command = null;
                        }
                    }
                }
            }

            finalCommands.push(command);
        }

        return finalCommands.filter((command) => command !== null);
    }
}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthFacadeService } from './auth-facade.service';
import { RouteParserService } from '@benefit-sculptor/router';
import { map, take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class IsAuthenticatedGuard
    implements CanActivate, CanActivateChild, CanLoad
{
    constructor(
        private _auth: AuthFacadeService,
        private _router: Router,
        private _routeParser: RouteParserService,
        private _toastr: ToastrService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._isAuthenticated().pipe(this._checkAuthentication(next));
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._isAuthenticated().pipe(this._checkAuthentication(next));
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this._isAuthenticated();
    }

    private _isAuthenticated(): Observable<boolean> {
        return this._auth.isAuthorized$.pipe(take(1));
    }

    private _checkAuthentication(next: ActivatedRouteSnapshot) {
        const data = this._routeParser.getAllRouteData(next);
        return (obs: Observable<boolean>): Observable<boolean | UrlTree> =>
            obs.pipe(
                map((isAuthenticated) => {
                    if (isAuthenticated) {
                        return true;
                    }

                    return this._router.createUrlTree(data.redirectIfNoAuth);
                })
            );
    }
}

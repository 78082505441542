import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
    catchError,
    finalize,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Employer, KeyContact } from '@besc/employer';
import { NewEmployerRequest } from '../models/NewEmployer.model';

@Injectable({
    providedIn: 'root',
})
export class EmployerService {
    protected _getEmployers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    protected _loading = new BehaviorSubject<boolean>(false);

    all$: Observable<Employer[]> = this._getEmployers.pipe(
        tap(() => this._loading.next(true)),
        switchMap(() =>
            this._http
                .get<Employer[]>('employers')
                .pipe(finalize(() => this._loading.next(false)))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    loading$ = this._loading.asObservable();

    constructor(
        protected _http: HttpClient,
        protected _toastr: ToastrService
    ) {}

    getEmployers() {
        this._getEmployers.next(true);
    }

    addNew(newEmployer: Employer & { user: string }): Observable<Employer> {
        const body: NewEmployerRequest = {
            name: newEmployer.companyName,
            zipcode: newEmployer.contactInfo.zipCode,
            users: [newEmployer.user],
            state: newEmployer.contactInfo.state,
            city: newEmployer.contactInfo.city
        };
        return this._http
            .post<Employer>('/admin/employerGroup', body)
            .pipe(tap(() => this.getEmployers()));
    }

    get(id: string): Observable<Employer> {
        return this._http
            .get<Employer>('employers/' + id)
            .pipe(catchError(() => of(null)));
    }

    update(id: string, smallEmployer: Partial<Employer>): Observable<Employer> {
        return this._http.patch<Employer>('employers/' + id, smallEmployer);
    }

    delete(id: any): Observable<any> {
        return this._http
            .delete('employers/' + id)
            .pipe(tap(() => this.getEmployers()));
    }

    updateKeyContacts(smallEmployer: string, keyContacts: KeyContact[]) {
        return this._http.put(
            'employers/' + smallEmployer + '/key-contacts/',
            keyContacts
        );
    }
}

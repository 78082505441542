<div
    class="d-flex align-items-stretch justify-content-between container-fluid"
    id="kt_header"
>
    <ngb-progressbar
        class="header-loading-bar"
        *ngIf="(progress$ | async) > 0"
        [value]="progress$ | async"
        type="primary"
        height="3px"
    ></ngb-progressbar>
    <div
        class="d-flex align-items-stretch justify-content-between container-fluid px-0"
    >
        <div class="d-flex align-items-center me-8">
            <a routerLink="/" class="d-flex align-items-center">
                <img
                    alt="Logo"
                    src="./assets/images/benefit-sculptor-logo-small.png"
                    class="h-45px logo"
                />
            </a>
        </div>
        <besc-header-navigation
            class="d-flex align-items-center"
        ></besc-header-navigation>
        <div class="d-flex align-items-center">
            <div class="d-flex align-items-center" role="button" (click)="helpAndSupport()">
                <img
                    alt="HelpAndSupport"
                    src="./assets/icons/help.png"
                />
                <span class="help">
                    Help
                </span>
            </div>
            <besc-watch-demos-icon
                class="d-flex align-items-stretch flex-shrink-0 video-icon"
            ></besc-watch-demos-icon>
            <besc-settings-menu
                class="d-flex align-items-stretch flex-shrink-0"
            ></besc-settings-menu>
            <besc-user-profile-menu
                class="d-flex align-items-stretch flex-shrink-0"
            ></besc-user-profile-menu>
        </div>
    </div>
</div>

import { Component } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthFacadeService, RecaptchaService } from '@benefit-sculptor/auth';
import { BescValidators } from '@benefit-sculptor/core';

@Component({
    selector: 'besc-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
    resetForm: UntypedFormGroup = this._fb.group({
        email: [
            null,
            {
                validators: Validators.compose([
                    BescValidators.email,
                    Validators.required,
                ]),
            },
        ],
    });

    loading$: Observable<boolean> = this._auth.loading$;
    expired$ = this._route.queryParamMap.pipe(
        map((params) => Boolean(params.get('expired')))
    );

    constructor(
        private _fb: UntypedFormBuilder,
        private _auth: AuthFacadeService,
        private _route: ActivatedRoute,
        private _recaptcha: RecaptchaService,
    ) {}

    async sendForgotPassword() {
        if (this.resetForm.valid) {
            const token = await this._recaptcha.getRecaptcha('resetPassword');
            this._auth.forgotPassword(this.resetForm.value.email, token);
        }
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.resetForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result =
            control.hasError(validationType) &&
            (control.dirty || control.touched);
        return result;
    }
}

import {
    AbstractControl,
    NG_VALIDATORS,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { Directive } from '@angular/core';

const ONE_LOWER = /(?=.*[a-z])/; // At least one lowercase character
const ONE_UPPER = /(?=.*[A-Z])/; // At least one uppercase character

@Directive({
    selector: '[bescPasswordComplexity]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PasswordComplexityValidator,
            multi: true,
        },
    ],
})
export class PasswordComplexityValidator implements Validator {
    validate(emailControl: AbstractControl): ValidationErrors | null {
        const password = emailControl.value;
        if (!password || password === '') {
            return null;
        }
        if (password.length < 8) {
            return {
                complexity: 'Password is too short',
            };
        } else if (!ONE_LOWER.test(password)) {
            return {
                complexity: 'Password needs at least one lowercase',
            };
        } else if (!ONE_UPPER.test(password)) {
            return {
                complexity: 'Password needs at least one uppercase',
            };
        }
        return null;
    }
}

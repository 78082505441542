import {
    Component,
    Input,
} from '@angular/core';
import {
    UntypedFormGroup,
} from '@angular/forms';

@Component({
    selector: 'besc-composite-rates',
    templateUrl: './composite-rates.component.html',
    styleUrls: ['./composite-rates.component.scss'],
})
export class CompositeRatesComponent {

    @Input() ratesForm: UntypedFormGroup;
}

import { Component } from '@angular/core';

@Component({
    selector: 'besc-check-icon',
    standalone: true,
    template: `<svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        aria-hidden="true"
    >
        <path
            d="M4.41643 10.5528L4.24028 10.3767L4.0635 10.5522L2.76184 11.8447L2.58381 12.0215L2.76121 12.1989L7.88538 17.323L8.06215 17.4998L8.23893 17.323L19.2389 6.32304L19.4157 6.14626L19.2389 5.96948L17.9464 4.67698L17.7697 4.50029L17.593 4.6769L8.06224 14.1986L4.41643 10.5528Z"
            fill="currentColor"
            stroke="currentColor"
            stroke-width="0.5"
        />
    </svg>`,
})
export class CheckIconComponent {}

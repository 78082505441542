import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { DataPersistence } from '@nrwl/angular';

import * as fromEmployees from './employees.reducer';
import { EMPLOYEES_FEATURE_KEY } from './employees.reducer';
import * as EmployeesActions from './employees.actions';
import { map, tap } from 'rxjs/operators';
import { EmployeeService } from '../services/employee.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { EmployerService } from '@besc/employer';
import { EmployeesFacade } from '@besc/employee';

@Injectable()
export class EmployeesEffects {
    loadEmployees$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.loadEmployees, {
            run: (
                action: ReturnType<typeof EmployeesActions.loadEmployees>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                // Your custom service 'load' logic goes here. For now just return a success action...
                return this._employee
                    .getEmployees(action.employerId, action.effectiveDate)
                    .pipe(
                        map((employees) =>
                            EmployeesActions.loadEmployeesSuccess({
                                employees: employees.map((employee) => {
                                    employee.fullName =
                                        employee.firstName +
                                        ' ' +
                                        employee.lastName;
                                    employee.reversedName =
                                        employee.lastName +
                                        ', ' +
                                        employee.firstName;
                                    return employee;
                                }),
                                effectiveDate: moment(action.effectiveDate),
                            })
                        )
                    );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.loadEmployees>,
                error
            ) => {
                console.error('Error', error);
                return EmployeesActions.loadEmployeesFailure({ error });
            },
        })
    );

    loadEmployeePlans$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.loadEmployeePlans, {
            run: (
                action: ReturnType<typeof EmployeesActions.loadEmployeePlans>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                // Your custom service 'load' logic goes here. For now just return a success action...
                return this._employee
                    .getEmployeePlans(
                        action.employerId,
                        action.effectiveDate?.format(moment.HTML5_FMT.DATE)
                    )
                    .pipe(
                        map((plans) =>
                            EmployeesActions.loadEmployeePlansSuccess({ plans })
                        )
                    );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.loadEmployeePlans>,
                error
            ) => {
                console.error('Error', error);
                return EmployeesActions.loadEmployeePlansFailure({ error });
            },
        })
    );

    loadCurrentEmployeePlan$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.loadCurrentEmployeePlan, {
            run: (
                action: ReturnType<
                    typeof EmployeesActions.loadCurrentEmployeePlan
                >,
                state: fromEmployees.EmployeesPartialState
            ) => {
                // Your custom service 'load' logic goes here. For now just return a success action...
                if (!state[EMPLOYEES_FEATURE_KEY].currentEmployee.id) {
                    return;
                }
                return this._employee
                    .getCurrentPlan(
                        state[EMPLOYEES_FEATURE_KEY].currentEmployee.id,
                        action.effectiveDate
                    )
                    .pipe(
                        map((currentPlan) =>
                            EmployeesActions.loadCurrentEmployeePlanSuccess({
                                currentPlan,
                            })
                        )
                    );
            },

            onError: (
                action: ReturnType<
                    typeof EmployeesActions.loadCurrentEmployeePlan
                >,
                error
            ) => {
                console.error('Error', error);
                return EmployeesActions.loadCurrentEmployeePlanFailure({
                    error,
                });
            },
        })
    );

    updateEmployee$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.updateEmployee, {
            run: (
                action: ReturnType<typeof EmployeesActions.updateEmployee>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                // Your custom service 'load' logic goes here. For now just return a success action...
                return this._employee.update({ ...action.employee, employerId: state['employees']?.employerId }).pipe(
                    tap((employee) => {
                        this._toastr.success(
                        employee.firstName +
                            ' ' +
                            employee.lastName +
                            ' has been successfully updated'
                        );
                    }),
                    tap(() => {
                        if (action.employee.employmentStatus.terminationDate
                            !== state['employees'].entities[state['employees'].currentEmployee.id].employmentStatus.terminationDate
                        ) {
                            this._employeesFacade.loadEmployeePlans(state['employees']?.employerId, state['employees'].effectiveDate)

                        }
                    }),
                    map((employee) => EmployeesActions.updateEmployeeSuccess({ employee }))
                );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.updateEmployee>,
                error
            ) => {
                console.error('Error', error);
                this._toastr.error(
                    action.employee.firstName +
                        ' ' +
                        action.employee.lastName +
                        ' failed to update'
                );
                return EmployeesActions.updateEmployeeFailure({
                    error,
                });
            },
        })
    );

    addNewEmployee$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.addNewEmployee, {
            run: (
                action: ReturnType<typeof EmployeesActions.addNewEmployee>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                if (!state[EMPLOYEES_FEATURE_KEY].employerId) {
                    return;
                }
                return this._employee
                    .addNew(
                        state[EMPLOYEES_FEATURE_KEY].employerId,
                        action.employee
                    )
                    .pipe(
                        map((employee) => {
                            this._employerService.getEmployers();
                            this._toastr.success(
                                employee.firstName +
                                    ' ' +
                                    employee.lastName +
                                    ' has been successfully created'
                            );
                            return EmployeesActions.addNewEmployeeSuccess({
                                employee,
                            });
                        })
                    );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.addNewEmployee>,
                error
            ) => {
                this._toastr.error(
                    action.employee.firstName +
                        ' ' +
                        action.employee.lastName +
                        ' failed to be created'
                );
                return EmployeesActions.addNewEmployeeFailure({
                    error,
                });
            },
        })
    );

    deleteEmployee$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.deleteEmployee, {
            run: (
                action: ReturnType<typeof EmployeesActions.deleteEmployee>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                const currentEmployee =
                    state[EMPLOYEES_FEATURE_KEY].entities[action.employeeId];
                return this._employee.delete(action.employeeId).pipe(
                    map(() => {
                        this._toastr.success(
                            `${currentEmployee.firstName} ${currentEmployee.lastName} has been successfully deleted`,
                            '', 
                            {
                                positionClass: 'toast-center',
                            }
                        );
                        this._employerService.getEmployers();
                        return EmployeesActions.deleteEmployeeSuccess({
                            employeeId: action.employeeId,
                        });
                    })
                );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.deleteEmployee>,
                error
            ) => {
                this._toastr.error('Failed to delete the employee');
                return EmployeesActions.deleteEmployeeFailure({
                    error,
                    employeeId: action.employeeId,
                });
            },
        })
    );

    deleteEmployeeElections$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.deleteEmployeeElections, {
            run: (
                action: ReturnType<typeof EmployeesActions.deleteEmployeeElections>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                const plan = state[EMPLOYEES_FEATURE_KEY].employeePlans[action.employeeId];
                const currentEmployee =
                    state[EMPLOYEES_FEATURE_KEY].entities[action.employeeId];
                return this._employee.deleteEmployeeElections(
                    plan.electionId
                ).pipe(
                    map(() => {
                        this._toastr.success(
                            `Plan assignment deleted for ${currentEmployee.firstName} ${currentEmployee.lastName}`,
                            '',
                            {
                                positionClass: 'toast-center',
                            }
                        );
                        return EmployeesActions.deleteEmployeeElectionsSuccess({
                            employeeId: action.employeeId,
                            employerId: action.employerId,
                            effectiveDate: action.effectiveDate
                        });
                    })
                );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.deleteEmployeeElections>,
                error
            ) => {
                this._toastr.error('Failed to delete employee elections');
                return EmployeesActions.deleteEmployeeElectionsFailure({
                    error
                });
            },
        })
    );

    deleteEmployeeElectionsSuccess$ = createEffect(() => 
        this._dataPersistence.fetch(EmployeesActions.deleteEmployeeElectionsSuccess, {
            run: (action: ReturnType<typeof EmployeesActions.deleteEmployeeElectionsSuccess>) => {
                return EmployeesActions.loadEmployeePlans({ employerId: action.employerId, effectiveDate: action.effectiveDate});
            }
        })
    );

    saveDependents$ = createEffect(() =>
        this._dataPersistence.fetch(EmployeesActions.saveDependents, {
            run: (
                action: ReturnType<typeof EmployeesActions.saveDependents>,
                state: fromEmployees.EmployeesPartialState
            ) => {
                return this._employee
                    .updateDependents(action.dependents, action.employeeId)
                    .pipe(
                        map((dependents) => {
                            this._toastr.success(
                                'Successfully saved dependents'
                            );
                            return EmployeesActions.saveDependentsSuccess({
                                employeeId: action.employeeId,
                                dependents,
                            });
                        })
                    );
            },

            onError: (
                action: ReturnType<typeof EmployeesActions.saveDependents>,
                error
            ) => {
                this._toastr.error('Failed to save dependents');
                return EmployeesActions.saveDependentsFailure({
                    error,
                    employeeId: action.employeeId,
                });
            },
        })
    );

    constructor(
        private _actions$: Actions,
        private _dataPersistence: DataPersistence<
            fromEmployees.EmployeesPartialState
        >,
        private _employee: EmployeeService,
        private _employeesFacade: EmployeesFacade,
        private _employerService: EmployerService,
        private _toastr: ToastrService
    ) {}
}

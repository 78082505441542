import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgencyInfoComponent } from './components/agency-info/agency-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormHelpersModule } from '@benefit-sculptor/core';
import { AddNewAgencyEmployeeDialogComponent } from './components/agency-info/add-new-agency-employee-dialog/add-new-agency-employee-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { DeleteAgencyUserDialogComponent } from './components/agency-info/delete-agency-user-dialog/delete-agency-user-dialog.component';
import { AgencyStatusPillComponent } from './components/agency-status-pill/agency-status-pill.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TransferOwnershipDialogComponent } from './components/agency-info/transfer-ownership-dialog/transfer-ownership-dialog.component';
import { AuthModule } from '../../../../apps/benefit-sculptor/src/app/auth/auth.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FontAwesomeModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        NgbDropdownModule,
        FormHelpersModule,
        AuthModule,
    ],
    declarations: [
        AgencyInfoComponent,
        AddNewAgencyEmployeeDialogComponent,
        DeleteAgencyUserDialogComponent,
        AgencyStatusPillComponent,
        TransferOwnershipDialogComponent,
    ],
    exports: [AgencyInfoComponent, AgencyStatusPillComponent],
})
export class AgencyModule {}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    vimeoUserId: '145399131',
    vimeoClientId: '16c3ae1e5de19ba21bff54e1152b0d77fbb61598',
    vimeoClientSecret: 'MRilWNJR4yGfdTrCgkNk2Ec5IoaBrv74mK7zYh+UoL2QdQ8dmRzpIp2x4ckkq51JSAY3zfyknAbRXMXo3QEhTCX6Wxg9jukbLLnddQkDqk9O/FqhAUmYXhGrkhqmg2fh',
    zipCodeAPIKey: 'js-n1LxePbIV6m6TNAhHC4n8P1CM9HToWbQNCQo5UpPGyHu1j65SocRrou6t8cxgPcy',
    stripePK: 'pk_test_bT0lOLcwy75JLQ62HB9enQ8g00PBVzkgM8',
    siteKey: '6LfklcQZAAAAAPSzOT7_xp44GQz9TlsihIAkRMF7'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<ng-container [formGroup]="ratesForm">
    <ng-container formGroupName="rates">
        <div class="btn-group" role="group">
            <input
                type="radio"
                class="btn-check"
                id="rateCompositeRates"
                formControlName="type"
                value="composite"
                autocomplete="off"
            />
            <label
                class="btn btn-outline btn-outline-primary"
                for="rateCompositeRates"
                >Composite Rates</label
            >

            <input
                type="radio"
                class="btn-check"
                id="rateAgeRates"
                formControlName="type"
                value="age_rate"
                autocomplete="off"
            />
            <label
                class="btn btn-outline btn-outline-primary"
                for="rateAgeRates"
                ><besc-loading-indicator [loading]="ageCurveLoading$ | async">
                    Age Rates
                </besc-loading-indicator></label
            >
        </div>
    </ng-container>
    <hr />
    <ng-container *ngIf="minMaxDates$ | async as minMaxDates">
        <div class="row">
            <div class="col-md mb-4 m-lg-0">
                <label class="form-label">Effective Date</label>
                <besc-month-year-picker
                    class="quote-date"
                    formControlName="effectiveDate"
                    placeholder="Quote Effective Date"
                    required
                    [max]="minMaxDates.effective.max"
                    [min]="minMaxDates.effective.min"
                    [readonly]="true"
                    #effectiveDateControl
                ></besc-month-year-picker>
            </div>
            <div class="col-md mb-4 m-lg-0">
                <label class="form-label">ExpirationDate</label>
                <besc-month-year-picker
                    class="quote-date"
                    formControlName="expirationDate"
                    placeholder="Quote Expiration Date"
                    required
                    [min]="minMaxDates.expiration.min"
                    [effectiveDateControl]="effectiveDateControl"
                    [readonly]="true"
                ></besc-month-year-picker>
            </div>
        </div>
        <hr />
    </ng-container>
    <div
        class="composite-rates"
        [ngClass]="{
            'd-none': ratesForm.value.rates.type !== 'composite'
        }"
    >
        <h4>Composite Rates</h4>
        <ng-container class="" formGroupName="rates">
            <div class="composite-form" formGroupName="composite">
                <div class="row">
                    <div class="col-md mb-4">
                        <label for="single" class="form-label required"
                            >Single</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="single"
                                class="form-control"
                                placeholder="Single"
                                type="number"
                                formControlName="single"
                                name="single"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                    <div class="col-md mb-4">
                        <label
                            for="singleAndChildren"
                            class="form-label required"
                            >Single and Children</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="singleAndChildren"
                                class="form-control"
                                placeholder="Single and Children"
                                type="number"
                                formControlName="singleAndChildren"
                                name="singleAndChildren"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md mb-4">
                        <label for="singleAndSpouse" class="form-label required"
                            >Single and Spouse</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="singleAndSpouse"
                                class="form-control"
                                placeholder="Single and Spouse"
                                type="number"
                                formControlName="singleAndSpouse"
                                name="singleAndSpouse"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                    <div class="col-md mb-4">
                        <label for="family" class="form-label required"
                            >Family</label
                        >
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <input
                                id="family"
                                class="form-control"
                                placeholder="Family"
                                type="number"
                                formControlName="family"
                                name="family"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div
        class="age-rates-wrapper"
        [ngClass]="{
            'd-none': ratesForm.value.rates.type !== 'age_rate'
        }"
    >
        <div class="calculate-rates" *ngIf="showRateCalculator$ | async">
            <h4>Rate Calculator</h4>
            <p>
                Based on the given zip code, we have retrieved the age rate
                curve associated with this geographic area. If you would like to
                auto generate the rates for this plan, please enter the age 21
                rate below and click on the Calculate Rates button.
            </p>
            <div class="row d-flex align-items-center">
                <div class="col-md-3 mb-4 mb-lg-0">
                    <label for="age21Rate" class="form-label required"
                        >Age 21 Rate</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="age21Rate"
                            class="form-control"
                            placeholder="Single and Spouse"
                            type="number"
                            formControlName="age21Rate"
                            name="age21Rate"
                            bescInputValidation
                            bescNumericInput
                            [decimals]="true"
                        />
                    </div>
                </div>
                <div class="col-md-3 mb-4 mb-lg-0">
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            formControlName="hasTobaccoRates"
                            type="checkbox"
                            role="switch"
                            id="hasTobaccoRates"
                        />
                        <label class="form-check-label" for="hasTobaccoRates">
                            Has Tobacco Rates?</label
                        >
                    </div>
                </div>
                <div
                    class="col-md-3 mb-4 mb-lg-0"
                    *ngIf="ratesForm.controls.hasTobaccoRates.value"
                >
                    <label for="age21TobaccoRate" class="form-label required"
                        >Age 21 Tobacco Rate</label
                    >
                    <div class="input-group">
                        <div class="input-group-text">$</div>
                        <input
                            id="age21TobaccoRate"
                            class="form-control"
                            placeholder="Single and Spouse"
                            type="number"
                            formControlName="age21TobaccoRate"
                            name="age21TobaccoRate"
                            bescInputValidation
                            bescNumericInput
                            [decimals]="true"
                        />
                    </div>
                </div>
                <div class="col-md-3">
                    <button
                        class="btn btn-primary ms-3"
                        (click)="calculateRates()"
                    >
                        Calculate Rates
                    </button>
                </div>
            </div>

            <button class="btn btn-info mt-4" (click)="toggleRatesTable()">
                Show Rates Table
            </button>

            <hr />
        </div>

        <div class="calculate-rates" *ngIf="!(showRateCalculator$ | async)">
            <p>
                Based on the given zip code, we could not retrieve the age rate
                curve associated with this geographic area. If you would like to
                auto generate the rates for this plan, please go to the
                <strong>Plan Setup</strong> and change the zip code
            </p>
            <mat-slide-toggle
                class="mt-2 me-3"
                [color]="'primary'"
                formControlName="hasTobaccoRates"
            >
                Has Tobacco Rates?
            </mat-slide-toggle>
            <hr />
        </div>

        <div
            class="rates"
            [ngbCollapse]="hideRates$ | async"
            formGroupName="rates"
        >
            <h4>Rates</h4>
            <p
                *ngIf="
                    !ratesForm.pristine &&
                    ratesForm.get('rates.age').errors &&
                    ratesForm.get('rates.age').errors.allRatesMustHaveValue
                "
                class="text-danger"
            >
                All Rates Must Have a Value Greater than $0.00
            </p>
            <hr />
            <div class="age-rates" formGroupName="age">
                <div class="age-rate-input" *ngFor="let age of ages">
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">$</div>
                        <div class="form-floating">
                            <input
                                [id]="'age' + age"
                                class="form-control"
                                placeholder="Single and Spouse"
                                type="number"
                                [formControlName]="'age' + age"
                                [name]="'age' + age"
                                bescInputValidation
                                bescNumericInput
                                [decimals]="true"
                            />
                            <label [for]="'age' + age" class="required"
                                >Age {{ age }}</label
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="tobacco-rates"
                [ngClass]="{
                    'd-none': !ratesForm.controls.hasTobaccoRates.value
                }"
            >
                <hr />
                <h4>Tobacco Rates</h4>
                <p
                    *ngIf="
                        !ratesForm.pristine &&
                        ratesForm.get('rates.tobacco').errors &&
                        ratesForm.get('rates.tobacco').errors
                            .allRatesMustHaveValue
                    "
                    class="text-danger"
                >
                    All Tobacco Rates Must Have a Value Greater than $0.00
                </p>
                <hr />
                <div class="age-rates" formGroupName="tobacco">
                    <div class="age-rate-input" *ngFor="let age of ages">
                        <div class="input-group">
                            <div class="input-group-text">$</div>
                            <div class="form-floating">
                                <input
                                    [id]="'age' + age"
                                    class="form-control"
                                    placeholder="Single and Spouse"
                                    type="number"
                                    [formControlName]="'age' + age"
                                    [name]="'age' + age"
                                    bescInputValidation
                                    bescNumericInput
                                    [decimals]="true"
                                />
                                <label [for]="'age' + age" class="required"
                                    >Age {{ age }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<besc-next-prev-buttons
    [form]="ratesForm"
    *ngIf="!hideNavigation"
></besc-next-prev-buttons>

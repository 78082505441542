import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { User, UserService } from '@benefit-sculptor/auth';
import { onboardingCountIdentifier } from '@besc/onboarding';

@Component({
    selector: 'besc-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeComponent implements OnInit {
    @Output() watch = new EventEmitter<void>();
    @Output() closeModal = new EventEmitter<void>();

    user$$ = new BehaviorSubject<User | undefined>(undefined);

    constructor(
        private _user: UserService,
    ) { }

    ngOnInit(): void {
        this.getUser();
    }

    getUser(): void {
        this._user.currentUser$.pipe(
            tap((user) => this.user$$.next(user)),
            tap((user) => user ? this.incrementModalPresentedCount(user) : null),
            take(1)
        ).subscribe();
    }

    incrementModalPresentedCount(user: User): void {
        const currentCount = localStorage.getItem(onboardingCountIdentifier(user)) ?? '0';
        localStorage.setItem(onboardingCountIdentifier(user), (Number(currentCount) + 1).toString());
    }

    emitClose(): void {
        this.closeModal.emit();
    }

    watchDemos(): void {
        if (this.user$$.value) {
            localStorage.setItem(onboardingCountIdentifier(this.user$$.value), '3');
            this.watch.emit();
        }
    }
}

import {
    AfterViewInit,
    Component,
    Input,
    OnDestroy,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { CreatePlansFacade } from '../../+state/create-plans.facade';
import * as dotProp from 'dot-prop';
import {
    distinctUntilChanged,
    first,
    map,
    takeUntil,
    startWith,
    tap,
} from 'rxjs/operators';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { PlanRates, RateType } from '../../../interfaces';
import {
    normalizeEffectiveDate,
    normalizeTerminationDate,
} from '@benefit-sculptor/core';

@Component({
    selector: 'besc-rates',
    templateUrl: './rates.component.html',
    styleUrls: ['./rates.component.scss'],
})
export class RatesComponent implements OnInit, OnDestroy, AfterViewInit {
    private _onDestroy$ = new Subject();
    private _hideRates$ = new BehaviorSubject(true);

    @Input() hideNavigation = false;
    @Input() rates: PlanRates;
    @Input() effectiveDate: string;
    @Input() expirationDate: string;
    @Output() ratesFormInit = new EventEmitter<UntypedFormGroup>();

     defaultValues = {
        "rates": {
            "type": "composite",
            "age": {},
            "tobacco": {},
            "composite": {
                "single": null,
                "singleAndChildren": null,
                "singleAndSpouse": null,
                "family": null
            }
        }
    }
    ratesForm: UntypedFormGroup = this._fb.group({
        effectiveDate: [
            normalizeEffectiveDate(moment().startOf('year')),
            Validators.required,
        ],
        expirationDate: [
            normalizeTerminationDate(moment().endOf('year')),
            Validators.required,
        ],
        rates: this._fb.group({
            composite: this._fb.group({
                single: [null],
                singleAndChildren: [null],
                singleAndSpouse: [null],
                family: [null],
            }),
            age: this._fb.group(
                {},
                {
                    validators: [this.ageRatesValidator(false)],
                }
            ),
            tobacco: this._fb.group(
                {},
                {
                    validators: [this.ageRatesValidator(true)],
                }
            ),
            type: ['composite'],
        }),
        age21Rate: [0],
        hasTobaccoRates: [false],
        age21TobaccoRate: [0],
        id: [null]
    });
    numberOfAges = 66;
    ages = Array.from(Array(this.numberOfAges).keys());
    hideRates = true;
    ageCurve$ = this._createPlans.ageRateCurve$;
    ageCurveLoading$ = this._createPlans.ageRateCurveLoading$;

    showRateCalculator$ = this.ageCurve$.pipe(map((ageCurve) => !!ageCurve));
    hideRates$ = combineLatest([
        this.ageCurve$,
        this._hideRates$,
        this.ageCurveLoading$,
    ]).pipe(
        map(([ageCurve, hideRates, ageCurveLoading]) => {
            const hasAgeCurve = !!ageCurve;
            if (hasAgeCurve) {
                return hideRates;
            } else if (ageCurveLoading) {
                return hideRates;
            }

            return false;
        })
    );

    minMaxDates$ = this.ratesForm.valueChanges
        .pipe(
            startWith(this.ratesForm.getRawValue()),
            map((rateForm) => {
                const effectiveDate = moment(
                    this.effectiveDate,
                    moment.HTML5_FMT.DATE
                );
                const expirationDate = moment(
                    this.expirationDate,
                    moment.HTML5_FMT.DATE
                );
                return {
                    effective: {
                        min: effectiveDate,
                        max: expirationDate,
                    },
                    expiration: {
                        min: rateForm.effectiveDate
                            ? moment(rateForm.effectiveDate).clone().add(1, 'months')
                            : moment(effectiveDate).clone().add(1, 'months'),
                    },
                };
            })
        );

    constructor(
        private _fb: UntypedFormBuilder,
        private _createPlans: CreatePlansFacade
    ) {}

    ngOnInit() {
        const ratesGroup = this.ratesForm.get('rates.age') as UntypedFormGroup;
        const tobacco = this.ratesForm.get('rates.tobacco') as UntypedFormGroup;
        for (let i = 0; i < this.numberOfAges; i++) {
            ratesGroup.addControl('age' + i, this._fb.control(null));
            tobacco.addControl('age' + i, this._fb.control(null));
        }
        this.ratesForm.markAsPristine();

        this._createPlans.wizardState$.pipe(first()).subscribe((state) => {
            const setup = dotProp.get(state, 'value.setup') as any;
            if (setup) {
                this._createPlans.loadAgeRateCurve(
                    setup.employerZipCode,
                    moment(setup.planExpirationDate).format('YYYY-MM-DD')
                );
            }
        });

        this.ratesForm
            .valueChanges.pipe(
                distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
                takeUntil(this._onDestroy$)
            )
            .subscribe((type) => {
                this.removeValidators(type);
                this.addValidators(type);
                this.ratesForm.updateValueAndValidity();
            });

        this.ratesForm
            .get('hasTobaccoRates')
            .valueChanges.pipe(
                distinctUntilChanged(),
                takeUntil(this._onDestroy$)
            )
            .subscribe(() => {
                this.ratesForm.get('rates.tobacco').updateValueAndValidity();
            });

        this._createPlans.setCurrentValueToForm(this.ratesForm);

        if (this.rates) {
            this.ratesForm.patchValue({
                "effectiveDate": moment(this.rates.effectiveDate, moment.HTML5_FMT.DATE),
                "expirationDate": moment(this.rates.expirationDate, moment.HTML5_FMT.DATE),
                "rates": this.rates.rates,
                "age21Rate": this.rates.rates.age.age21,
                "age21TobaccoRate": this.rates.rates.tobacco.age21,
                "hasTobaccoRates": this.rates.rates.tobacco.age21 > 0,
                "id": this.rates.id
            });
        } else {
            this.ratesForm.patchValue({
                "id": null,
                "effectiveDate": moment(this.effectiveDate, moment.HTML5_FMT.DATE),
                "expirationDate": normalizeTerminationDate(
                    moment(this.effectiveDate, moment.HTML5_FMT.DATE).clone().add(11, 'months')
                ),
                "rates": this.defaultValues.rates,
                "age21Rate": null,
                "age21TobaccoRate": null,
                "hasTobaccoRates": null,
            });
        }
        this.ratesFormInit.emit(this.ratesForm);
        this.watchEffectiveDateValueChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    ngAfterViewInit() {
        this._createPlans.finishedNavigating();
    }

    watchEffectiveDateValueChanges(): void {
        this.ratesForm.controls.effectiveDate.valueChanges
            .pipe(
                tap((value: moment.Moment) => this.ratesForm.controls.expirationDate.patchValue(
                    moment(value, moment.HTML5_FMT.DATE).clone().add(11, 'months')
                )),
                takeUntil(this._onDestroy$),
            )
            .subscribe();
    }

    ageRatesValidator(tobacco: boolean): ValidatorFn {
        return (group: UntypedFormGroup): { [key: string]: any } | null => {
            if (
                group.root.get('rates.type')?.value !== 'age_rate' ||
                (tobacco && group.root.get('hasTobaccoRates')?.value === false)
            ) {
                return null;
            }
            for (const key of Object.keys(group.value)) {
                if (
                    group.value.hasOwnProperty(key) &&
                    (!group.value[key] || group.value[key] <= 0)
                ) {
                    return {
                        allRatesMustHaveValue: {},
                    };
                }
            }

            return null;
        };
    }

    calculateRates() {
        this._createPlans.ageRateCurve$
            .pipe(first())
            .subscribe((ageRateCurve) => {
                const age21Rate = this.ratesForm.get('age21Rate').value;
                const age21TobaccoRate = this.ratesForm.get('age21TobaccoRate')
                    .value;
                const hasTobaccoRates = this.ratesForm.get('hasTobaccoRates')
                    .value;
                const ageRates: any = {};
                const tobacco: any = {};
                for (let i = 0; i < ageRateCurve.ageRateCurve.length; i++) {
                    ageRates['age' + i] = Number(
                        Math.round(
                            parseFloat(
                                ageRateCurve.ageRateCurve[i] * age21Rate + 'e2'
                            )
                        ) + 'e-2'
                    );
                    if (hasTobaccoRates) {
                        tobacco['age' + i] = Number(
                            Math.round(
                                parseFloat(
                                    ageRateCurve.ageRateCurve[i] *
                                        age21TobaccoRate +
                                        'e2'
                                )
                            ) + 'e-2'
                        );
                    }
                }
                this.ratesForm.get('rates.age').setValue(ageRates);
                if (hasTobaccoRates) {
                    this.ratesForm.get('rates.tobacco').setValue(tobacco);
                }
                this.hideRates = false;
            });
    }

    removeValidators(type: string) {
        if (type === RateType.AgeRate) {
            this.ratesForm.get('rates.composite.single').clearValidators();
            this.ratesForm.get('rates.composite.single').clearAsyncValidators();
            this.ratesForm
                .get('rates.composite.single')
                .updateValueAndValidity();
            this.ratesForm
                .get('rates.composite.singleAndChildren')
                .clearValidators();
            this.ratesForm
                .get('rates.composite.singleAndChildren')
                .clearAsyncValidators();
            this.ratesForm
                .get('rates.composite.singleAndChildren')
                .updateValueAndValidity();
            this.ratesForm
                .get('rates.composite.singleAndSpouse')
                .clearValidators();
            this.ratesForm
                .get('rates.composite.singleAndSpouse')
                .clearAsyncValidators();
            this.ratesForm
                .get('rates.composite.singleAndSpouse')
                .updateValueAndValidity();
            this.ratesForm.get('rates.composite.family').clearValidators();
            this.ratesForm.get('rates.composite.family').clearAsyncValidators();
            this.ratesForm
                .get('rates.composite.family')
                .updateValueAndValidity();
        } else if (type === RateType.Composite) {
            const rates = this.ratesForm.get('rates') as UntypedFormGroup;
            rates.get('age').clearValidators();
            rates.get('age').clearAsyncValidators();
            rates.get('age').updateValueAndValidity();
            const hasTobaccoRates = this.ratesForm.get('hasTobaccoRates').value;
            if (hasTobaccoRates) {
                rates.get('tobacco').clearValidators();
                rates.get('tobacco').clearAsyncValidators();
                rates.get('tobacco').updateValueAndValidity();
            }
        }
    }

    addValidators(type: string) {
        if (type === RateType.AgeRate) {
            const rates = this.ratesForm.get('rates') as UntypedFormGroup;
            const hasTobaccoRates = this.ratesForm.get('hasTobaccoRates').value;
            rates.get('age').setValidators(this.ageRatesValidator(false));
            rates.get('age').updateValueAndValidity();
            if (hasTobaccoRates) {
                rates
                    .get('tobacco')
                    .setValidators(this.ageRatesValidator(true));
                rates.get('tobacco').updateValueAndValidity();
            }
            rates.updateValueAndValidity();
        } else if (type === RateType.Composite) {
            this.ratesForm
                .get('rates.composite.single')
                .setValidators(Validators.required);
            this.ratesForm
                .get('rates.composite.single')
                .updateValueAndValidity();
            this.ratesForm
                .get('rates.composite.singleAndChildren')
                .setValidators(Validators.required);
            this.ratesForm
                .get('rates.composite.singleAndChildren')
                .updateValueAndValidity();
            this.ratesForm
                .get('rates.composite.singleAndSpouse')
                .setValidators(Validators.required);
            this.ratesForm
                .get('rates.composite.singleAndSpouse')
                .updateValueAndValidity();
            this.ratesForm
                .get('rates.composite.family')
                .setValidators(Validators.required);
            this.ratesForm
                .get('rates.composite.family')
                .updateValueAndValidity();
        }
    }

    toggleRatesTable() {
        this._hideRates$.next(!this._hideRates$.getValue());
    }
}

import { Injectable } from '@angular/core';

import { ReCaptchaV3Service } from 'ng-recaptcha';


@Injectable({
    providedIn: 'root',
})
export class RecaptchaService {

    constructor(
        private _recaptcha: ReCaptchaV3Service,
    ) {}

    getRecaptcha(action: 'loginUser' | 'resetPassword' | 'registerUser' | 'verifyUser'): Promise<string> {
        return this._recaptcha.execute(action).toPromise();
    }
}

import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-activity-as',
  templateUrl: './view-activity-as.component.html',
  styleUrls: ['./view-activity-as.component.scss']
})
export class ViewActivityAsComponent implements OnInit {

  selectedUser;

  constructor(private _activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  close(result: any) {
    this._activeModal.close(result);
  }

  submit() {
    this._activeModal.close(this.selectedUser);
  }
}

import { createSelector } from '@ngrx/store';
import {
    createPlansDataKey,
    CreatePlansState,
} from '../+state/create-plans.models';
import { createNestedFeatureSelector } from '@benefit-sculptor/core';

export const getCreatePlansState = createNestedFeatureSelector<
    CreatePlansState
>(createPlansDataKey);

export const getAgeRateCurve = createSelector(
    getCreatePlansState,
    (state) => state.ageRateCurve
);

export const getAgeRates = createSelector(
    getAgeRateCurve,
    (ageRateCurve) => ageRateCurve.curve
);

export const getAgeRateCurveLoading = createSelector(
    getAgeRateCurve,
    (state) => state.loading
);

export const isAgeRateCurveLoading = createSelector(
    getAgeRateCurve,
    (ageRateCurve) => ageRateCurve.loading
);

export const getCurrentIssuers = createSelector(
    getCreatePlansState,
    (state) => state.currentIssuers
);

export const isIssuersLoading = createSelector(
    getCurrentIssuers,
    (state) => state.loading
);

export const getIssuers = createSelector(
    getCurrentIssuers,
    (state) => state.issuers
);

export const getPlanList = createSelector(
    getCreatePlansState,
    (state) => state.planList
);

export const getPlans = createSelector(getPlanList, (state) => state.plans);

export const isPlanListLoading = createSelector(
    getPlanList,
    (state) => state.loading
);

export const isCloningExisting = createSelector(
    getCreatePlansState,
    (state) => state.isCloning
);

export const creatingState = createSelector(
    getCreatePlansState,
    (state) => state.creating
);

export const isCreatingPlan = createSelector(
    creatingState,
    (created) => created.loading
);

export const newPlan = createSelector(
    creatingState,
    (created) => created.newPlan
);

import { parseObjectDecimalsToFloats } from '@benefit-sculptor/core';
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class TransformDecimalsToNumbers implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            map((response: HttpResponse<any>) => {
                if (
                    response instanceof HttpResponse &&
                    response.status < 300 &&
                    response.body
                ) {
                    const body = parseObjectDecimalsToFloats(response.body);

                    return response.clone({
                        body
                    });
                }

                return response;
            })
        );
    }
}

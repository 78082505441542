import { Injectable } from '@angular/core';
import {
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { EMPTY, Observable, throwError, timer } from 'rxjs';
import { catchError, first, switchMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthFacadeService } from '@benefit-sculptor/auth';
import { CustomHttpError } from '../../error-handler/custom-error';

export const IGNORED_STATUSES = new HttpContextToken<number[]>(() => []);

/** Pass untouched request through to the next request handler. */
@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {
    constructor(
        private _toastr: ToastrService,
        private _router: Router,
        private _auth: AuthFacadeService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((response) => {
                const ignoredStatuses = req.context.get(IGNORED_STATUSES);

                const errorDetail = response.error?.detail;
                if (
                    response.status === 403 &&
                    errorDetail ===
                        'Authentication credentials were not provided.' &&
                    this._auth.isLoggedIn()
                ) {
                    this._toastr.error(
                        'Not Logged In. Redirecting to the login page.'
                    );

                    return timer(1500).pipe(
                        first(),
                        switchMap(() => {
                            this._auth.clearAuth();

                            return EMPTY;
                        })
                    );
                } else if (ignoredStatuses?.includes(response.status)) {
                    return throwError(response);
                } else if (
                    (response.status === 401 || response.status === 403) &&
                    this._auth.isLoggedIn()
                ) {
                    this._toastr.error(
                        response.error.detail ?? response.error,
                        'Unauthorized'
                    );

                    return EMPTY;
                }

                const error = new CustomHttpError(response, {
                    http: true,
                    data: {
                        error: response.error.error,
                        url: req.urlWithParams,
                        method: req.method,
                        body: req.body,
                    }
                });
                return throwError(error);
            })
        );
    }
}

<div class="card">
    <div class="card-header">
        <h3 class="card-title align-items-start flex-column">Employers</h3>
        <div class="card-toolbar flex-row-reverse">
            <button
                *ngIf="isNonSubAgent()"
                class="btn btn-outline btn-outline-primary"
                (click)="addNewEmployer()"
            >
                Add New Employer
            </button>
            <div
                ngbTooltip="Download Employer data as Excel file"
                placement="bottom"
            >
                <button
                    class="search-button me-3 btn btn-outline btn-outline-excel"
                    (click)="downloadExcelFile()"
                >
                    <fa-icon
                        [icon]="['fad', 'file-excel']"
                        *ngIf="(downloadingExcel$ | async) === false"
                    ></fa-icon>
                    <fa-icon
                        *ngIf="downloadingExcel$ | async"
                        [icon]="['fad', 'spinner-third']"
                        [spin]="true"
                    ></fa-icon>
                </button>
            </div>
            <div class="page-filters" ngbDropdown [autoClose]="true">
                <button
                    ngbDropdownToggle
                    [ngClass]="filtersApplied ? 'btn-primary' : 'btn-light'"
                    class="btn btn-active-primary"
                >
                    <fa-duotone-icon
                        [icon]="['fad', 'filter']"
                    ></fa-duotone-icon>
                </button>
                <div
                    ngbDropdownMenu
                    class="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                    [formGroup]="filterForm"
                >
                    <div class="px-7 py-5">
                        <h3 class="fs-5 text-dark fw-bold">Filter Employers</h3>
                    </div>
                    <div class="separator border-gray-200"></div>
                    <div class="px-7 py-5">
                        <div class="mb-4">
                            <label for="nameContains" class="form-label"
                                >Name Contains</label
                            >
                            <input
                                type="text"
                                id="nameContains"
                                class="form-control"
                                formControlName="nameContains"
                                placeholder="Name Contains"
                            />
                        </div>
                        <div class="mb-4">
                            <label class="form-label">Status</label>
                            <ng-select
                                formControlName="status"
                                [multiple]="false"
                                placeholder="Status..."
                                required
                            >
                                <ng-option
                                    *ngFor="
                                        let employerStatus of employerStatuses
                                    "
                                    [value]="employerStatus.value"
                                >
                                    <besc-employer-status-badge
                                        [status]="employerStatus.value"
                                    ></besc-employer-status-badge>
                                </ng-option>
                            </ng-select>
                        </div>
                        <div class="mb-4">
                            <label class="form-label">State</label>
                            <ng-select
                                formControlName="state"
                                [multiple]="true"
                                placeholder="State..."
                                required
                            >
                                <ng-option
                                    *ngFor="
                                        let state of statesForFilter$ | async
                                    "
                                    [value]="state.value"
                                >
                                    {{ state.label }}
                                </ng-option>
                            </ng-select>
                        </div>
                    </div>
                    <div class="separator border-gray-200"></div>
                    <div class="px-7 py-5">
                        <button
                            class="btn btn-primary btn-sm"
                            (click)="filterEmployers()"
                        >
                            Filter
                        </button>
                        <button
                            class="btn btn-secondary btn-sm ms-4"
                            (click)="resetFilter()"
                        >
                            Reset
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-body p-0 table-responsive">
        <div class="loading" *ngIf="loading$ | async">
            <mat-spinner [diameter]="40" color="primary"></mat-spinner>
        </div>
        <table
            *ngIf="employers$ | async"
            mat-table
            #table
            [dataSource]="employersData"
            [matSortDisableClear]="true"
            matSort
            class="w-100"
        >
            <ng-container matColumnDef="companyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                <td mat-cell *matCellDef="let element">
                    <a [routerLink]="[element.link]">{{
                        element.companyName
                    }}</a>
                </td>
            </ng-container>
            <ng-container matColumnDef="employeeCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    # of Employees
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.employeeCount }}
                </td>
            </ng-container>
            <ng-container matColumnDef="plansCount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    # of Current Products
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.plansCount }}
                </td>
            </ng-container>
            <ng-container matColumnDef="companyStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Status
                </th>
                <td mat-cell *matCellDef="let element">
                    <besc-employer-status-badge
                        [status]="element.companyStatus"
                    ></besc-employer-status-badge>
                </td>
            </ng-container>
            <ng-container matColumnDef="companyCity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.contactInfo.city }}
                </td>
            </ng-container>
            <ng-container matColumnDef="companyState">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.contactInfo.state }}
                </td>
            </ng-container>
            <ng-container matColumnDef="renewalDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    Renewal Date
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.renewalDate }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <a
                        *ngIf="isNonSubAgent()"
                        class="employer-action"
                        tabindex="-1"
                        (click)="setEmployerToDelete(element); delete(element)"
                    >
                        <fa-icon [icon]="['fad', 'trash']"></fa-icon>
                    </a>
                </td>
            </ng-container>
            <thead>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            </thead>

            <tbody>
                <tr
                    mat-row
                    [ngClass]="{
                        'loading-row': (loadingIndex$ | async) === row.id
                    }"
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </tbody>
        </table>
    </div>
    <div class="card-footer py-0">
        <mat-paginator
            [length]="employers.length"
            [pageSize]="10"
            [pageSizeOptions]="[10, 25, 50]"
        >
        </mat-paginator>
    </div>
</div>

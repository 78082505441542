import { Injectable } from '@angular/core';
import {
    NgbDateParserFormatter,
    NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import {
    getMomentFromDateStruct,
    getNgbDateStructFromMoment,
} from '../helpers';

@Injectable()
export class NgbCustomDateParserService extends NgbDateParserFormatter {
    format(date: NgbDateStruct | null): string {
        if (date) {
            const momentDate = getMomentFromDateStruct(date);
            return momentDate.format('MM/DD/YYYY');
        }

        return '';
    }

    parse(value: string): NgbDateStruct | null {
        if (value) {
            const date = moment(
                value,
                [moment.HTML5_FMT.DATE, 'MM/DD/YYYY'],
                true
            );
            if (date.isValid()) {
                return getNgbDateStructFromMoment(date);
            }
        }
        return null;
    }
}

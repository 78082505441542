<div class="modal-header">
    <h4 class="modal-title">Plan Rates</h4>
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm"
        aria-label="Close"
        (click)="close('Cross click')"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
</div>
<div class="modal-body">
  <ng-container [formGroup]="rateForm">
    <div class="btn-group">
      <input
        *ngIf="rates.rates.type === 'age_rate'"
        id="ageRates"
        type="radio"
        class="btn-check"
        formControlName="type"
        value="age_rates"
      />
      <label *ngIf="rates.rates.type === 'age_rate'" class="btn btn-primary" for="ageRates">
        Age Rates
      </label>
      <input
        id="compositeRate"
        type="radio"
        class="btn-check"
        formControlName="type"
        value="composite"
        *ngIf="compositeRates$ | async"
      />
      <label *ngIf="compositeRates$ | async" class="btn btn-primary" for="compositeRate">
        Composite
      </label>
    </div>
    <div *ngIf="rates">
      <div *ngIf="rateForm.value.type === 'age_rates'" class="card">
        <div class="card-body">
          <div class="age-table my-4">
            <div class="age-column" *ngFor="let index of indexes">
              <div class="age-item py-2" *ngFor="let column of columns">
                <strong>Age {{ index * 11 + column }}:</strong>
                {{ rates.rates.age['age' + (index * 11 + column)] | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="rateForm.value.type === 'composite'" class="card">
        <div class="card-body" *ngIf="compositeRates$ | async as compositeRates">
          <div class="composite-table my-4">
            <div class="composite-item px-4">
              <h6 class="title pb-2 mb-2 border-bottom">Employee Only</h6>
              <p class="item">
                <strong>{{ compositeRates.single | currency }}</strong>
              </p>
            </div>
            <div class="composite-item px-4">
              <h6 class="title pb-2 mb-2 border-bottom">Employee & Spouse</h6>
              <p class="item">
                <strong>{{ compositeRates.singleAndSpouse | currency }}</strong>
              </p>
            </div>
            <div class="composite-item px-4">
              <h6 class="title pb-2 mb-2 border-bottom">Employee & Children</h6>
              <p class="item">
                <strong>{{ compositeRates.singleAndChildren | currency }}</strong>
              </p>
            </div>
            <div class="composite-item px-4">
              <h6 class="title pb-2 mb-2 border-bottom">Employee & Family</h6>
              <p class="item">
                <strong>{{ compositeRates.family | currency }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #loadingBlock>
    <div class="loading">
      <mat-spinner class="center" [diameter]="40" color="primary"></mat-spinner>
    </div>
  </ng-template>
</div>
<div class="modal-footer"></div>

<div class="page d-flex flex-row w-100">
    <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <besc-header class="header align-items-stretch"></besc-header>
        <besc-navigation-bar></besc-navigation-bar>
        <div id="kt_content" class="d-flex flex-column flex-column-fluid">
            <div ktContentAnimate class="d-flex" [ngClass]="{'container-fluid': fullContent !== true}">
                <besc-sidebar *ngIf="hasSidebar"></besc-sidebar>
                <div class="main-body">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="agency">
    <div class="row" [formGroup]="form">
        <div class="col-md-6">
            <div class="card mb-8">
                <div class="card-header">
                    <h3 class="card-title">{{ agency.name }}</h3>
                </div>

                <div class="card-body">
                    <div class="mb-4">
                        <label for="agencyName" class="form-label required"
                            >Agency Name</label
                        >
                        <input
                            id="agencyName"
                            class="form-control"
                            placeholder="Agency Name"
                            type="text"
                            formControlName="name"
                            name="name"
                            required
                            bescInputValidation
                        />
                    </div>
                    <ng-container formGroupName="contactInfo">
                        <div class="row">
                            <div class="col-md-6 mb-4">
                                <label for="address" class="form-label"
                                    >Address</label
                                >
                                <input
                                    id="address"
                                    class="form-control"
                                    placeholder="Address"
                                    type="text"
                                    formControlName="address"
                                    name="address"
                                    bescInputValidation
                                />
                            </div>
                            <div class="col-md-3 mb-4">
                                <label for="city" class="form-label"
                                    >City</label
                                >
                                <input
                                    id="city"
                                    class="form-control"
                                    placeholder="City"
                                    type="text"
                                    formControlName="city"
                                    name="city"
                                />
                            </div>
                            <div class="col-md-3 mb-4">
                                <label for="zipCode" class="form-label required"
                                    >City</label
                                >
                                <input
                                    id="zipCode"
                                    class="form-control"
                                    placeholder="City"
                                    type="text"
                                    formControlName="zipCode"
                                    name="zipCode"
                                    required
                                    bescInputValidation
                                />
                                <span class="form-text">Can be #####</span>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="card-footer">
                    <div
                        class="kt-form__actions d-flex flex-row align-items-end"
                    >
                        <!--            <button-->
                        <!--                [ngClass]="{-->
                        <!--                    'kt-spinner kt-spinner&#45;&#45;right kt-spinner&#45;&#45;md kt-spinner&#45;&#45;light':-->
                        <!--                        loading$ | async-->
                        <!--                }"-->
                        <!--                [disabled]="vm.employerForm.invalid || (loading$ | async)"-->
                        <!--                class="btn btn-primary"-->
                        <!--                (click)="update()"-->
                        <!--            >-->
                        <!--                Update-->
                        <!--            </button>-->
                        <button
                            class="btn btn-primary"
                            [ngClass]="{
                                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                                    loading$ | async
                            }"
                            [disabled]="form.invalid || (loading$ | async)"
                            (click)="updateAgency()"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card" [formGroup]="form" *ngIf="agency">
                <div class="card-header">
                    <h3 class="card-title">
                        {{
                            'Broker: ' +
                                agency.owner.firstName +
                                ' ' +
                                agency.owner.lastName
                        }}
                    </h3>
                </div>

                <div class="card-body" formGroupName="owner">
                    <div class="row">
                        <div class="col-md">
                            <label for="firstName" class="form-label required"
                                >First Name</label
                            >
                            <input
                                id="firstName"
                                class="form-control"
                                placeholder="First Name"
                                type="text"
                                formControlName="firstName"
                                name="firstName"
                                required
                                bescInputValidation
                            />
                        </div>
                        <div class="col-md">
                            <label for="lastName" class="form-label required"
                                >Last Name</label
                            >
                            <input
                                id="lastName"
                                class="form-control"
                                placeholder="Last Name"
                                type="text"
                                formControlName="lastName"
                                name="lastName"
                                required
                                bescInputValidation
                            />
                        </div>
                        <div class="col-md">
                            <label for="email" class="form-label required"
                                >Email</label
                            >
                            <input
                                id="email"
                                class="form-control"
                                placeholder="Email"
                                type="text"
                                formControlName="email"
                                name="email"
                                required
                                bescInputValidation
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h3 class="card-title">Agency Employees</h3>
                </div>

                <div class="card-body">
                    <ng-container *ngIf="agency.employees as employees">
                        <p *ngIf="employees.length === 0">
                            Your agency currently does not have any employees.
                            Click "Add Employee" below to add one.
                        </p>
                        <div class="table-responsive">
                            <table
                                class="table table-bordered"
                                *ngIf="employees.length > 0"
                            >
                                <thead>
                                    <tr>
                                        <th class="min-w-125px">Name</th>
                                        <th class="min-w-125px">Email</th>
                                        <th class="min-w-125px">Job Title</th>
                                        <th class="min-w-125px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let employee of employees">
                                        <td>
                                            {{ employee.lastName }},
                                            {{ employee.firstName }}
                                        </td>
                                        <td>{{ employee.email }}</td>
                                        <td>{{ employee.jobTitle }}</td>
                                        <td>
                                            <div
                                                class="d-flex justify-content-end"
                                            >
                                                <button
                                                    class="btn btn-link-primary me-2"
                                                    (click)="
                                                        deleteAgencyEmployee(
                                                            employee
                                                        )
                                                    "
                                                >
                                                    <fa-icon
                                                        [icon]="[
                                                            'fad',
                                                            'trash'
                                                        ]"
                                                    ></fa-icon>
                                                </button>
                                                <div
                                                    ngbDropdown
                                                    class="d-inline-block"
                                                >
                                                    <button
                                                        class="btn btn-link-primary me-2"
                                                        ngbDropdownToggle
                                                    >
                                                        <fa-icon
                                                            [icon]="[
                                                                'far',
                                                                'ellipsis-h'
                                                            ]"
                                                        ></fa-icon>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <button
                                                            ngbDropdownItem
                                                            (click)="
                                                                transferOwnership(
                                                                    employee
                                                                )
                                                            "
                                                        >
                                                            Transfer Agency
                                                            Ownership
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                </div>
                <div class="card-footer">
                    <div
                        class="kt-form__actions d-flex flex-row align-items-end"
                    >
                        <button
                            class="btn btn-primary"
                            [ngClass]="{
                                'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                                    loading$ | async
                            }"
                            (click)="addAgencyEmployee()"
                        >
                            Add Employee
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<besc-registration-container [agencyRegistration]="nameAndAgency$ | async">
    <div class="set-password-container">
        <h2>{{ title }}</h2>
        <p *ngIf="type === 'reset'">Almost done. Enter your new password and you’re good to go.</p>
        <form class="kt-form" [formGroup]="passwordForm" autocomplete="off" (ngSubmit)="submitPassword()"
            id="set-password-form">
            <div class="bs-input-group">
                <label for="password" class="bs-input-label">{{ passwordLabel }}</label>
                <input bescInputValidation bescPasswordComplexity name="password" type="password" class="bs-input"
                    placeholder="**********" formControlName="password" autocomplete="off">

                <ul class="password-requirements" *ngIf="passwordRequirements$ | async as requirement">
                    <li [ngClass]="{valid: requirement.length}">At least 8 characters long.</li>
                    <li [ngClass]="{valid: requirement.lower}">1 lowercase character.</li>
                    <li [ngClass]="{valid: requirement.upper}">1 uppercase character.</li>
                </ul>
            </div>
            <div class="bs-input-group">
                <label for="passwordConfirm" class="bs-input-label">{{ confirmLabel }}</label>
                <input bescInputValidation bescPasswordComplexity name="passwordConfirm" type="password"
                    class="bs-input" [placeholder]="confirmLabel" formControlName="passwordConfirm" autocomplete="off">
            </div>
        </form>
        <footer>
            <div class="progress-container" *ngIf="type === 'set'">
                <div class="progress" style="width: 56px;"></div>
            </div>
            <button class="bs-button bs-primary" type="submit" [disabled]="passwordForm.invalid || (loading$ | async)"
                form="set-password-form"><besc-loading-indicator [loading]="loading$ | async">{{ continueLabel
                    }}</besc-loading-indicator></button>
        </footer>
    </div>
</besc-registration-container>

<ng-template #setSuccessModal let-modal>
    <div class="success-modal">
        <header>
            <div class="logo">
                <img src="/assets/images/BS-BenefitSculptorLogo-Full.png" alt="Benefit Sculptor" />
            </div>
        </header>
        <div class="content">
            <div>
                <h3>You have successfully registered!</h3>
                <p>Please log into Benefit Sculptor</p>
            </div>
            <footer>
                <div class="progress-container">
                    <div class="progress" style="width: 109px;"></div>
                </div>
                <a routerLink="/auth/login" class="bs-button bs-primary" (click)="modal.close()">Continue</a>
            </footer>
        </div>
    </div>
</ng-template>

<ng-template #resetSuccessModal let-modal>
    <div class="success-modal">
        <header>
            <div class="logo">
                <img src="/assets/images/BS-BenefitSculptorLogo-Full.png" alt="Benefit Sculptor" />
            </div>
        </header>
        <div class="content">
            <div>
                <h3>Password successfully updated!</h3>
                <p>Your password has been update and is secure. You can now log in again.</p>
            </div>
            <footer>
                <a routerLink="/auth/login" class="bs-button bs-primary" (click)="modal.close()">Return to login</a>
            </footer>
        </div>
    </div>
</ng-template>
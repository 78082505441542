import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    EMPLOYEES_FEATURE_KEY,
    employeesAdapter,
    State,
} from './employees.reducer';
import { sortCompareFn } from '@benefit-sculptor/core';
import { Employee } from '../interfaces';

// Lookup the 'Employees' feature state managed by NgRx
export const getEmployeesState = createFeatureSelector<State>(
    EMPLOYEES_FEATURE_KEY
);

const { selectAll, selectEntities } = employeesAdapter.getSelectors();

export const getLoading = createSelector(
    getEmployeesState,
    (state) => state.loading
);

export const getEmployeesLoaded = createSelector(
    getLoading,
    (state) => !state.employees
);

export const getPlansLoaded = createSelector(
    getLoading,
    (state) => !state.plans
);

export const getSaveDependentsLoaded = createSelector(
    getLoading,
    (state) => state.saveDependents
);

export const getUpdateLoading = createSelector(
    getLoading,
    (state) => state.update
);

export const getAddNewLoading = createSelector(
    getLoading,
    (state) => state.addNew
);

export const getEmployeesError = createSelector(
    getEmployeesState,
    (state: State) => state.error
);

export const getAllEmployees = createSelector(
    getEmployeesState,
    (state: State) => selectAll(state)
);

export const getSort = createSelector(
    getEmployeesState,
    (state: State) => state.sort
);

export const getFilter = createSelector(
    getEmployeesState,
    (state: State) => state.filter
);

export const getEmployeesEntities = createSelector(
    getEmployeesState,
    (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
    getEmployeesState,
    (state: State) => state.selectedId
);

export const getSelected = createSelector(
    getEmployeesEntities,
    getSelectedId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getIssuers = createSelector(
    getEmployeesState,
    (state) => state.issuers
);

export const getEmployeePlans = createSelector(
    getEmployeesState,
    (state) => state.employeePlans
);

export const getEmployerPlansToEmployeeIds = createSelector(
    getEmployeesState,
    (state) => state.employerPlansToEmployeeIds
);

export const getCurrentEmployerPlanId = createSelector(
    getEmployeesState,
    (state) => state.currentEmployerPlanId
);

export const getEmployeeIdsForCurrentPlan = createSelector(
    getCurrentEmployerPlanId,
    getEmployerPlansToEmployeeIds,
    (currentPlanId, plansToEmployeeIds) => {
        if (currentPlanId === 'all') {
            return Object.keys(plansToEmployeeIds).reduce(
                (allEmployees, planId) =>
                    planId !== 'none'
                        ? allEmployees.concat(plansToEmployeeIds[planId])
                        : allEmployees,
                []
            );
        }

        return plansToEmployeeIds.hasOwnProperty(currentPlanId)
            ? plansToEmployeeIds[currentPlanId]
            : [];
    }
);

export const getEmployeeIdsNotOnCurrentPlan = createSelector(
    getCurrentEmployerPlanId,
    getEmployerPlansToEmployeeIds,
    (currentPlanId, plansToEmployeeIds) => {
        let employeeIds = [];

        if (currentPlanId) {
            for (const planId of Object.keys(plansToEmployeeIds)) {
                if (planId !== currentPlanId) {
                    employeeIds = employeeIds.concat(
                        plansToEmployeeIds[planId]
                    );
                }
            }
        }

        return employeeIds;
    }
);

export const getEmployeeIdsNotOnAnyPlan = createSelector(
    getEmployerPlansToEmployeeIds,
    (plansToEmployeeIds) => plansToEmployeeIds['none']
);

export const getEmployeesForCurrentPlan = createSelector(
    getEmployeeIdsForCurrentPlan,
    getEmployeesEntities,
    (ids, entities) =>
        ids
            .map((id) => entities[id])
            .sort(sortCompareFn(['lastName', 'firstName'], 'asc'))
);

export const getEmployeesNotOnCurrentPlan = createSelector(
    getEmployeeIdsNotOnCurrentPlan,
    getEmployeesEntities,
    (ids, entities) =>
        ids
            .map((id) => entities[id])
            .sort(sortCompareFn(['lastName', 'firstName'], 'asc'))
);

export const getEmployeesNotOnAnyPlan = createSelector(
    getEmployeeIdsNotOnAnyPlan,
    getEmployeesEntities,
    (ids, entities) =>
        ids
            .map((id) => entities[id])
            .filter((obj) => obj !== undefined)
            .sort(sortCompareFn(['lastName', 'firstName'], 'asc'))
);

export const getEffectiveDate = createSelector(
    getEmployeesState,
    (state) => state.effectiveDate
);

export const getCurrentEmployeeState = createSelector(
    getEmployeesState,
    (state) => state.currentEmployee
);

export const getCurrentEmployeeId = createSelector(
    getCurrentEmployeeState,
    (state) => state.id
);

export const getCurrentEmployee = createSelector(
    getEmployeesEntities,
    getCurrentEmployeeId,
    (entities, selectedId) => selectedId && entities[selectedId]
);

export const getCurrentEmployeePlan = createSelector(
    getCurrentEmployeeState,
    (state) => state.currentPlan
);

export const getEmployerId = createSelector(
    getEmployeesState,
    (state) => state.employerId
);

export const getFilteredEmployees = createSelector(
    getAllEmployees,
    getEmployeePlans,
    getSort,
    getFilter,
    (employees, plans, sort, filter) => {
        employees = [...employees];
        if (sort && sort.direction !== '') {
            employees = employees.sort(
                sortCompareFn(sort.active.split(','), sort.direction)
            );
        }

        if (!filter) {
            return employees;
        }
        return employees.filter((employee: Employee) => {
            if (
                filter.nameContains &&
                !employee.fullName
                    .toLowerCase()
                    .includes(filter.nameContains.toLowerCase())
            ) {
                return false;
            }

            if (
                plans[employee.id] &&
                filter.carrier &&
                filter.carrier.length > 0 &&
                !filter.carrier.includes(plans[employee.id].issuer?.id)
            ) {
                return false;
            }

            if (
                filter.plan &&
                filter.plan.length > 0 &&
                ((plans[employee.id] &&
                    !filter.plan.includes(plans[employee.id].id)) ||
                    (!plans[employee.id] && !filter.plan.includes('no-plan')))
            ) {
                return false;
            }

            return true;
        });
    }
);

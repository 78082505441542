<div class="sidebar-container">
  <div class="sidebar-items">
    <div
      class="menu-item py-3"
      *ngIf="parent$ | async as parent"
      [ngClass]="{
          'menu-active': parent.isActive
      }"
    >
      <a
        class="menu-link menu-center"
        [attr.href]="!parent.disabled ? parent.link : undefined"
        (click)="navigateItem(parent, $event)"
        [ngClass]="{
          disabled: parent.disabled
        }"
      >
        <span class="menu-title">
          {{ parent.label }}
        </span>
      </a>
    </div>
    <div
      class="menu-item py-3"
      *ngFor="let item of navigationItems$ | async"
      [ngClass]="{
        'menu-active': item.isActive
      }"
    >
      <a
        class="menu-link menu-center"
        [attr.href]="!item.disabled ? item.link : undefined"
        (click)="navigateItem(item, $event)"
        [ngClass]="{
          disabled: item.disabled
        }"
      >
        <span class="menu-title">
          {{ item.label }}
        </span>
      </a>
    </div>
  </div>
</div>

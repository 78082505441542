<div class="contributions-form" [formGroup]="contributionsForm">
    <div class="contributions-type">
        <span>Contribution Type</span>
        <div class="type-button">
            <div class="btn-group" id="contribution-type">
                <input
                    id="rateBuildUp"
                    type="radio"
                    class="btn-check"
                    formControlName="type"
                    [value]="'rate_build_up'"
                    *ngIf="rateType !== 'composite' && billingType !== 'composite'"
                />
                <label
                    *ngIf="rateType !== 'composite' && billingType !== 'composite'"
                    for="rateBuildUp"
                    class="btn btn-secondary"
                >
                    Rate Build Up
                </label>
                <input
                    id="compositeBuildUp"
                    type="radio"
                    class="btn-check"
                    formControlName="type"
                    [value]="'composite'"
                />
                <label for="compositeBuildUp" class="btn btn-secondary">
                    Composite
                </label>
            </div>
        </div>
        <a class="plan-rates" (click)="openPlanRatesModal()" *ngIf="hasEmployees$ | async">See Plan Rates</a>
    </div>
    <hr />
    <div class="" *ngIf="contributionsLabels$ | async as contributionsLabels">
        <div class="row">
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value1"
                    formControlName="value1"
                ></besc-contribution-slider>
            </div>
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value2"
                    formControlName="value2"
                ></besc-contribution-slider>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value3"
                    formControlName="value3"
                ></besc-contribution-slider>
            </div>
            <div class="col-md-6 mb-4">
                <besc-contribution-slider
                    [label]="contributionsLabels.value4"
                    formControlName="value4"
                ></besc-contribution-slider>
            </div>
        </div>
    </div>
    <hr />
</div>

<div class="create-new-group-modal">
    <div class="new-group-header">
        <h2>Add New Employer</h2>
        <button class="bs-button-close" (click)="close('Cross click')">
            <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.8072 1.99757L13.3159 0.378174L7.40361 6.79832L1.4913 0.378174L0 1.99757L5.91231 8.41772L0 14.8379L1.4913 16.4573L7.40361 10.0371L13.3159 16.4573L14.8072 14.8379L8.89491 8.41772L14.8072 1.99757Z"
                    fill="black" />
            </svg>
        </button>
    </div>
    <form class="new-group-form" [formGroup]="newEmployerForm">
        <div class="row">
            <div class="col-12">
                <div class="bs-input-group employer-name">
                    <label for="companyName" class="bs-input-label">Employer name*</label>
                    <input bescInputValidation required name="companyName" type="text" class="bs-input"
                        placeholder="Employer Name" formControlName="companyName">
                </div>
            </div>
            <div class="col-6 info-row">
                <div class="bs-input-group" formGroupName="contactInfo">
                    <label for="zipCode" class="bs-input-label">Zip code*</label>
                    <input bescInputValidation required name="zipCode" type="text" class="bs-input"
                        placeholder="Zip code" formControlName="zipCode">
                </div>
            </div>
            <div class="col-6 info-row" *ngIf="currentUser.userType !== roleType.AGENT">
                <div class="group-user-name">
                    <label class="group-user-name-label">Agent assigned*</label>
                    <ng-select class="bs-select" formControlName="user" [multiple]="false" placeholder="Agent assigned" required>
                        <ng-option *ngFor="let user of users$ | async" [value]="user.userId">
                            {{ user.firstName }} {{user.lastName }}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="actions">
            <button class="bs-button bs-secondary" (click)="dismiss('Close click')">
                Cancel
            </button>
            <button class="bs-button bs-primary" (click)="createEmployee()" [disabled]="newEmployerForm.invalid || (loading$ | async)">
                Create
            </button>
        </div>
    </form>
</div>
<!--begin::Body-->
<div class="d-flex forgot">
    <!--begin::Signin-->
    <div class="w-100">
        <h2 class="mb-4 fs-1">Forgot Password</h2>
        <p class="helper-text" *ngIf="(expired$ | async) === false">Enter your account’s email address and we’ll send
            you a link
            to reset your password.</p>
        <p class="helper-text text-danger" *ngIf="expired$ | async">Your password reset link has expired. Please request
            another
            link below.</p>

        <!--begin::Form-->
        <form [formGroup]="resetForm" autocomplete="off">
            <div class="bs-input-group email-group">
                <label class="bs-input-label-lg" for="email">Email</label>
                <input required bescInputValidation class="bs-input" type="email" placeholder="Email address"
                    formControlName="email" id="email" name="email" />
            </div>
            <!--begin::Action-->
            <div class="d-flex w-100 justify-content-between align-items-center">
                <button (click)="sendForgotPassword()" [disabled]="resetForm.invalid || (loading$ | async)"
                    class="bs-button bs-primary">
                    <besc-loading-indicator [loading]="loading$ | async">Send reset link</besc-loading-indicator>
                </button>
                <a href="javascript:;" routerLink="/auth/login" class="bs-link" *ngIf="(expired$ | async) === false">
                    <besc-chevron-left-icon></besc-chevron-left-icon> Back to
                    Login
                </a>
            </div>
            <!--end::Action-->
        </form>
        <!--end::Form-->
    </div>
    <!--end::Signin-->
</div>
<!--end::Body-->
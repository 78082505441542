import { createSelector, MemoizedSelector } from '@ngrx/store';
import * as dotProp from 'dot-prop';
import { isDevMode } from '@angular/core';

export function createNestedFeatureSelector<T>(
    featureName: string
): MemoizedSelector<object, T>;
export function createNestedFeatureSelector<T, V>(
    featureName: string
): MemoizedSelector<T, V>;
export function createNestedFeatureSelector(
    featureName: string
): MemoizedSelector<any, any> {
    return createSelector(
        (state: any) => {
            const featureState = dotProp.get(state, featureName);
            if (isDevMode() && !featureState) {
                console.warn(
                    `@ngrx/store: The feature name \"${featureName}\" does ` +
                        'not exist in the state, therefore createNestedFeatureSelector ' +
                        'cannot access it.  Be sure it is imported in a loaded module ' +
                        `using StoreModule.forRoot('${featureName}', ...) or ` +
                        `StoreModule.forFeature('${featureName}', ...).  If the default ` +
                        'state is intended to be undefined, as is the case with router ' +
                        'state, this development-only warning message can be ignored.'
                );
            }
            return featureState;
        },
        (featureState: any) => featureState
    );
}

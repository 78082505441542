import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WelcomeComponent } from './welcome/welcome.component';
import { SharedModule } from '@besc/shared';
import { WatchDemosIconComponent } from './watch-demos-icon/watch-demos-icon.component';
import { OnboardingContainerComponent } from './onboarding-container/onboarding-container.component';
import { DemosPlayerComponent } from './demos-player/demos-player.component';
import { CustomPipesModule } from '@benefit-sculptor/pipes';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [CommonModule, SharedModule, CustomPipesModule, MatProgressSpinnerModule, NgbAccordionModule],
    declarations: [
        WelcomeComponent,
        WatchDemosIconComponent,
        OnboardingContainerComponent,
        DemosPlayerComponent,
    ],
    exports: [WatchDemosIconComponent],
})
export class OnboardingModule {}

<div id="kt_toolbar_container" class="d-flex flex-stack container-fluid">
    <div class="d-flex">
        <ng-template #preActions></ng-template>
        <besc-page-title
            class="page-title d-flex align-items-center flex-wrap mx-3 mb-5 mb-lg-0"
        ></besc-page-title>
    </div>
    <!-- begin::Actions  -->
    <div class="d-flex align-items-center py-1">
        <ng-template #actions></ng-template>
    </div>
    <!-- end::Actions  -->
</div>

import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Agency, AgencyUser, AgencyUserRole } from '../agency.interface';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { UserService } from '@benefit-sculptor/auth';

@Injectable({
    providedIn: 'root',
})
export class AgencyService {
    userAgency$: Observable<Agency | undefined> = this._user.currentUser$.pipe(
        switchMap((user) => {
            if (user && !user.isAdmin) {
                return this._http.get<Agency>('agencies/current-agency');
            } else {
                return of(undefined);
            }
        }),
    );

    agency$: Observable<Agency | undefined> = this._user.currentUser$.pipe(
        switchMap((user) => {
            if (user && !user.isAdmin) {
                return this._http.get<Agency>('agencies/current-agency');
            } else {
                return of(undefined);
            }
        })
    );

    currentAgencyUser$: Observable<AgencyUser | undefined> = combineLatest([
        this._user.currentUser$,
        this.userAgency$,
    ]).pipe(
        map(([user, agency]) => {
            if (agency && user && !user.isAdmin) {
                return agency.owner.id === user.id
                    ? agency.owner
                    : agency.employees.find(
                          (employee) => employee.id === user.id
                      );
            } else {
                return undefined;
            }
        })
    );

    isAgencyOwner$ = this._user.currentUser$.pipe(
        switchMap((user) => {
            if (!user || user.isAdmin) {
                return of(false);
            } else {
                return this.currentAgencyUser$.pipe(
                    map((agencyUser) => {
                        return agencyUser?.role === AgencyUserRole.OWNER;
                    })
                );
            }
        })
    );

    public styleChanged$: EventEmitter<Agency>;

    constructor(private _http: HttpClient, private _user: UserService) {
        this.styleChanged$ = new EventEmitter();
    }

    getAgencies(offset: number, limit: number) {
        const params = new HttpParams()
            .append('offset', '' + offset)
            .append('limit', '' + limit);
        return this._http.get('agencies/', {
            params,
        });
    }

    getAgency(agencyId: string) {
        return this._http.get<Agency>(`agencies/${agencyId}`);
    }

    update(agencyId: string, update: Partial<Agency>) {
        return this._http.put<Agency>(`agencies/${agencyId}`, update);
    }

    updateLogo(agencyId, logo: File) {
        const formData = new FormData();
        formData.append('logo_file', logo, logo.name);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        
        return this._http.post<Agency>(`agencies/${agencyId}/upload-logo`, formData, {
            headers
        });
    }

    removeLogo(agencyId) {
        const formData = new FormData();
        formData.append('logo_file', null);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        
        return this._http.post<Agency>(`agencies/${agencyId}/upload-logo`, formData, {
            headers
        });
    }
}

import { Component, ElementRef } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faArchive,
    faBalanceScale,
    faBars,
    faBuilding,
    faCalendar,
    faChartBar,
    faChevronDown,
    faChevronLeft as faChevronLeftDuo,
    faChevronRight as faChevronRightDuo,
    faChevronUp,
    faQuestionCircle,
    faClipboardListCheck,
    faCopy,
    faDownload,
    faFileAlt,
    faFileDownload,
    faFileExcel,
    faFileInvoiceDollar,
    faFilePdf,
    faFileSpreadsheet,
    faFileUpload,
    faFilter,
    faHammer,
    faIdCard,
    faInfoSquare,
    faList,
    faLock,
    faLongArrowLeft,
    faPencil,
    faSearch,
    faSpinnerThird,
    faSyncAlt,
    faTimes,
    faTimesCircle,
    faTrash,
    faUser,
    faUsers,
    faTv
} from '@fortawesome/pro-duotone-svg-icons';
import {
    faBuilding as faBuildingReg,
    faCheck,
    faChevronRight,
    faEllipsisH as faEllipsisHRegular,
    faMinus,
    faPlus,
    faEllipsisV,
} from '@fortawesome/pro-regular-svg-icons';
import { NavigationService } from '@benefit-sculptor/navigation';
import {
    faCaretDown,
    faPlus as faPlusSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-light-svg-icons';
import { Agency, AgencyService } from '@besc/agency';
import { takeUntil, map } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';

const SIDEBAR_BACKGROUND_KEY = 'AgencyCustomSidebarBackground';
const LOGO_BACKGROUND_KEY = 'AgencyCustomSidebarLogoBackground';
const PRIMARY_COLOR_KEY = 'AgencyCustomPrimaryColor';
const PRIMARY_ACTIVE_COLOR_KEY = 'AgencyCustomPrimaryActiveColor';
const MENU_TEXT_COLOR_KEY = 'AgencyCustomMenuTextColor';
const MENU_ICON_COLOR_KEY = 'AgencyCustomMenuIconColor';
const MENU_BACKGROUND_COLOR_KEY = 'AgencyCustomMenuBackgroundColor';

const DEFAULT_COLORS = {
    SIDEBAR_BACKGROUND: '#1e1e2d',
    LOGO_BACKGROUND: '#1B1B28',
    PRIMARY: '#F37121',
    PRIMARY_ACTIVE: '#FF7824',
    MENU_TEXT: '#9899ac',
    MENU_ICON: '#3a3a5d',
    MENU_BACKGROUND: '#1b1b28'
};



@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'body[besc-root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'benefit-sculptor';
    private _onDestroy$: Subject<any> = new Subject();

    constructor(
        private _faLibrary: FaIconLibrary,
        private _navigation: NavigationService,
        private el: ElementRef,
        private _agency: AgencyService,
    ) {
        this._faLibrary.addIcons(faFilter);
        this._faLibrary.addIcons(faList);
        this._faLibrary.addIcons(faBuilding);
        this._faLibrary.addIcons(faTimes);
        this._faLibrary.addIcons(faIdCard);
        this._faLibrary.addIcons(faBalanceScale);
        this._faLibrary.addIcons(faUser);
        this._faLibrary.addIcons(faUsers);
        this._faLibrary.addIcons(faChartBar);
        this._faLibrary.addIcons(faFileInvoiceDollar);
        this._faLibrary.addIcons(faTrash);
        this._faLibrary.addIcons(faSpinnerThird);
        this._faLibrary.addIcons(faSearch);
        this._faLibrary.addIcons(faChevronUp);
        this._faLibrary.addIcons(faChevronDown);
        this._faLibrary.addIcons(faClipboardListCheck);
        this._faLibrary.addIcons(faInfoSquare);
        this._faLibrary.addIcons(faEllipsisV);
        this._faLibrary.addIcons(faEllipsisHRegular);
        this._faLibrary.addIcons(faFileAlt);
        this._faLibrary.addIcons(faFileSpreadsheet);
        this._faLibrary.addIcons(faArchive);
        this._faLibrary.addIcons(faMinus);
        this._faLibrary.addIcons(faPlus);
        this._faLibrary.addIcons(faPlusSolid);
        this._faLibrary.addIcons(faPencil);
        this._faLibrary.addIcons(faSyncAlt);
        this._faLibrary.addIcons(faCaretDown);
        this._faLibrary.addIcons(faLongArrowLeft);
        this._faLibrary.addIcons(faChevronRight);
        this._faLibrary.addIcons(faBuildingReg);
        this._faLibrary.addIcons(faDownload);
        this._faLibrary.addIcons(faFilePdf);
        this._faLibrary.addIcons(faLock);
        this._faLibrary.addIcons(faCircle);
        this._faLibrary.addIcons(faCheck);
        this._faLibrary.addIcons(faFileUpload);
        this._faLibrary.addIcons(faFileDownload);
        this._faLibrary.addIcons(faCopy);
        this._faLibrary.addIcons(faTimesCircle);
        this._faLibrary.addIcons(faFileExcel);
        this._faLibrary.addIcons(faCalendar);
        this._faLibrary.addIcons(faChevronRightDuo);
        this._faLibrary.addIcons(faChevronLeftDuo);
        this._faLibrary.addIcons(faBars);
        this._faLibrary.addIcons(faTv);
        this._faLibrary.addIcons(faHammer);
        this._faLibrary.addIcons(faQuestionCircle);
        this._navigation.register();

        combineLatest([
            this._agency.styleChanged$
        ]).pipe(
            takeUntil(this._onDestroy$),
            map(([agency]) => {
                this.updateStyling(agency);
            })
        ).subscribe();

        this._agency.userAgency$.pipe().subscribe(agency => {
            this.updateStyling(agency);
        });
    }

    updateStyling(agency: Agency) {
        if (agency) {
            const sidebarBackgroundColor = agency.sidebarBackgroundColor || DEFAULT_COLORS.SIDEBAR_BACKGROUND;
            const logoBackgroundColor = agency.logoBackgroundColor || DEFAULT_COLORS.LOGO_BACKGROUND;
            const primaryColor = agency.primaryColor || DEFAULT_COLORS.PRIMARY;
            const primaryActiveColor = agency.primaryActiveColor || DEFAULT_COLORS.PRIMARY_ACTIVE;
            const menuTextColor = agency.menuTextColor || DEFAULT_COLORS.MENU_TEXT;
            const menuIconColor = agency.menuIconColor || DEFAULT_COLORS.MENU_ICON;
            const menuBackgroundColor = agency.menuBackgroundColor || DEFAULT_COLORS.MENU_BACKGROUND;
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-aside-bg-color', sidebarBackgroundColor);
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-aside-logo-bg-color', logoBackgroundColor);
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-primary', primaryColor);
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-primary-active', primaryActiveColor);
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-link-text', menuTextColor);
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-link-icon', menuIconColor);
            (this.el.nativeElement as HTMLElement).style.setProperty('--kt-menu-background', menuBackgroundColor);
            localStorage.setItem(SIDEBAR_BACKGROUND_KEY, sidebarBackgroundColor);
            localStorage.setItem(LOGO_BACKGROUND_KEY, logoBackgroundColor);
            localStorage.setItem(PRIMARY_COLOR_KEY, primaryColor);
            localStorage.setItem(PRIMARY_ACTIVE_COLOR_KEY, primaryActiveColor);
            localStorage.setItem(MENU_TEXT_COLOR_KEY, menuTextColor);
            localStorage.setItem(MENU_ICON_COLOR_KEY, menuIconColor);
            localStorage.setItem(MENU_BACKGROUND_COLOR_KEY, menuBackgroundColor);
        }
    }
}

import {
    AbstractControl,
    AsyncValidator,
    NG_ASYNC_VALIDATORS,
    ValidationErrors,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Directive } from '@angular/core';
import { AuthFacadeService } from '@benefit-sculptor/auth';
import { map } from 'rxjs/operators';

@Directive({
    selector: '[bescCheckExistingEmail]',
    providers: [
        {
            provide: NG_ASYNC_VALIDATORS,
            useExisting: CheckExistingEmailValidator,
            multi: true,
        },
    ],
})
export class CheckExistingEmailValidator implements AsyncValidator {
    constructor(private _auth: AuthFacadeService) {}

    validate(
        emailControl: AbstractControl
    ): Observable<ValidationErrors | null> {
        const email = emailControl.value;
        if (!email || email === '') {
            return of(null);
        }
        return this._auth.checkExistingEmail(email).pipe(
            map((response: { existing: boolean }) => {
                if (response.existing) {
                    return response;
                }
                return null;
            })
        );
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'besc-down-arrow-icon',
    standalone: true,
    template: `<svg
        width="13"
        height="8"
        viewBox="0 0 13 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M10.8029 0.00350952L6.21289 4.58351L1.62289 0.00350952L0.212891 1.41351L6.21289 7.41351L12.2129 1.41351L10.8029 0.00350952Z"
            fill="currentColor"
        />
    </svg> `,
})
export class DownArrowIconComponent {}

import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {
    finalize,
    map,
    shareReplay,
    switchMap,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {
    BehaviorSubject,
    forkJoin,
    Observable,
    of,
    Subject,
} from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

import {
    downloadFileFromResponse,
    getUsaStatesForForm,
} from '@benefit-sculptor/core';
import { PlanFacade } from '@besc/plan';
import { EmployerStatus } from '@benefit-sculptor/small-employer';
import { AddNewGroupDialogComponent } from '../add-new-group-dialog/add-new-group-dialog.component';
import { EmployerService } from '../../services/employer.service';
import { Employer } from '../../interfaces';
import { RoleType, User, UserService } from '@benefit-sculptor/auth';
import { Confirmable, LabelValueRecord } from '@besc/shared';
import { deleteEmployerConfirmationParams } from '../../constants/EmployerConfirmationModal';

@Component({
    selector: 'besc-employer-list',
    templateUrl: './employer-list.component.html',
    styleUrls: ['./employer-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployerListComponent implements OnInit {
    private _loadingIndex: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private _destroy = new Subject();

    @Input() link: string;
    @Output() employerCreated: EventEmitter<Employer> = new EventEmitter<Employer>();

    @ViewChild(NgbDropdown) dropdown: NgbDropdown;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    employerStatuses: LabelValueRecord<EmployerStatus>[] = Object.keys(EmployerStatus).map(
        (status: EmployerStatus) => {
            return {
                value: status,
                label: status,
            };
        }
    );

    filterForm = this._fb.group({
        nameContains: '',
        status: null,
        state: null,
        carrier: null,
        renewingIn: null,
    });
    employers: Employer[] = [];
    employers$ = this._employer.all$.pipe(
        switchMap((employers) =>
            employers.length > 0 
                ? forkJoin(
                        employers.map((employer) => this._plan.all(employer.id, moment()))
                    ).pipe(
                        map((employerPlans) => {
                            return employerPlans.map((plans, i) => {
                                    return {
                                        ...employers[i],
                                        plansCount: plans.length,
                                        link: this.link.replace(
                                            ':employerId',
                                            employers[i].id
                                        ),
                                        renewalDate:
                                            plans.length > 0
                                                ? moment(plans[0].terminationDate)
                                                    .add(1, 'day')
                                                    .format(moment.HTML5_FMT.DATE)
                                                : '-',
                                    };
                                })
                        }),
                    )
                : of([])
        ),
        tap((employers) => this.employers = employers),
        tap(() => this.filterAndSort()),
        shareReplay({ refCount: true, bufferSize: 1 })
    );

    employersData: MatTableDataSource<Employer> = new MatTableDataSource([]);

    loadingIndex$ = this._loadingIndex.pipe(shareReplay(1));
    downloadingExcel$ = new BehaviorSubject(false);

    displayedColumns = [
        'companyName',
        'employeeCount',
        'plansCount',
        'companyStatus',
        'companyCity',
        'companyState',
        'renewalDate',
        'actions',
    ];

    loading$ = this._employer.loading$;

    statesForFilter$: Observable<{ label: string; value: string }[]> =
        this._employer.all$.pipe(
            map((employers) => {
                const states: any = {};
                for (const state of getUsaStatesForForm()) {
                    states[state.value] = state;
                }

                const statesForFilter: {
                    [key: string]: { label: string; value: string };
                } = {};
                for (const employer of employers) {
                    if (
                        employer.contactInfo.state &&
                        states[employer.contactInfo.state] &&
                        !statesForFilter[employer.contactInfo.state]
                    ) {
                        statesForFilter[employer.contactInfo.state] =
                            states[employer.contactInfo.state];
                    }
                }

                return Object.values(statesForFilter).sort(
                    (prevState, currState) =>
                        prevState.label.localeCompare(currState.label)
                );
            }),
    );

    currentUser: User;

    employerToDelete: Employer;
    deleteEmployerConfirmationParams = deleteEmployerConfirmationParams;

    filtersApplied = false;

    constructor(
        private _employer: EmployerService,
        private _fb: UntypedFormBuilder,
        private _modal: NgbModal,
        private _toastr: ToastrService,
        private _user: UserService,
        private _plan: PlanFacade,
        private _http: HttpClient
    ) {}

    ngOnInit(): void {
        this._user.currentUser$
            .pipe(takeUntil(this._destroy))
            .subscribe((data) => {
                this.currentUser = data;
            });
    }

    setSort(): void {
        if (!this.sort) {
            setTimeout(() => this.setSort(), 500);
            return;
        }
        this.employersData.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'companyCity':
                    return item.contactInfo.city;
                case 'companyState':
                    return item.contactInfo.state;
                default:
                    return item[property];
            }
        };
        this.employersData.sort = this.sort;
        this.employersData.paginator = this.paginator;
    }

    setEmployerToDelete(employer: Employer): void {
        this.employerToDelete = employer;
    }

    @Confirmable('deleteEmployerConfirmationParams', 'employerToDelete')
    delete(employer: Employer): void {
        this._loadingIndex.next(employer.id);
        this._employer.delete(employer.id).subscribe(() => {
            this._loadingIndex.next(null);
            this._toastr.success('Employer Deleted');
        });
    }

    filterEmployers() {
        this.filterAndSort();
        this.dropdown.close();
    }

    resetFilter() {
        this.filterForm.reset();
        this.filterAndSort();
        this.dropdown.close();        
    }


    filterAndSort() {
        const { nameContains, state, status } = this.filterForm.value;
        this.filtersApplied = !!nameContains || (state && state.length > 0) || !!status;
        const filteredData = this.employers.filter(
            (employer) => {
                const nameMatches = !nameContains || employer.companyName.toLowerCase().includes(nameContains?.toLowerCase());
                const stateMatches =
                    !state ||
                    (Array.isArray(state)) && state.length === 0 ||
                    (Array.isArray(state) && state.includes(employer.contactInfo.state));
                const statusMatches =
                    !status ||
                    status?.toLowerCase() === employer.companyStatus;
                return nameMatches && stateMatches && statusMatches;
            }
        );

        this.employersData = new MatTableDataSource(filteredData);
        this.setSort();
    }

    addNewEmployer() {
        const addNewGroupDialogRef = this._modal
            .open(AddNewGroupDialogComponent, { size: 'lg' });

        addNewGroupDialogRef.componentInstance.currentUser = this.currentUser;
        addNewGroupDialogRef.result.then((newEmployer) => {
                if (newEmployer && newEmployer.id) {
                    this.employerCreated.emit(newEmployer);
                }
            })
            .catch((error) => {});
    }

    downloadExcelFile(): void {
        this.downloadingExcel$.next(true);
        this._http
            .get('employers/download-excel/', {
                responseType: 'arraybuffer',
                observe: 'response',
            })
            .pipe(
                downloadFileFromResponse(),
                finalize(() => this.downloadingExcel$.next(false))
            )
            .subscribe();
    }

    isNonSubAgent(): boolean {
        if (!this.currentUser) return false;
        if (!this.currentUser.userType) return false;
        return this.currentUser.userType.toLowerCase() !== RoleType.SUB_AGENT;
    }

}

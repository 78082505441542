import {
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import {
    BreakpointService,
    BrowserBreakpoint,
    LayoutService,
} from '@besc/shared';
import { Agency, AgencyService } from '@besc/agency';
import { takeUntil, map, first, take } from 'rxjs/operators';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';

const STYLE_UPDATE_KEY = 'AgencyStyleUpdated';
const LOGO_KEY = 'AgencyCustomLogo';

@Component({
    selector: 'besc-aside-left',
    templateUrl: './aside-left.component.html',
    styleUrls: ['./aside-left.component.scss'],
})
export class AsideLeftComponent implements OnDestroy, OnInit {
    private _onDestroy$: Subject<any> = new Subject();

    isLgSize$: Observable<boolean> = this._breakpoint.atLeastSize(
        BrowserBreakpoint.LG
    );
    asideOpen$: Observable<boolean> = this._layout.asideOpen$;
    @ViewChild('aside') aside: ElementRef;

    DEFALUT_LOGO = './assets/images/benefit-sculptor-logo-small.png';
    logo$ = new BehaviorSubject(null);
    hasCustomLogo$ = new BehaviorSubject(false);

    constructor(
        private _breakpoint: BreakpointService,
        private _layout: LayoutService,
        private _renderer: Renderer2,
        private _agency: AgencyService,
        private el: ElementRef
    ) {
        this._agency.userAgency$.pipe(take(1)).subscribe(agency => {
            const styleUpdated = JSON.parse(localStorage.getItem(STYLE_UPDATE_KEY));
            if (!styleUpdated) {
                this.updateStyling(agency);
                localStorage.setItem(STYLE_UPDATE_KEY, "true");
            } else {
                const logo = localStorage.getItem(LOGO_KEY);
                this.logo$.next(logo || this.DEFALUT_LOGO);
            }
        });
    }

    ngOnInit() {
        combineLatest([
            this._agency.styleChanged$
        ]).pipe(
            takeUntil(this._onDestroy$),
            map(([agency]) => {
                this.updateStyling(agency);
            })
        ).subscribe();
    }

    updateStyling(agency: Agency) {
        if (agency.logo) {
            this.hasCustomLogo$.next(true);
            this.logo$.next(agency.logo);
            localStorage.setItem(LOGO_KEY, agency.logo);
        } else {
            localStorage.removeItem(LOGO_KEY);
            this.hasCustomLogo$.next(false);
            this.logo$.next(this.DEFALUT_LOGO);
        }
    }

    ngOnDestroy() {
        this._onDestroy$.next();
    }

    toggleAside() {
        this._layout.toggleAside();
    }
}

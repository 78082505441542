import { Injectable } from '@angular/core';
import {
	CanActivate,
	CanActivateChild,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	UrlTree,
	Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { filter, map } from 'rxjs/operators';
import { RouteParserService } from '@benefit-sculptor/router';

@Injectable({
	providedIn: 'root',
})
export class IsStaffGuard implements CanActivate, CanActivateChild {
	constructor(
		private _user: UserService,
		private _router: Router,
		private _routeParser: RouteParserService
	) { }

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		return this._user.currentUser$.pipe(
			filter((user) => !!user),
			map((user) =>
				user.setupAttributes
					? true
					: this._router.createUrlTree(next.data.redirectIfNotStaff)
			)
		);
	}
	canActivateChild(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> {
		const data = this._routeParser.getAllRouteData(next);
		return this._user.currentUser$.pipe(
			filter((user) => !!user),
			map((user) =>
				user.setupAttributes
					? true
					: this._router.createUrlTree(data.redirectIfNotStaff)
			)
		);
	}
}
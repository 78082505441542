import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KeyContact, SmallEmployer } from '../interfaces';
import {
    catchError,
    finalize,
    shareReplay,
    switchMap,
    tap,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class SmallEmployerFacade {
    private _getEmployers: BehaviorSubject<boolean> = new BehaviorSubject<
        boolean
    >(false);
    private _loading = new BehaviorSubject<boolean>(false);

    all$: Observable<SmallEmployer[]> = this._getEmployers.pipe(
        tap(() => this._loading.next(true)),
        switchMap(() =>
            this._http
                .get<SmallEmployer[]>('employers')
                .pipe(finalize(() => this._loading.next(false)))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    loading$ = this._loading.asObservable();

    constructor(private _http: HttpClient, private _toastr: ToastrService) {}

    getEmployers() {
        this._getEmployers.next(true);
    }

    addNew(newEmployer: SmallEmployer): Observable<SmallEmployer> {
        return this._http.post<SmallEmployer>('employers', newEmployer);
    }

    get(id: string): Observable<SmallEmployer> {
        return this._http
            .get<SmallEmployer>('employers/' + id)
            .pipe(catchError(() => of(null)));
    }

    update(
        id: string,
        smallEmployer: Partial<SmallEmployer>
    ): Observable<SmallEmployer> {
        return this._http.patch<SmallEmployer>(
            'employers/' + id,
            smallEmployer
        );
    }

    delete(id: any): Observable<any> {
        return this._http
            .delete('employers/' + id)
            .pipe(tap(() => this.getEmployers()));
    }

    updateKeyContacts(smallEmployer: string, keyContacts: KeyContact[]) {
        return this._http.put(
            'employers/' + smallEmployer + '/key-contacts/',
            keyContacts
        );
    }
}

import * as moment from 'moment';
import {
    EmployerHealthPlanContribution,
    EmployerPlan,
    Issuer,
    PlanCoverageDetails,
    PlanDetails,
    PlanInfo,
    PlanRates,
    SimplePlan,
} from '../../interfaces';
import { Type } from '@angular/core';
import { CreatePlansStep } from '../steps/steps';

export const createPlansFeatureKey = 'createPlans';
export const createPlansDataKey = createPlansFeatureKey + '.data';
export const createPlansWizardKey = createPlansFeatureKey + '.wizard';

export interface CreatePlansState {
    ageRateCurve: {
        loading: boolean;
        curve: AgeRateCurve;
    };
    allAgeRateCurves: {
        loading: boolean;
        curves: AgeRateCurve[];
    };
    currentIssuers: {
        loading: boolean;
        issuers: Issuer[];
    };
    planList: {
        loading: boolean;
        plans: SimplePlan[];
    };
    isCloning: boolean;
    creating: {
        loading: boolean;
        newPlan: EmployerPlan;
    };
}

export interface AgeRateCurve {
    id: string;
    isFederalDefault: boolean;
    ageRateCurve: number[];
}

export interface PlanStep {
    label?: string;
    icon?: [string, string];
    component?: Type<any>;
    default?: boolean;
    current?: boolean;
    placeholder?: boolean;
    future?: boolean;
    complete?: boolean;
    selected?: boolean;
    navigable?: boolean;
    pathFromRoot?: CreatePlansStep[];
}

export type PlanSteps = { [key in CreatePlansStep]?: PlanStep };

export interface FutureStep extends PlanStep {
    label: string;
    icon: [string, string];
    current: false;
    placeholder: false;
    future: true;
    complete: boolean;
    pathFromRoot: CreatePlansStep[];
}

export interface PlaceholderStep extends PlanStep {
    placeholder: true;
    current: false;
    future: false;
    complete: false;
}

export interface CurrentStep extends PlanStep {
    label: string;
    icon: [string, string];
    current: true;
    future: false;
    placeholder: false;
    complete: boolean;
}

export interface ParentStep extends PlanStep {
    label: string;
    icon: [string, string];
    current: false;
    future: false;
    complete: true;
    pathFromRoot: CreatePlansStep[];
}

export type ChildStep = ParentStep;

export interface PlanSetupStep extends PlanStep {
    value: {};
}

export interface PlanSetup {
    effectiveDate: moment.Moment;
    expirationDate: moment.Moment;
    employerZipCode: string;
}

export interface ExistingPlan {
    planId: string;
}

export interface StepRouteChild {
    condition: (stepValue: any) => boolean;
    route: StepRoute;
}

export interface StepRoute {
    step: PlanStep;
    key: CreatePlansStep;
    parent: StepRoute;
    children: { [key in CreatePlansStep]?: StepRouteChild };
    root: StepRoute;
    totalPossibleSteps: number;
    pathFromRoot: CreatePlansStep[];
    condition?: (stepValue: any) => boolean;
}

export interface StepRouteDefinition {
    step: CreatePlansStep;
    children?: {
        route: StepRouteDefinition;
        condition?: (stepValue: any) => boolean;
    }[];
    child?: StepRouteDefinition;
}

export interface ActivatedStep { 
    firstChild: ActivatedStep | null;
    step: PlanStep;
    key: CreatePlansStep;
    root: ActivatedStep;
    parent: ActivatedStep;
    route: StepRoute;
    value: any;
}

export interface StepsValue {
    [CreatePlansStep.Setup]: PlanSetup;
    [CreatePlansStep.Issuer]: Issuer;
    [CreatePlansStep.PlanInfo]: PlanInfo;
    [CreatePlansStep.PlanDetails]: PlanDetails;
    [CreatePlansStep.CoverageDetails]: PlanCoverageDetails;
    [CreatePlansStep.PlanContribution]: EmployerHealthPlanContribution;
    [CreatePlansStep.Rates]: PlanRates;
    [CreatePlansStep.PickExistingPlan]: ExistingPlan;
}

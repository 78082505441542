import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Observable } from 'rxjs';

import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

import { ChangeListEmployee } from '@besc/plan';
import { EmployeePlanResponse } from '@besc/employee';

@Component({
    selector: 'besc-employee-list',
    templateUrl: './employee-list.component.html',
    styleUrls: ['./employee-list.component.scss'],
})
export class EmployeeListComponent implements AfterViewInit {
    @Input() employees$: Observable<ChangeListEmployee[]>;
    @Input() connectedTo: (CdkDropList | string)[] | CdkDropList | string;
    @Input() uniqueId: string;
    @Input() listTitle: string;
    @Input() employeePlans: EmployeePlanResponse = {};
    @Input() disabled = false;
    @Input() showDates: boolean;

    @ViewChild('dragTooltip') dragTooltip: NgbTooltip;


    @Output() drag: EventEmitter<CdkDragDrop<any, any>> = new EventEmitter<
        CdkDragDrop<any, any>
    >();
    @Output() clickItem: EventEmitter<any> = new EventEmitter<any>();

    CHANGING_PLANS_TOOLTIP_OPENED = 'CHANGING_PLANS_TOOLTIP_OPENED';
    
    ngAfterViewInit(): void {
        const alreadyOpened = localStorage.getItem(this.CHANGING_PLANS_TOOLTIP_OPENED);
        if (this.listTitle === 'Unassigned Employees' && !alreadyOpened) {
            this.dragTooltip.open();
        }
    }

    clickEmployee(i: number, employee: any) {
        this.clickItem.emit({
            index: i,
            employee,
            list: this.uniqueId,
        });
    }

    move($event: CdkDragDrop<any, any>) {
        this.drag.emit($event);
    }

    closeTooltip(): void {
        localStorage.setItem(this.CHANGING_PLANS_TOOLTIP_OPENED, 'true');
        this.dragTooltip.close();
    }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OnboardingContainerComponent } from '../onboarding-container/onboarding-container.component';

@Component({
    selector: 'besc-watch-demos-icon',
    templateUrl: './watch-demos-icon.component.html',
    styleUrls: ['./watch-demos-icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WatchDemosIconComponent {
    constructor(
        private _modal: NgbModal,
    ) {}
  
    presentOnboardingVideos(): void {
        const modalRef = this._modal.open(OnboardingContainerComponent, {
            centered: true,
            size: 'xl'
        });
        modalRef.componentInstance.watchDemos$$.next(true);
    }
}

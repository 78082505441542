import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { sortCompareFn } from '@benefit-sculptor/core';
import { SmallEmployerFacade } from './small-employer.facade';
import { SmallEmployer, SmallEmployerListFilter } from '../interfaces';

@Injectable({
    providedIn: 'root',
})
export class SmallEmployerListFacade {
    private _requestEmployers: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    private _currentFilter: BehaviorSubject<
        SmallEmployerListFilter
    > = new BehaviorSubject<SmallEmployerListFilter>(null);
    private _currentPage: BehaviorSubject<PageEvent> = new BehaviorSubject<
        PageEvent
    >({
        pageIndex: 0,
        pageSize: 6,
        previousPageIndex: 0,
        length: 15,
    });
    private _currentSort: BehaviorSubject<Sort> = new BehaviorSubject<Sort>(
        null
    );
    private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
        false
    );

    filteredEmployers$ = this._requestEmployers.pipe(
        switchMap(() => {
            return combineLatest([
                this._smallEmployer.all$.pipe(
                    tap(() => this._loading.next(false))
                ),
                this._currentFilter,
                this._currentSort,
            ]);
        }),
        this._filterEmployers(),
        shareReplay({ bufferSize: 1, refCount: true })
    );

    loading$: Observable<boolean> = this._loading.asObservable();

    constructor(private _smallEmployer: SmallEmployerFacade) {}

    getEmployers() {
        this._loading.next(true);
        this._requestEmployers.next(null);
    }
    filterEmployers(filter: SmallEmployerListFilter) {
        this._currentFilter.next(filter);
    }
    changePage(page: PageEvent) {
        this._currentPage.next(page);
    }
    sortEmployers($event: Sort) {
        this._currentSort.next($event);
    }

    private _filterEmployers() {
        return (
            employersWithFilter: Observable<
                [SmallEmployer[], SmallEmployerListFilter, Sort]
            >
        ): Observable<SmallEmployer[]> =>
            employersWithFilter.pipe(
                map(
                    ([smallEmployers, filter, sort]: [
                        SmallEmployer[],
                        SmallEmployerListFilter,
                        Sort
                    ]) => {
                        smallEmployers = [...smallEmployers];
                        if (sort && sort.direction !== '') {
                            smallEmployers = smallEmployers.sort(
                                sortCompareFn(sort.active, sort.direction)
                            );
                        }
                        if (!filter) {
                            return smallEmployers;
                        }
                        return smallEmployers.filter(this._filterFn(filter));
                    }
                )
            );
    }

    private _filterFn(filter) {
        return (smallEmployer) => {
            if (
                filter.nameContains &&
                !smallEmployer.companyName
                    .toLowerCase()
                    .includes(filter.nameContains.toLowerCase())
            ) {
                return false;
            }

            // if (
            //     filter.carrier &&
            //     filter.carrier.length > 0 &&
            //     !filter.carrier.includes(smallEmployer.carrier)
            // ) {
            //     return false;
            // }

            if (
                filter.status &&
                smallEmployer.companyStatus.toLowerCase() !==
                    filter.companyStatus.toLowerCase()
            ) {
                return false;
            }

            if (
                filter.state &&
                filter.state.length > 0 &&
                !filter.state.includes(smallEmployer.companyState)
            ) {
                return false;
            }

            return true;
        };
    }

    deleteEmployer(id: any) {
        return this._smallEmployer.delete(id);
    }
}

import { createReducer, on } from '@ngrx/store';
import * as WizardActions from './wizard.actions';
import { WizardState } from './wizard.models';
import { CustomError } from '../../error-handler/custom-error';

export const wizardFeatureKey = 'wizard';

export const initialState: WizardState = {
    pathToCurrentStep: [],
    navigating: false,
    stepRouting: null,
    value: {},
    steps: {},
    saving: false,
};

export const wizardReducer = createReducer(
    initialState,
    on(
        WizardActions.setCurrentStepPath,
        (state: WizardState, { path, previous }) => {
            try {
                const newState = {
                    ...state,
                    pathToCurrentStep: path,
                };
                if (previous) {
                    newState.value = {
                        ...state.value,
                        [previous.key]: previous.value,
                    };
                }
                return newState;
            } catch (error) {
                throw new CustomError(error, {
                    ngrx: true,
                    data: {
                        action: 'setCurrentStepPath',
                        state,
                        path,
                        previous,
                    }
                });
            }
        }
    ),
    on(
        WizardActions.setStepRouting,
        (state: WizardState, { stepRouting, steps }) => ({
            ...state,
            stepRouting,
            steps,
        })
    ),
    on(
        WizardActions.setStepValue,
        (state: WizardState, { stepKey, value }) => ({
            ...state,
            value: {
                ...state.value,
                [stepKey]: value,
            },
        })
    ),
    on(WizardActions.setValue, (state: WizardState, { value }) => ({
        ...state,
        value: {
            ...state.value,
            ...value,
        },
    })),
    on(WizardActions.startNavigating, (state: WizardState, {}) => ({
        ...state,
        navigating: true,
    })),
    on(WizardActions.finishedNavigating, (state: WizardState, {}) => ({
        ...state,
        navigating: false,
    })),
    on(WizardActions.resetState, (state: WizardState, { state: newState }) => ({
        ...state,
        ...newState,
    })),
    on(WizardActions.finishWizard, (state: WizardState, {}) => ({
        ...state,
        saving: true,
    })),
    on(WizardActions.savedWizard, (state: WizardState, {}) => ({
        ...state,
        saving: false,
        pathToCurrentStep: [],
        value: {},
    }))
);

// export function wizardReducer(state: WizardState | undefined, action: Action) {
//     return reducer(state, action);
// }

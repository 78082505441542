import { Component, Input, OnInit } from '@angular/core';
import { PlanRates, RateType } from '@besc/plan';
import { BehaviorSubject } from 'rxjs';
import {
    UntypedFormGroup,
} from '@angular/forms';

@Component({
    selector: 'besc-accordion-rates',
    templateUrl: './accordion-rates.component.html',
    styleUrls: ['./accordion-rates.component.scss'],
})
export class AccordionRatesComponent implements OnInit {

    @Input() rates: PlanRates;
    @Input() planInstallation = false;
    @Input() ratesForm: UntypedFormGroup;

    ages: number[] = [...Array(66).keys()];

    showAgeRates$: BehaviorSubject<boolean>;


    ageRateSelected(selected: boolean) {
        this.showAgeRates$.next(selected);
    }

    ngOnInit(): void {
        this.showAgeRates$ = new BehaviorSubject<boolean>(
            this.planInstallation || this.rates?.rates?.type === RateType.AgeRate
        );
    }

}

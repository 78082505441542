import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Plan } from '@besc/plan';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';

@Component({
    selector: 'besc-plan-details-accordion',
    templateUrl: './plan-details-accordion.component.html',
    styleUrls: ['./plan-details-accordion.component.scss'],
})
export class PlanDetailsAccordionComponent implements OnInit {
    @Input() plan: Plan;
    @Input() planInstallation = false;
    @Output() private compositeRatesChange = new EventEmitter<any>();

    ratesForm: UntypedFormGroup = this._fb.group({
        rates: this._fb.group({
            composite: this._fb.group({
                single: [0],
                singleAndChildren: [0],
                singleAndSpouse: [0],
                family: [0],
            }),
            type: ['composite'],
        })
    });

    obsv$ = this.ratesForm.valueChanges.subscribe((rates) => {
        this.compositeRatesChange.emit(rates);
    })

    planDetailsExpanded = false;

    constructor(
        private _fb: UntypedFormBuilder
    ) {}

    ngOnInit(): void {}
}

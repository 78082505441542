import { Injectable } from '@angular/core';
import {
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    UrlSegment,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthFacadeService } from './auth-facade.service';
import { map, take } from 'rxjs/operators';
import { RouteParserService } from '@benefit-sculptor/router';

@Injectable({
    providedIn: 'root',
})
export class IsUnauthenticatedGuard
    implements CanActivate, CanActivateChild, CanLoad {
    constructor(
        private _auth: AuthFacadeService,
        private _router: Router,
        private _routeParser: RouteParserService
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        return this._isUnauthenticated().pipe(
            map((isUnauthenticated) =>
                isUnauthenticated
                    ? true
                    : this._router.createUrlTree(next.data.redirectIfAuth)
            )
        );
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        const data = this._routeParser.getAllRouteData(next);
        return this._isUnauthenticated().pipe(
            map((isUnauthenticated) =>
                isUnauthenticated
                    ? true
                    : this._router.createUrlTree(data.redirectIfAuth)
            )
        );
    }

    canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this._isUnauthenticated();
    }

    private _isUnauthenticated(): Observable<boolean> {
        return this._auth.isAuthorized$.pipe(
            take(1),
            map((isAuthorized) => !isAuthorized)
        );
    }
}

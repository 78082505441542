import { APP_INITIALIZER, Provider } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthFacadeService, User } from '@benefit-sculptor/auth';


export function preloadUser(authService: AuthFacadeService): () => Observable<User> {
	return (): Observable<User> => authService.bootstrapAuth();
}

export const USER_INITIALIZER: Provider = {
	provide: APP_INITIALIZER,
	multi: true,
	useFactory: preloadUser,
	deps: [AuthFacadeService],
};

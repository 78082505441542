import { AfterViewInit, Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, startWith, takeUntil } from 'rxjs/operators';
import { normalizeEffectiveDate } from '../../helpers';
import { MonthYearPickerComponent } from '../components/month-year-picker/month-year-picker.component';
import { Moment } from 'moment';

@Directive({
    selector: 'besc-month-year-picker[bescEffectiveDate]',
})
export class EffectiveDateDirective implements AfterViewInit, OnDestroy {
    private _destroy = new Subject();

    constructor(private _control: MonthYearPickerComponent) {}

    ngAfterViewInit() {
        this._control.dateForm
            .get('date')
            .valueChanges.pipe(
                takeUntil(this._destroy),
                startWith(this._control.dateForm.get('date').value),
                filter((date) => !!date)
            )
            .subscribe((date: Moment) => {
                const normalizedDate = normalizeEffectiveDate(date);

                if (!date.isSame(normalizedDate, 'day')) {
                    this._control.writeValue(normalizedDate);
                }
            });
    }

    ngOnDestroy() {
        this._destroy.next();
    }
}

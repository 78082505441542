import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../../confirm-password.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, map, startWith, switchMap } from 'rxjs/operators';
import { AuthFacadeService } from '@benefit-sculptor/auth';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';

const ONE_LOWER = /(?=.*[a-z])/; // At least one lowercase character
const ONE_UPPER = /(?=.*[A-Z])/; // At least one uppercase character

@Component({
    selector: 'besc-set-password',
    templateUrl: './set-password.component.html',
    styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
    private _loading = new BehaviorSubject(false);
    private _userId$ = this._route.queryParams.pipe(
        map((params) => params.user_id)
    );

    passwordForm = this._fb.group(
        {
            password: ['', Validators.required],
            passwordConfirm: ['', Validators.required],
        },
        {
            validators: ConfirmPasswordValidator.MatchPassword,
        }
    );
    nameAndAgency$ = this._userId$.pipe(
        switchMap((userId) => {
            return this._auth.getNameAndAgency(userId);
        }),
        map((nameAndAgency) => {
            return {
                customerName: nameAndAgency.name,
                agencyName: nameAndAgency.agency,
            };
        })
    );
    passwordRequirements$ = this.passwordForm.get('password').valueChanges.pipe(
        startWith(''),
        map((password) => {
            return {
                length: password.length >= 8,
                upper: ONE_UPPER.test(password),
                lower: ONE_LOWER.test(password),
            };
        })
    );
    type: 'set' | 'reset';
    title: string;
    passwordLabel: string;
    confirmLabel: string;
    continueLabel: string;
    loading$ = this._loading.asObservable();

    @ViewChild('setSuccessModal') setSuccessModal: TemplateRef<any>;
    @ViewChild('resetSuccessModal') resetSuccessModal: TemplateRef<any>;

    constructor(
        private _fb: FormBuilder,
        private _route: ActivatedRoute,
        private _auth: AuthFacadeService,
        private _modal: NgbModal,
        private _toastr: ToastrService,
        private _router: Router
    ) {}

    ngOnInit() {
        this.type = this._route.snapshot.data.type;
        if (this.type === 'set') {
            this.title = 'Welcome to Benefit Sculptor!';
            this.passwordLabel = 'Create password';
            this.confirmLabel = 'Confirm password';
            this.continueLabel = 'Continue';
        } else {
            this.title = 'Reset your password';
            this.passwordLabel = 'New password';
            this.confirmLabel = 'Confirm new password';
            this.continueLabel = 'Reset password';
        }
    }

    submitPassword() {
        if (this.passwordForm) {
            this._loading.next(true);
            const params = this._route.snapshot.queryParamMap;
            const data = {
                userId: params.get('user_id'),
                timestamp:
                    params.get('pass_timestamp') ?? params.get('timestamp'),
                signature:
                    params.get('pass_signature') ?? params.get('signature'),
                password: this.passwordForm.value.password,
            };

            const sendPassword =
                this.type === 'set'
                    ? this._auth.setPassword(data)
                    : this._auth.updatePassword(data);
            sendPassword
                .pipe(finalize(() => this._loading.next(false)))
                .subscribe(
                    () => {
                        const template =
                            this.type === 'set'
                                ? this.setSuccessModal
                                : this.resetSuccessModal;
                        this._modal.open(template, {
                            size: 'lg',
                            centered: true,
                            backdrop: 'static',
                            keyboard: false,
                        });
                    },
                    (error) => {
                        if (error.error.detail?.includes('expired')) {
                            this._router.navigate(['auth/forgot-password'], {
                                queryParams: {
                                    expired: true,
                                },
                            });
                        } else if (Array.isArray(error.error.password)) {
                            this._toastr.error(
                                error.error.password[0],
                                'Failed to Update Password'
                            );
                        } else {
                            this._toastr.error('Failed to Update Password');
                        }
                    }
                );
        }
    }
}

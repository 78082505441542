import { Component, Input, OnInit } from '@angular/core';
import { EmployerStatus } from '../../interfaces';

@Component({
    selector: 'besc-employer-status-badge',
    templateUrl: './employer-status-badge.component.html',
    styleUrls: ['./employer-status-badge.component.scss'],
})
export class EmployerStatusBadgeComponent implements OnInit {
    @Input() status: EmployerStatus;

    constructor() {}

    ngOnInit(): void {}
}

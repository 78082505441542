import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { environment } from 'apps/benefit-sculptor/src/environments/environment';
import { DemoCategory, VimeoVideo, VimeoVideosDTO } from '../models/Vimeo-videos';
import { AuthFacadeService, RoleType, User } from '@benefit-sculptor/auth';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {

  readonly CATEGORIES_RANK: Record<string, number> = {
    'Customer Data': 0,
    'Quoting': 1,
    'Custom Plans': 2,
    'Admin Center': 3,
    'Other': 4,
  };
  readonly CATEGORIES_ONLY_ADMINS: Record<string, boolean> = {
    'Customer Data': false,
    'Quoting': false,
    'Custom Plans': false,
    'Admin Center': true,
    'Other': false,
  };

  constructor(
    private _http: HttpClient,
    private _authFacade: AuthFacadeService,
  ) { }

  getVideos(): Observable<DemoCategory[]> {
    return this._authFacade.currentUser$.pipe(
      take(1),
      switchMap(user => this.fetchVideos(user))
    )
  }

  fetchVideos(user: User): Observable<DemoCategory[]> {
    const secret = window.btoa(`${environment.vimeoClientId}:${environment.vimeoClientSecret}`);
    const headers: HttpHeaders = new HttpHeaders()
      .set('Authorization', `Basic ${secret}`)
      .set('Content-Type', 'application/json')
      .set('Accept', 'application/vnd.vimeo.*+json;version=3.4');

    const api = `https://api.vimeo.com/users/${environment.vimeoUserId}/videos`;
    return this._http.get<VimeoVideosDTO>(api, { headers }).pipe(
      map((response) => this.groupByCategory(response.data)),
      map((categories) => categories.sort((a,b) => a.rank - b.rank)),
      map((categories) => categories.filter(category => 
          !category.onlyAdmins
          || category.onlyAdmins && user.userType === RoleType.ADMIN_AGENT
          || category.onlyAdmins && user.userType === RoleType.ADMIN_ONLY)
      ),
    );
  }

  groupByCategory(vimeoVideos: VimeoVideo[]): DemoCategory[] {
    return vimeoVideos.reduce((categories: DemoCategory[], currentVimeoVideo) => {
      const currentCategory = currentVimeoVideo.tags.find((tag) => tag.name.includes('category:'))?.name.split('category:')[1] ?? 'Others';
      const currentSort = currentVimeoVideo.tags.find((tag) => tag.name.includes('sort:'))?.name.split('sort:')[1];
      const currentCategoryIndex = categories.findIndex((category) => category.name === currentCategory);
      if (currentCategoryIndex >= 0) {
        categories[currentCategoryIndex].videos.push({ ...currentVimeoVideo, sort: Number(currentSort) });
        categories[currentCategoryIndex].videos.sort((a, b) => a.sort - b.sort);
      } else {
        categories.push({
          name: currentCategory,
          rank: this.CATEGORIES_RANK[currentCategory] ?? 999,
          onlyAdmins: this.CATEGORIES_ONLY_ADMINS[currentCategory] ?? false,
          videos: [{ ...currentVimeoVideo, sort: Number(currentSort) }]
        });
      }
      return categories;
    }, []);
  }
}

export enum CreatePlansStep {
    Setup = 'setup',
    PlanInfo = 'info',
    Issuer = 'issuer',
    PlanDetails = 'details',
    CoverageDetails = 'coverage-details',
    Rates = 'rates',
    PickExistingPlan = 'pick-existing-plan',
    PlanContribution = 'contribution',
    SetupDatabase = 'setup-database',
}

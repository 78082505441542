import {MonoTypeOperatorFunction, Observable} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import {tap} from "rxjs/operators";
import * as moment from "moment";


export function downloadFileFromResponse(): MonoTypeOperatorFunction<any> {
    return (fileResponse: Observable<HttpResponse<ArrayBuffer>>) => fileResponse.pipe(
        tap((response) => {
            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([response.body], { type: "application/pdf" });
            const fileNameRegex = /filename\=\"(.*)\"/
            const filename = response.headers.get('content-disposition').match(fileNameRegex)[1];

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && (window.navigator as unknown as any).msSaveOrOpenBlob) {
                (window.navigator as unknown as any).msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);

            const link = document.createElement('a');
            link.href = data;
            link.download = filename;
            // this is necessary as link.click() does not work on the latest firefox
            link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
            }, 100);
        })
    )
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'besc-onboarding-container',
    templateUrl: './onboarding-container.component.html',
    styleUrls: ['./onboarding-container.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnboardingContainerComponent {

    watchDemos$$ = new BehaviorSubject<boolean>(false);

    constructor(private _activeModal: NgbActiveModal) { }

    watchDemos(): void {
        this.watchDemos$$.next(true);
    }

    close(): void {
        this._activeModal.close();
    }
}

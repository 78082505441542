import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PageInfoService {
    private _title: BehaviorSubject<string> = new BehaviorSubject<string>(
        'Page Title'
    );

    title$ = this._title.asObservable();

    constructor() {}
}

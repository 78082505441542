import { Injectable } from '@angular/core';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthFacadeService } from '../services/auth-facade.service';
import { User } from '../../interface';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    currentUser$: Observable<User | undefined> = this._auth.currentUser$.pipe(
        map((currentUser) => {
            if (currentUser) {
                return currentUser;
            } else {
                return undefined;
            }
        }),
        distinctUntilChanged(),
        shareReplay(1)
    );

    isAdmin$ = this.currentUser$.pipe(
        map((user) => user?.isAdmin),
        distinctUntilChanged()
    );

    constructor(private _auth: AuthFacadeService) {}

    refreshCurrentUser(): void {
        this._auth.loadCurrentUser().subscribe();
    }
}

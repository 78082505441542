import { Injectable } from '@angular/core';
import { animationFrameScheduler, fromEvent, Observable } from 'rxjs';
import {
    distinctUntilChanged,
    map,
    observeOn,
    shareReplay,
    startWith,
} from 'rxjs/operators';

export enum BrowserBreakpoint {
    XS = 'xs',
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XXL = 'xxl',
}

export enum MediaQueryBreakpoints {
    XS = '(min-width: 0px)',
    SM = '(min-width: 576px)',
    MD = '(min-width: 768px)',
    LG = '(min-width: 992px)',
    XL = '(min-width: 1200px)',
    XXL = '(min-width: 1400px)',
}

export const MEDIA_QUERIES: Map<BrowserBreakpoint, MediaQueryBreakpoints> =
    new Map([
        [BrowserBreakpoint.XXL, MediaQueryBreakpoints.XXL],
        [BrowserBreakpoint.XL, MediaQueryBreakpoints.XL],
        [BrowserBreakpoint.LG, MediaQueryBreakpoints.LG],
        [BrowserBreakpoint.MD, MediaQueryBreakpoints.MD],
        [BrowserBreakpoint.SM, MediaQueryBreakpoints.SM],
        [BrowserBreakpoint.XS, MediaQueryBreakpoints.XS],
    ]);

@Injectable({
    providedIn: 'root',
})
export class BreakpointService {
    private _mediaQueryCache: Map<BrowserBreakpoint, Observable<boolean>> =
        new Map([]);
    private _windowResize$ = fromEvent(window, 'resize').pipe(
        observeOn(animationFrameScheduler),
        distinctUntilChanged(),
        shareReplay(1)
    );
    currentSize$ = this._windowResize$.pipe(
        startWith(this._getCurrentSize()),
        map(() => this._getCurrentSize()),
        distinctUntilChanged(),
        shareReplay(1)
    );

    constructor() {}

    atLeastSize(breakPoint: BrowserBreakpoint): Observable<boolean> {
        if (!this._mediaQueryCache.has(breakPoint)) {
            this._mediaQueryCache.set(
                breakPoint,
                this._windowResize$.pipe(
                    startWith(this._matches(MEDIA_QUERIES.get(breakPoint))),
                    map(() => this._matches(MEDIA_QUERIES.get(breakPoint))),
                    shareReplay(1)
                )
            );
        }

        return this._mediaQueryCache.get(breakPoint);
    }

    private _getCurrentSize(): string {
        for (const [size, mediaQuery] of Array.from(MEDIA_QUERIES.entries())) {
            if (this._matches(mediaQuery)) {
                return size;
            }
        }

        return BrowserBreakpoint.XS;
    }

    private _matches(mediaQuery: MediaQueryBreakpoints): boolean {
        return window.matchMedia(mediaQuery).matches;
    }
}

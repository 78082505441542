<div class="title">
    {{ listTitle }}
</div>

<div class="indicator-container">
    <img
      class="drag-indicator"
      src="./assets/icons/drag.svg"
      alt=""
      #dragTooltip="ngbTooltip"
      [ngbTooltip]="dragTooltipContent"
      placement="end"
      tooltipClass="drag-tooltip"
      [autoClose]="false"
      triggers="manual"
    >
</div>

<ng-container *ngIf="employees$ | async as employees">
    <div
        cdkDropList
        [cdkDropListData]="employees"
        [cdkDropListConnectedTo]="connectedTo"
        class="employee-list"
        [id]="uniqueId"
        cdkDropListSortingDisabled
        [cdkDropListDisabled]="disabled"
        (cdkDropListDropped)="move($event)"
    >
        <div
            class="employee"
            *ngFor="let employee of employees; let i = index"
            cdkDrag
            [cdkDragData]="employee"
            cdkDragBoundary=".employee-lists"
            [ngClass]="{ changing: employee.changing }"
            (click)="clickEmployee(i, employee)"
        >
            {{ employee.lastName }}, {{ employee.firstName }}
            <div class="flex-grow-1"></div>
            <span
                *ngIf="employee.dependentCount"
                class="badge badge-pill badge-secondary"
                >{{ employee.dependentCount }} Dependent<span
                    *ngIf="employee.dependentCount > 1"
                    >s</span
                ></span
            >
            <ng-container *ngIf="showDates && employeePlans[employee.id] as plan">
                <span
                    class="badge badge-pill badge-effective"
                    ngbTooltip="Effective Date"
                    >Effective {{ plan.effectiveDate | date }}</span
                >
                <span
                    class="badge badge-pill badge-termination"
                    ngbTooltip="Termination Date"
                    >Terminates {{ plan.terminationDate | date }}</span
                >
            </ng-container>
            <span
                *ngIf="employee.changing"
                class="badge badge-pill badge-inprogress"
                ngbTooltip="Termination Date"
                >In progress {{ uniqueId === 'employees-on-plan' ?  (employee.effectiveDate | date: 'shortDate') : (employee.terminationDate | date: 'shortDate') }}
            </span
            >
        </div>
    </div>
</ng-container>

<ng-template #dragTooltipContent>
    <div class="drag-header">
      <bs-white-close-button (clickEvent)="closeTooltip()"></bs-white-close-button>
    </div>
    <div class="drag-desc">Drag and drop to assign and unassign employees to plans.</div>
    <div class="drag-page">1/1</div>
  </ng-template>


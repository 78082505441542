import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Navigation, NavResolver } from '@benefit-sculptor/navigation';
import { Observable } from 'rxjs';
import { RouteParserService } from '@benefit-sculptor/router';
import { AgencyService, AgencyStatus } from '@besc/agency';
import { first, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class SmallEmployerNavResolver implements NavResolver {
    constructor(
        private _routeParser: RouteParserService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _agency: AgencyService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<Navigation | Observable<Navigation>>
        | Promise<Navigation | Observable<Navigation>>
        | Navigation
        | Observable<Navigation> {
        const params = this._routeParser.getParamsFromRoute(route);
        return this._agency.userAgency$.pipe(
            map((agency) => {
                if (typeof params.employerId !== 'undefined') {
                    return {
                        items: [
                            {
                                label: 'Home',
                                disabled: agency.status !== AgencyStatus.ACTIVE,
                                link: ['/app/home'],
                            },
                            {
                                label: 'Custom Data',
                                icon: ['fad', 'list'],
                                disabled: agency.status !== AgencyStatus.ACTIVE,
                                link: ['/app/small-employers'],
                                notExact: true,
                                separator: true,
                            },
                            {
                                label: 'Quotes',
                                icon: ['fad', 'chart-bar'],
                                notExact: true,
                                disabled: typeof params.employerId === 'undefined',
                                link: ['/app/small-employers/:employerId/quotes'],
                            },
                            {
                                label: 'Custom Plans',
                                icon: ['fad', 'copy'],
                                notExact: true,
                                disabled: agency.status !== AgencyStatus.ACTIVE,
                                link: ['/app/agency/custom-plans/'],
                            },
                        ],
                    } as Navigation;
                } else {
                    return {
                        items: [
                            {
                                label: 'Home',
                                disabled: agency.status !== AgencyStatus.ACTIVE,
                                link: ['/app/home'],
                            },
                            {
                                label: 'Custom Data',
                                icon: ['fad', 'list'],
                                disabled: agency.status !== AgencyStatus.ACTIVE,
                                link: ['/app/small-employers'],
                                notExact: true,
                                separator: true,
                            },
                            {
                                label: 'Custom Plans',
                                icon: ['fad', 'copy'],
                                notExact: true,
                                disabled: agency.status !== AgencyStatus.ACTIVE,
                                link: ['/app/agency/custom-plans/'],
                            },
                        ],
                    } as Navigation;
                }
            }),
            first()
        );
    }
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, delay, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { concat, from, of, throwError } from 'rxjs';

import * as WizardActions from './wizard.actions';
import { transformDefinitionToRoute } from '../helpers/transform';
import { WizardFacade } from './wizard.facade';
import { getNextStepPath, transformRouteToActivatedStep } from '../helpers/step-routing';
import { CustomError } from '../../error-handler/custom-error';

@Injectable()
export class WizardEffects {
    loadStepRouting$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WizardActions.setStepRouting),
            switchMap((action) => {
                const stepRoute = transformDefinitionToRoute(
                    action.stepRouting,
                    action.steps
                );

                return from([
                    WizardActions.setStepRoute({ stepRoute, key: action.key }),
                    WizardActions.setCurrentStepPath({
                        path: stepRoute.pathFromRoot,
                        key: action.key,
                    }),
                ]);
            }),
            catchError(error => throwError(new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'loadStepRouting$',
                }
            }))),
        )
    );

    navigateToPreviousStep$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WizardActions.goToPreviousStep),
            withLatestFrom(this._facade.activatedStep ? of(null) : this._facade['_store']),
            map(([action, data]) => {
                let activatedStep = this._facade.activatedStep;
                if (!activatedStep) {
                    const route = transformDefinitionToRoute(data.createPlans.wizard.stepRouting, data.createPlans.wizard.steps)
                    activatedStep = transformRouteToActivatedStep(data.createPlans.wizard.pathToCurrentStep, route, action.value ?? {} )
                    if (action.value) {
                        activatedStep.value = action.value;
                    }
                }
                let path = activatedStep.route.pathFromRoot;

                if (activatedStep.parent) {
                    path = activatedStep.parent.route.pathFromRoot;
                }

                const props: any = {
                    path,
                };

                if (action.value) {
                    props.previous = {
                        key: activatedStep.key,
                        value: action.value,
                    };
                }

                return WizardActions.setCurrentStepPath(props);
            }),
            catchError(error => throwError(new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'navigateToPreviousStep$',
                    activatedStep: this._facade.activatedStep,
                }
            }))),
        )
    );

    navigateToNextStep$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WizardActions.goToNextStep),
            withLatestFrom(this._facade.activatedStep ? of(null) : this._facade['_store']),
            map(([action, data]) => {
                let activatedStep = this._facade.activatedStep;       
                if (!activatedStep) {
                    const route = transformDefinitionToRoute(data.createPlans.wizard.stepRouting, data.createPlans.wizard.steps)
                    activatedStep = transformRouteToActivatedStep(data.createPlans.wizard.pathToCurrentStep, route, action.value)
                    activatedStep.value = action.value;
                }
                const path = getNextStepPath(
                    action.value,
                    activatedStep
                );
                return WizardActions.setCurrentStepPath({
                    path,
                    previous: {
                        key: activatedStep.key,
                        value: action.value,
                    },
                    key: action.key,
                });
            }),
            catchError(error => throwError(new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'navigateToNextStep$',
                    activatedStep: this._facade.activatedStep,
                }
            }))),
        )
    );

    navigateToStep$ = createEffect(() =>
        this._actions$.pipe(
            ofType(WizardActions.navigateToStep),
            switchMap((action) => {
                const navigate = of(
                    WizardActions.setCurrentStepPath({
                        path: action.path,
                        key: action.key,
                    })
                );
                return concat(
                    of(WizardActions.startNavigating({ key: action.key })),
                    navigate.pipe(delay(100))
                );
            }),
            catchError(error => throwError(new CustomError(error, {
                ngrx: true,
                data: {
                    action: 'navigateToStep$',
                }
            }))),
        )
    );

    constructor(private _actions$: Actions, private _facade: WizardFacade) {}
}

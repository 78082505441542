<h3>Contact support</h3>
<br>
<p>Hello! Your feedback and inquiries mean a lot to us. 
    Please contact <a class="support_email" href="mailto:support@benefitsculptor.com" target="_blank">support@benefitsculptor.com</a>, 
    and our team will reach out to you at the earliest opportunity. 
</p>
<p>
    Some common questions can be answered by watching our instructional demos.
    <a role="button" (click)="seeVideos()">
        <span class="see_videos">
            See videos
        </span>
        <img
            alt="See_videos"
            src="./assets/icons/navigate.png"
        />
    </a>
</p>
<br><br>
<button class="bs-button bs-primary" (click)="close()">
    Back
</button>

<mat-expansion-panel class="mat-elevation-z0" *ngIf="rates">
    <mat-expansion-panel-header class="centered-description">
        <mat-panel-title> Plan Rates </mat-panel-title>
        <mat-panel-description>
            {{ planInstallation ? 'Default Rate Type' : 'Rate Type' }} -
            {{ rates.rates.type === 'age_rate' ? 'Age Banded' : 'Composite' }}
            | Rate Effective Date - {{ rates.effectiveDate | date }} | Rating
            Area - {{ rates.ratingArea }}
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="btn-group" role="group" *ngIf="planInstallation">
        <input
            type="radio"
            class="btn-check"
            id="rateAgeRates"
            value="ageRate"
            autocomplete="off"
            [checked]="(showAgeRates$ | async) === true"
        />
        <label
            (click)="ageRateSelected(true)"
            class="btn btn-outline btn-outline-primary"
            for="rateAgeRates"
            >Age Rates</label
        >

        <input
            type="radio"
            class="btn-check"
            id="rateCompositeRates"
            value="composite"
            autocomplete="off"
            [checked]="(showAgeRates$ | async) === false"
        />
        <label
            (click)="ageRateSelected(false)"
            class="btn btn-outline btn-outline-primary"
            for="rateCompositeRates"
            >Composite Rates</label
        >
    </div>
    <hr />
    <div class="item-table age-table" *ngIf="showAgeRates$ | async">
        <div class="age-item" *ngFor="let age of ages">
            <strong>{{ 'Age ' + age }}:</strong>
            {{ rates.rates.age['age' + age] | currency }}
        </div>
    </div>
    <div *ngIf="!(showAgeRates$ | async)">
        <besc-composite-rates
            [ratesForm]="ratesForm"
            *ngIf="planInstallation"
        ></besc-composite-rates>
        <div *ngIf="!planInstallation" class="composite-table">
            <div class="composite-item">
                <div class="title">Single</div>
                <div class="item">
                    {{ rates.rates.composite.single | currency }}
                </div>
            </div>
            <div class="composite-item">
                <div class="title">Single & Children</div>
                <div class="item">
                    {{ rates.rates.composite.singleAndChildren | currency }}
                </div>
            </div>
            <div class="composite-item">
                <div class="title">Single & Spouse</div>
                <div class="item">
                    {{ rates.rates.composite.singleAndSpouse | currency }}
                </div>
            </div>
            <div class="composite-item">
                <div class="title">Family</div>
                <div class="item">
                    {{ rates.rates.composite.family | currency }}
                </div>
            </div>
        </div>
    </div>
</mat-expansion-panel>

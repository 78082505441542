import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Appearance, SetupIntentResult, Stripe, StripeElements, StripePaymentElement, loadStripe } from '@stripe/stripe-js';
import { environment } from 'apps/benefit-sculptor/src/environments/environment';
import {
    AgencySubscriptionSummary,
    CreateStripeSubscription,
    PaymentMethodSummary,
    RegisterAgency,
    SetupIntent,
} from '../registration.interfaces';
import { Observable, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SetDefaulCardDTO } from '../models/SetDefaultCard.model';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    stripePromise: Promise<Stripe | null>;
    elements: StripeElements | undefined;

    constructor(protected _http: HttpClient) {
        this.stripePromise = loadStripe(environment.stripePK);
    }

    createAgency(data: Omit<RegisterAgency, 'agency' | 'user'>) {
        return this._http.post<RegisterAgency>(
            '/agencies/registration/register-agency',
            data
        );
    }

    updateAgency(data: RegisterAgency) {
        return this._http.put<RegisterAgency>(
            '/agencies/registration/register-agency',
            data
        );
    }

    initiatePaymentMethod(agencyId: string) {
        return this._http.post<SetupIntent>(
            '/agencies/registration/initiate-payment-method-setup',
            {
                agency: agencyId,
            }
        );
    }

    confirmSetupIntent(customerId: string) {
        return this.confirmStripeSetupIntent().pipe(
            switchMap((resp: { error?: any }) => {
                if (resp.error) {
                    throw new Error(resp.error);
                }
                return this._http.get<PaymentMethodSummary>(
                    `/agencies/registration/confirmed-setup-intent`,
                    {
                        params: {
                            customerId,
                        },
                    }
                );
            })
        );
    }

    confirmStripeSetupIntent(): Observable<SetupIntentResult> {
        return from(this.stripePromise)
            .pipe(
                switchMap((stripe) => {
                    return from(
                        stripe!.confirmSetup({
                            elements: this.elements,
                            redirect: 'if_required',
                            confirmParams: {
                                payment_method_data: {
                                    billing_details: {
                                        address: {
                                            country: 'US',
                                        },
                                    },
                                },
                            },
                        })
                    );
                }),
            );
    }

    setDefaultCard(setupIntentId: string): Observable<SetDefaulCardDTO> {
        return this._http.post<SetDefaulCardDTO>('subscriptions/set-default-card', { setupIntentId });
    }

    createSubscription(data: {
        agency: string;
        agents: number;
        subAgents: number;
        trialPeriodEndDate: string;
        states: string[];
    }) {
        return this._http.post<CreateStripeSubscription>(
            '/agencies/registration/create-subscription',
            data
        );
    }

    getSubscriptionSummary(agencyId: string) {
        return this._http.get<AgencySubscriptionSummary>(
            `/agencies/registration/summary/${agencyId}`
        );
    }

    confirmRegistration(agencyId: string) {
        return this._http.post<{ agency: string }>(
            '/agencies/registration/confirmation/',
            {
                agency: agencyId,
            }
        );
    }

    async createCardElement(clientSecret: string): Promise<StripePaymentElement | undefined> {
        const stripe = await this.stripePromise;
        const appearance: Appearance = {
            theme: 'stripe',
            variables: {
                fontFamily: 'Red Hat Display',
                colorPrimary: '#00879F',
                colorText: '#000000',
                fontWeightLight: '400',
                fontWeightNormal: '500',
            },
        };
        this.elements = stripe?.elements({
            appearance,
            clientSecret,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css?family=Red+Hat+Display',
                },
            ],
        });
        return this.elements?.create('payment', {
            fields: {
                billingDetails: {
                    address: {
                        country: 'never'                    },
                },
            },
            wallets: {
                applePay: 'never',
                googlePay: 'never'
            }
        });
    }
}

import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthFacadeService, RecaptchaService } from '@benefit-sculptor/auth';
import { BescValidators } from '@benefit-sculptor/core';

@Component({
    selector: 'besc-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    loginForm = this._fb.group({
        username: [
            '',
            {
                validators: [BescValidators.email, Validators.required],
            },
        ],
        password: ['', { validators: [Validators.required] }],
        remember: false,
    });

    authNotice$ = this._authService.authNotice$;
    loading$ = this._authService.loading$;

    constructor(
        private _authService: AuthFacadeService,
        private _recaptcha: RecaptchaService,
        private _fb: UntypedFormBuilder,
        private _router: Router,
    ) {}

    ngOnInit() {
        this._authService.isAuthorized$
            .pipe(filter((isAuthorized) => isAuthorized))
            .subscribe((authorized) => {
                if (authorized) {
                    this._router.navigate(['/app/home']);
                }
            });
    }

    async login() {
        const token = await this._recaptcha.getRecaptcha('loginUser');
        this._authService.login(this.loginForm.value, token);
    }

    /**
     * Checking control validation
     *
     * @param controlName: string => Equals to formControlName
     * @param validationType: string => Equals to valitors name
     */
    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.loginForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result =
            control.hasError(validationType) &&
            (control.dirty || control.touched);
        return result;
    }
}

import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Route,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RoleType } from '../../interface';
import { AuthFacadeService } from './auth-facade.service';

@Injectable({
    providedIn: 'root',
})
export class UserTypeGuard implements CanActivate, CanLoad {
    constructor(private _auth: AuthFacadeService) {}

    canActivate(route: ActivatedRouteSnapshot):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._userHasType(route.data.userType);
    }
    canLoad(route: Route):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._userHasType(route.data.userType);
    }

    private _userHasType(allowed: RoleType | RoleType[]) {
        return this._auth.currentUser$.pipe(
            map((user) => {
                if (typeof allowed === 'string') {
                    return user.userType === allowed;
                } else {
                    return allowed.includes(user.userType);
                }
            })
        );
    }
}

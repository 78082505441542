import { Component } from '@angular/core';

@Component({
    selector: 'besc-calendar-icon',
    standalone: true,
    template: `<svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M14.75 2.40381H14.0417V1.15381H12.625V2.40381H5.54167V1.15381H4.125V2.40381H3.41667C2.6375 2.40381 2 2.96631 2 3.65381V13.6538C2 14.3413 2.6375 14.9038 3.41667 14.9038H14.75C15.5292 14.9038 16.1667 14.3413 16.1667 13.6538V3.65381C16.1667 2.96631 15.5292 2.40381 14.75 2.40381ZM14.75 13.6538H3.41667V6.77881H14.75V13.6538ZM14.75 5.52881H3.41667V3.65381H14.75V5.52881Z"
            fill="currentColor"
        />
    </svg> `,
})
export class CalendarIconComponent {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloseButtonComponent } from './close-button/close-button.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { FilterButtonComponent } from './filter-button/filter-button.component';
import { RemoveButtonComponent } from './remove-button/remove-button.component';
import { WhiteCloseButtonComponent } from './white-close-button/white-close-button.component';
import { AssignedUsersComponent } from './assigned-users/assigned-users.component';
import {
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    CloseButtonComponent,
    ExportButtonComponent,
    FilterButtonComponent,
    RemoveButtonComponent,
    WhiteCloseButtonComponent,
    AssignedUsersComponent
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    NgbDropdownModule,
    NgbPopoverModule
  ],
  exports: [
    CloseButtonComponent,
    ExportButtonComponent,
    FilterButtonComponent,
    RemoveButtonComponent,
    WhiteCloseButtonComponent,
    AssignedUsersComponent
  ]
})
export class SharedComponentsModule {}

import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class TransformRequestInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.url.includes('api.vimeo.com')) {
            // const url = req.url;
            // const apiRequest = req.clone({
            //     url: url.replace('//', '/')
            // });
            // send the cloned, "secure" request to the next handler.
            return next.handle(req);
        } else {
            let url = !req.url.includes('/api/') ? '/api/' + req.url : req.url;
            url = !url.endsWith('/') ? url.endsWith('.pdf') ? url : url + '/' : url;
            // clone request and replace 'http://' with 'https://' at the same time
            const apiRequest = req.clone({
                url: url.replace('//', '/')
            });
            // send the cloned, "secure" request to the next handler.
            return next.handle(apiRequest);
        }
    }
}

import { Component } from '@angular/core';

@Component({
    selector: 'besc-manage-account-icon',
    standalone: true,
    template: `<svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
    >
        <path
            d="M4.42676 17.7617V17.1117C4.42676 16.7717 4.58676 16.4517 4.83676 16.3017C6.52676 15.2917 8.45676 14.7617 10.4268 14.7617C10.4568 14.7617 10.4768 14.7617 10.5068 14.7717C10.6068 14.0717 10.8068 13.4017 11.0968 12.7917C10.8768 12.7717 10.6568 12.7617 10.4268 12.7617C8.00676 12.7617 5.74676 13.4317 3.81676 14.5817C2.93676 15.1017 2.42676 16.0817 2.42676 17.1117V19.7617H11.6868C11.2668 19.1617 10.9368 18.4817 10.7168 17.7617H4.42676Z"
            fill="currentColor"
        />
        <path
            d="M10.4268 11.7617C12.6368 11.7617 14.4268 9.97172 14.4268 7.76172C14.4268 5.55172 12.6368 3.76172 10.4268 3.76172C8.21676 3.76172 6.42676 5.55172 6.42676 7.76172C6.42676 9.97172 8.21676 11.7617 10.4268 11.7617ZM10.4268 5.76172C11.5268 5.76172 12.4268 6.66172 12.4268 7.76172C12.4268 8.86172 11.5268 9.76172 10.4268 9.76172C9.32676 9.76172 8.42676 8.86172 8.42676 7.76172C8.42676 6.66172 9.32676 5.76172 10.4268 5.76172Z"
            fill="currentColor"
        />
        <path
            d="M21.1768 15.7617C21.1768 15.5417 21.1468 15.3417 21.1168 15.1317L22.2568 14.1217L21.2568 12.3917L19.8068 12.8817C19.4868 12.6117 19.1268 12.4017 18.7268 12.2517L18.4268 10.7617H16.4268L16.1268 12.2517C15.7268 12.4017 15.3668 12.6117 15.0468 12.8817L13.5968 12.3917L12.5968 14.1217L13.7368 15.1317C13.7068 15.3417 13.6768 15.5417 13.6768 15.7617C13.6768 15.9817 13.7068 16.1817 13.7368 16.3917L12.5968 17.4017L13.5968 19.1317L15.0468 18.6417C15.3668 18.9117 15.7268 19.1217 16.1268 19.2717L16.4268 20.7617H18.4268L18.7268 19.2717C19.1268 19.1217 19.4868 18.9117 19.8068 18.6417L21.2568 19.1317L22.2568 17.4017L21.1168 16.3917C21.1468 16.1817 21.1768 15.9817 21.1768 15.7617ZM17.4268 17.7617C16.3268 17.7617 15.4268 16.8617 15.4268 15.7617C15.4268 14.6617 16.3268 13.7617 17.4268 13.7617C18.5268 13.7617 19.4268 14.6617 19.4268 15.7617C19.4268 16.8617 18.5268 17.7617 17.4268 17.7617Z"
            fill="currentColor"
        />
    </svg>`,
})
export class ManageAccountIconComponent {}

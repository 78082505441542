import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    ActivatedRouteSnapshot,
    Data,
    Params
} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class RouteParserService {
    constructor() {}

    getRouteToFurthestRoot(
        route: ActivatedRoute | ActivatedRouteSnapshot
    ): { route: ActivatedRoute | ActivatedRouteSnapshot; data: Data } {
        if (route instanceof ActivatedRoute) {
            route = route.snapshot;
        }
        const { route: leafRoute, data } = this.traverseRouteForData(route);
        return { route: leafRoute, data };
    }

    getRouteFromRoot(
        route: ActivatedRoute | ActivatedRouteSnapshot
    ): { route: ActivatedRoute | ActivatedRouteSnapshot; data: Data } {
        if (route instanceof ActivatedRoute) {
            route = route.snapshot;
        }
        const { route: leafRoute, data } = this.traverseRouteForData(
            route,
            true
        );
        return { route, data };
    }

    getAllRouteData(route: ActivatedRoute | ActivatedRouteSnapshot): Data {
        if (route instanceof ActivatedRoute) {
            route = route.snapshot;
        }

        return {
            ...this.getRouteDataFromRoot(route),
            ...this.getRouteDataToFurthestLeaf(route)
        };
    }

    getParamsFromRoute(
        route: ActivatedRoute | ActivatedRouteSnapshot,
        keys?: string[]
    ): any {
        let value: Params = {};

        if (!keys) {
            keys = ['*'];
        }

        if (route instanceof ActivatedRoute) {
            route = route.snapshot;
        }

        this.traverseRoute(
            route.root,
            (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
                if (keys.length === 0) {
                    return true;
                }

                if (keys.includes('*')) {
                    value = { ...value, ...activatedRouteSnapshot.params };
                } else {
                    for (let i = 0; i < keys.length; ++i) {
                        if (activatedRouteSnapshot.paramMap.has(keys[i])) {
                            value[
                                keys[i]
                            ] = activatedRouteSnapshot.paramMap.get(keys[i]);

                            keys.splice(i, 1);
                            i--;
                        }
                    }
                }

                return false;
            }
        );

        return value;
    }

    getRouteDataToFurthestLeaf(
        route: ActivatedRoute | ActivatedRouteSnapshot
    ): Data {
        return this.getRouteToFurthestRoot(route).data;
    }

    getRouteDataFromRoot(route: ActivatedRoute | ActivatedRouteSnapshot): Data {
        return this.getRouteFromRoot(route).data;
    }

    traverseRoute(
        route: ActivatedRoute | ActivatedRouteSnapshot,
        perItemCall: (
            route: ActivatedRoute | ActivatedRouteSnapshot
        ) => boolean,
        key?: 'firstChild' | 'parent'
    ): void {
        if (!key) {
            key = 'firstChild';
        }
        let traversableRoute = route;
        while (traversableRoute) {
            if (perItemCall(traversableRoute)) {
                break;
            }
            traversableRoute = traversableRoute[key];
            if (!traversableRoute) {
                break;
            }
        }
    }

    traverseRouteForData(
        route: ActivatedRoute | ActivatedRouteSnapshot,
        fromRoot = false
    ): { route: ActivatedRoute | ActivatedRouteSnapshot; data: Data } {
        if (route instanceof ActivatedRoute) {
            route = route.snapshot;
        }
        let traversableRoute = route;
        let data: Data = {};
        let currentRoute: ActivatedRouteSnapshot = route;
        if (fromRoot) {
            traversableRoute = route.root;
        }
        this.traverseRoute(
            traversableRoute,
            (activatedRoute: ActivatedRouteSnapshot) => {
                if (activatedRoute.data) {
                    data = { ...data, ...activatedRoute.data };
                    currentRoute = activatedRoute;
                }
                if (fromRoot && activatedRoute === route) {
                    return true;
                }

                return false;
            },
            'firstChild'
        );

        return { route: currentRoute, data };
    }
}

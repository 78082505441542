<div class="admin-dashboard home-dashboard">
  <div class="top container">
    <div class="section-title">
      <h2>Admin Center</h2>
      <a class="dashboard-link" [routerLink]="['/app/home']">View dashboard</a>
    </div>
    <p class="section-description">Your home for visibility to accurate, real time data to improve agency topline sales.
    </p>
    <div class="row">
      <div class="col-4">
        <div class="dashboard-card">
          <div class="dashboard-card-header">
            <img src="./assets/icons/users.svg" alt="">
            <h3>Manage users</h3>
          </div>
          <div class="dashboard-card-body">
            <p>View and manage current users on your team. Assign and reassign agents to employer groups.</p>
            <a class="detail-link" [routerLink]="['/app/manage/users']">Manage users</a>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="dashboard-card">
          <div class="dashboard-card-header">
            <img src="./assets/icons/groups.svg" alt="">
            <h3>Manage groups</h3>
          </div>
          <div class="dashboard-card-body">
            <p>View and filter all employer groups and agents assigned. Add new employer groups as your business increases.</p>
            <a class="detail-link" [routerLink]="['/app/manage/groups']">Manage groups</a>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="dashboard-card">
          <div class="dashboard-card-header">
            <img src="./assets/icons/data_exploration.png" alt="">
            <h3>My agency</h3>
          </div>
          <div class="dashboard-card-body">
            <p>Manage current State licenses accessible to all users. Manage user licenses to support your business growth.</p>
            <a class="detail-link" [routerLink]="['/app/manage/agency']">My agency</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="bottom">
    <div class="container">
      <div class="bottom-header">
        <h2>My activity</h2>
        <ul class="nav nav-tabs period-tabs">
          <li class="nav-item active">
            Monthly
          </li>
          <li class="nav-item">
            Yearly
          </li>
        </ul>
      </div>
    </div>
  </div> -->
</div>
import { Injectable } from '@angular/core';
import {
    ActivatedRoute,
    NavigationEnd,
    NavigationExtras,
    Resolve,
    Router,
} from '@angular/router';
import { filter, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import {
    BehaviorSubject,
    EMPTY,
    Observable,
    OperatorFunction,
    Subject,
} from 'rxjs';
import { NavigationParserService } from './navigation-parser.service';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface NavigationItem {
    label: string;
    link: any[];
    isActive?: boolean;
    disabled?: boolean;
    icon?: IconProp;
    extras?: NavigationExtras;
    notExact?: boolean;
    separator?: boolean;
}

export interface Navigation {
    parent?: NavigationItem;
    items: NavigationItem[];
}

export interface NavResolver
    extends Resolve<Navigation | Observable<Navigation>> {}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _unregister$: Subject<any> = new Subject();
    private _navigation$: BehaviorSubject<Observable<Navigation>> =
        new BehaviorSubject<Observable<Navigation>>(EMPTY);

    private _headerNavigation$: BehaviorSubject<Observable<Navigation>> =
        new BehaviorSubject<Observable<Navigation>>(EMPTY);

    navigation$: Observable<Navigation> = this._navigation$.pipe(
        switchMap((navigation) => navigation),
        shareReplay(1)
    );

    headerNavigation$: Observable<Navigation> = this._headerNavigation$.pipe(
        switchMap((navigation) => navigation),
        shareReplay(1)
    );

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _navParser: NavigationParserService
    ) {}

    register() {
        this._router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this._route),
                takeUntil(this._unregister$)
            )
            .subscribe((route: ActivatedRoute) => {
                this._navigation$.next(
                    this._navParser.getNavigationObservableFromRoute(route)
                );

                this._headerNavigation$.next(
                    this._navParser.getHeaderNavigationObservableFromRouter(
                        route
                    )
                );
            });
    }

    unregister() {
        this._unregister$.next();
    }

    getNavigationItemsWithTransformedLinks(): OperatorFunction<
        Navigation,
        NavigationItem[]
    > {
        return (navigationObs) =>
            navigationObs.pipe(
                map((navigation) => navigation.items),
                map((items) =>
                    items.map((item) => {
                        item.link = [
                            this._router.serializeUrl(
                                this._router.createUrlTree(
                                    typeof item.link === 'string'
                                        ? [item.link]
                                        : item.link
                                )
                            ),
                        ];
                        item.isActive = this._router.isActive(
                            this._router.createUrlTree(item.link, {
                                queryParamsHandling: 'preserve',
                            }),
                            !item.notExact
                        );

                        return item;
                    })
                )
            );
    }
}

<div class="modal-header">
    <h4 class="modal-title">Are you sure?</h4>
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm"
        aria-label="Close"
        (click)="dismiss('Cross click')"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
</div>
<div class="modal-body">
    <p>
        Are you sure you want to remove {{ user.firstName }}
        {{ user.lastName }}?
    </p>
    <p>
        Their account will be permanently removed and they will no longer be
        able to log in.
    </p>
</div>
<div class="modal-footer">
    <button
        type="button"
        class="btn btn-secondary"
        (click)="dismiss('Cancel click')"
    >
        Cancel
    </button>
    <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{
            'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light':
                working$ | async
        }"
        (click)="deleteUser()"
    >
        Remove User
    </button>
</div>

import { HttpErrorResponse } from "@angular/common/http";

export class CustomError extends Error {
    public additionalInfo: any;
  
    constructor(public originalError: Error, additionalInfo: any) {
      super(originalError.message);
      this.name = originalError.name;
      this.stack = originalError.stack;
      this.additionalInfo = additionalInfo;
    }
}

export class CustomHttpError extends HttpErrorResponse {
    public additionalInfo: any;
    public stack: string;
  
    constructor(public originalError: HttpErrorResponse, additionalInfo: any) {
      super(originalError);
      this.additionalInfo = additionalInfo;
    }
}
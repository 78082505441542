import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageInfoService } from '../../services/page-info.service';
import { HeaderService } from '../../../../../core/src/lib/header';
// import { LayoutService } from '../../../core/layout.service';
// import { PageInfoService, PageLink } from '../../../core/page-info.service';

@Component({
    selector: 'besc-page-title',
    templateUrl: './page-title.component.html',
})
export class PageTitleComponent implements OnInit, OnDestroy {
    // private unsubscribe: Subscription[] = [];
    // showTitle: boolean = true;
    // showBC: boolean = true;
    // title$: Observable<string>;
    // description$: Observable<string>;
    // bc$: Observable<Array<PageLink>>;
    // pageTitleCssClass: string = '';
    // pageTitleDirection: string = 'row';

    title$ = this._header.pageTitle$;

    constructor(
        private _pageInfo: PageInfoService,
        private _header: HeaderService
    ) {}

    ngOnInit(): void {
        // this.title$ = this.pageInfo.title.asObservable();
        // this.description$ = this.pageInfo.description.asObservable();
        // this.bc$ = this.pageInfo.breadcrumbs.asObservable();
        // this.showTitle = this.layout.getProp('pageTitle.display') as boolean;
        // this.showBC = this.layout.getProp('pageTitle.breadCrumbs') as boolean;
        // this.pageTitleCssClass = this.layout.getStringCSSClasses('pageTitle');
        // this.pageTitleDirection = this.layout.getProp(
        //   'pageTitle.direction'
        // ) as string;
    }

    ngOnDestroy() {
        // this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}

import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { Params, RouterStateSnapshot } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    NavigationActionTiming,
    routerReducer,
    RouterStateSerializer,
    StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {
    NgbDateAdapter,
    NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

import { AuthEffectsService } from '@benefit-sculptor/auth';
import { httpInterceptorProviders } from '@benefit-sculptor/http';
import {
    CoreModule,
    CustomErrorHandler,
    MomentNgbDateAdapterService,
    NgbCustomDateParserService,
    USER_INITIALIZER,
} from '@benefit-sculptor/core';
import { EmployeeModule } from '@besc/employee';


function logout(reducer) {
    return function (state, action) {
        return reducer(action.type === 'LOGOUT' ? undefined : state, action);
    };
}

export interface RouterStateUrl {
    url: string;
    params: Params;
    queryParams: Params;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const {
            url,
            root: { queryParams },
        } = routerState;
        const { params } = route;

        // Only return an object including the URL, params and query params
        // instead of the entire snapshot
        return { url, params, queryParams };
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        ToastrModule.forRoot({
            countDuplicates: true,
            preventDuplicates: true,
        }),
        RecaptchaV3Module,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'csrftoken',
            headerName: 'X-CSRFToken',
        }),
        StoreModule.forRoot(
            {
                router: routerReducer,
            },
            {
                metaReducers: [logout],
                runtimeChecks: {
                    strictStateImmutability: false,
                    strictActionImmutability: false,
                    strictStateSerializability: false,
                    strictActionSerializability: false,
                },
            }
        ),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
        EffectsModule.forRoot([AuthEffectsService]),
        !environment.production
            ? StoreDevtoolsModule.instrument({
                  // actionSanitizer: (action: any, id) => {
                  //     debugger;
                  //     if (action.activatedStep) {
                  //         action.activatedStep = {
                  //             ...action.activatedStep,
                  //             root: null,
                  //             firstChild: null,
                  //             route: {
                  //                 ...action.activatedStep.route,
                  //                 root: null,
                  //                 parent: null,
                  //                 children: []
                  //             }
                  //         };
                  //     }
                  //     if (action.stepRoute) {
                  //         action.stepRoute = {
                  //             ...action.stepRoute,
                  //             root: null,
                  //             parent: null,
                  //             children: []
                  //         };
                  //     }
                  //     return action;
                  // },
                  // stateSanitizer: (state: any, index: number) => {
                  //     if (state.createPlans) {
                  //         if (state.createPlans.activatedStep) {
                  //             state.createPlans.activatedStep = {
                  //                 ...state.createPlans.activatedStep,
                  //                 root: null,
                  //                 firstChild: null,
                  //                 route: {
                  //                     ...state.createPlans.activatedStep.route,
                  //                     root: null,
                  //                     parent: null,
                  //                     children: []
                  //                 }
                  //             };
                  //         }
                  //
                  //         if (state.createPlans.stepRoute) {
                  //             state.createPlans.stepRoute = {
                  //                 ...state.createPlans.stepRoute,
                  //                 root: null,
                  //                 parent: null,
                  //                 children: []
                  //             };
                  //         }
                  //     }
                  //     return state;
                  // },
                  serialize: {
                      options: {
                          undefined: true, // same as serialilze: undefined,
                      },
                      replacer: (key, value) => {
                          if (key === 'createPlans') {
                              return {
                                  ...value,
                                  stepRoute: null,
                                  activatedStep: !value.activatedStep
                                      ? null
                                      : {
                                            ...value.activatedStep,
                                            root: null,
                                            firstChild: null,
                                            route: {
                                                ...value.activatedStep.route,
                                                root: null,
                                                parent: null,
                                                children: [],
                                            },
                                        },
                              };
                          }

                          return value;
                      },
                  },
              })
            : [],
        EmployeeModule,
        FormsModule,
        ReactiveFormsModule,
        NgxDnDModule.forRoot(),
    ],
    providers: [
        USER_INITIALIZER,
        httpInterceptorProviders,
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.siteKey,
        },
        {
            provide: NgbDateAdapter,
            useClass: MomentNgbDateAdapterService,
        },
        {
            provide: NgbDateParserFormatter,
            useClass: NgbCustomDateParserService,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    static injector: Injector = null;
	constructor(public injector: Injector) {
		AppModule.injector = injector;
	}
}

import { Injectable } from '@angular/core';

import { select, Store, Action } from '@ngrx/store';

import * as fromEmployees from './employees.reducer';
import * as EmployeesSelectors from './employees.selectors';
import * as EmployeesActions from './employees.actions';
import { Moment } from 'moment';
import * as moment from 'moment';
import {
    CreateEmployeeModel,
    Dependent,
    Employee,
    EmployeeListFilter,
} from '../interfaces';
import { first } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

@Injectable()
export class EmployeesFacade {
    loaded$ = this.store.pipe(select(EmployeesSelectors.getEmployeesLoaded));
    plansLoaded$ = this.store.pipe(select(EmployeesSelectors.getPlansLoaded));
    saveDependentsLoading$ = this.store.pipe(
        select(EmployeesSelectors.getSaveDependentsLoaded)
    );
    updateLoading$ = this.store.pipe(
        select(EmployeesSelectors.getUpdateLoading)
    );
    addNewLoading$ = this.store.pipe(
        select(EmployeesSelectors.getAddNewLoading)
    );
    allEmployees$ = this.store.pipe(select(EmployeesSelectors.getAllEmployees));
    filteredEmployees$ = this.store.pipe(
        select(EmployeesSelectors.getFilteredEmployees)
    );
    employeeEntities$ = this.store.pipe(
        select(EmployeesSelectors.getEmployeesEntities)
    );
    employeesForCurrentPlan$ = this.store.pipe(
        select(EmployeesSelectors.getEmployeesForCurrentPlan)
    );
    employeesNotOnCurrentPlan$ = this.store.pipe(
        select(EmployeesSelectors.getEmployeesNotOnCurrentPlan)
    );
    employeesNotOnAnyPlan$ = this.store.pipe(
        select(EmployeesSelectors.getEmployeesNotOnAnyPlan)
    );
    effectiveDate$ = this.store.pipe(
        select(EmployeesSelectors.getEffectiveDate)
    );
    issuers$ = this.store.pipe(select(EmployeesSelectors.getIssuers));
    selectedEmployees$ = this.store.pipe(
        select(EmployeesSelectors.getSelected)
    );
    employeePlans$ = this.store.pipe(
        select(EmployeesSelectors.getEmployeePlans)
    );
    currentEmployee$ = this.store.pipe(
        select(EmployeesSelectors.getCurrentEmployee)
    );
    currentEmployeePlan$ = this.store.pipe(
        select(EmployeesSelectors.getCurrentEmployeePlan)
    );
    employerId$ = this.store.pipe(select(EmployeesSelectors.getEmployerId));

    constructor(private store: Store<fromEmployees.EmployeesPartialState>) {}

    dispatch(action: Action) {
        this.store.dispatch(action);
    }

    loadEmployees(employerId: string, effectiveDate?: Moment) {
        this.store.dispatch(
            EmployeesActions.loadEmployees({
                employerId,
                effectiveDate: effectiveDate?.format(moment.HTML5_FMT.DATE),
            })
        );
    }

    refreshEmployees(effectiveDate?: Moment) {
        this.employerId$
            .pipe(first())
            .subscribe((employerId) =>
                employerId
                    ? this.loadEmployees(employerId, effectiveDate)
                    : null
            );
    }

    loadEmployeePlans(
        employerId: string,
        effectiveDate: string | Moment = null
    ) {
        this.store.dispatch(
            EmployeesActions.loadEmployeePlans({
                employerId,
                effectiveDate:
                    effectiveDate !== null
                        ? moment.isMoment(effectiveDate)
                            ? effectiveDate.clone()
                            : moment(effectiveDate, moment.HTML5_FMT.DATE)
                        : (effectiveDate as null),
            })
        );
    }

    setCurrentEmployerPlan(currentEmployerPlanId: string) {
        this.store.dispatch(
            EmployeesActions.setCurrentEmployerPlan({ currentEmployerPlanId })
        );
    }

    setCurrentEmployee(currentEmployeeId: string) {
        this.store.dispatch(
            EmployeesActions.setCurrentEmployee({ currentEmployeeId })
        );
    }

    loadCurrentEmployeePlan(effectiveDate: Moment) {
        this.store.dispatch(
            EmployeesActions.loadCurrentEmployeePlan({ effectiveDate })
        );
    }

    updateEmployee(employee: Employee) {
        this.store.dispatch(EmployeesActions.updateEmployee({ employee }));
    }

    addNewEmployee(employee: CreateEmployeeModel) {
        this.store.dispatch(EmployeesActions.addNewEmployee({ employee }));
    }

    deleteEmployee(employeeId: string) {
        this.store.dispatch(EmployeesActions.deleteEmployee({ employeeId }));
    }

    deleteEmployeeElections(employeeId: string, employerId: string, effectiveDate: Moment) {
        this.store.dispatch(EmployeesActions.deleteEmployeeElections({ employeeId, employerId, effectiveDate }))
    }

    updateDependents(dependents: Dependent[], employeeId: string) {
        this.store.dispatch(
            EmployeesActions.saveDependents({ dependents, employeeId })
        );
    }

    filterEmployees(filter: EmployeeListFilter) {
        this.store.dispatch(EmployeesActions.filterEmployees({ filter }));
    }

    sortEmployees(sort: Sort) {
        this.store.dispatch(EmployeesActions.sortEmployees({ sort }));
    }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationItem } from '@besc/layout';
import {
  Router,
  NavigationStart
} from '@angular/router';
import { AgencyService, AgencyStatus } from '@besc/agency';
import { Subscription } from 'rxjs';
import { RoleType, UserService } from '@benefit-sculptor/auth';

@Component({
  selector: 'besc-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, OnDestroy {
  isAgentAdmin;
  isAccessingAs;
  navigationItems = [];
  private unsubscribe: Subscription[] = [];

  constructor(
    private _router: Router,
    private _agency: AgencyService,
    private _user: UserService,
  ) { }

  ngOnInit() {
    this._user.currentUser$.subscribe(user => {
      this.isAgentAdmin = (user as any).userType.toLowerCase() === RoleType.ADMIN_AGENT;
      this.isAccessingAs = localStorage.getItem('AS_USER') !== null && localStorage.getItem('AS_USER_AGENCY') !== null;
      this._agency.userAgency$.subscribe(agency => {
        const employerId = this.getEmployerIdFromURL(this._router.url);
        let navitationItems = [
          {
            label: 'Home',
            link: ['/app/home'],
            notExact: false,
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          },
          {
            label: 'Customer Data',
            link: ['/app/small-employers'],
            notExact: true,
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          },
          {
            label: 'Custom Plans',
            link: ['/app/agency/custom-plans/'],
            notExact: true,
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          }
        ];

        if (employerId) {
          navitationItems.splice(2, 0, {
            label: 'Quotes',
            link: [`/app/small-employers/${employerId}/quotes`],
            notExact: true,
            disabled: employerId == null,
            isActive: false,
          });
        }

        if (this.isAgentAdmin && !this.isAccessingAs) {
          navitationItems.splice(1, 0, {
            label: 'Admin Center',
            notExact: false,
            link: ['/app/admin-center'],
            disabled: agency.status !== AgencyStatus.ACTIVE,
            isActive: false,
          })
        }
  
        this.navigationItems = navitationItems.map((item) => {
          item.link = [
            this._router.serializeUrl(
              this._router.createUrlTree(
                typeof item.link === 'string'
                  ? [item.link]
                  : item.link
              )
            ),
          ];
          item.isActive = this.isActiveURL(this._router.url, item.link, item.notExact);
  
          return item;
        })
      });
      this.routingChanges();
    })
  }

  routingChanges() {
    const routerSubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const employerId = this.getEmployerIdFromURL(event.url);
        if (employerId && (
          (this.isAgentAdmin && this.navigationItems.length === 4) || (!this.isAgentAdmin && this.navigationItems.length === 3))) {
          const item = {
            label: 'Quotes',
            link: [`/app/small-employers/${employerId}/quotes`],
            notExact: true,
            disabled: employerId == null,
            isActive: false,
          };
          item.link = [
            this._router.serializeUrl(
              this._router.createUrlTree(
                typeof item.link === 'string'
                  ? [item.link]
                  : item.link
              )
            ),
          ];
          item.isActive = this.isActiveURL(this._router.url, item.link, item.notExact);
          if (this.isAgentAdmin && !this.isAccessingAs) {
            this.navigationItems.splice(3, 0, item);
          } else {
            this.navigationItems.splice(2, 0, item);
          }
        } else if (!employerId) {
          this.navigationItems = this.navigationItems.filter((item) => {
            return item.label !== 'Quotes';
          });
        }
        for (let i = 0; i < this.navigationItems.length; i += 1) {
          const item = this.navigationItems[i];
          const isActive = this.isActiveURL(event.url, item.link, item.notExact);
          this.navigationItems[i].isActive = isActive;
          if (item.link[0].includes('quotes')) {
            if (employerId) {
              this.navigationItems[i].disabled = false;
              this.navigationItems[i].link = [
                this._router.serializeUrl(
                  this._router.createUrlTree([`/app/small-employers/${employerId}/quotes`])
                ),
              ];
            } else {
              this.navigationItems[i].disabled = true;
            }
          }
        }
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  getEmployerIdFromURL(url) {
    if (!url.includes('/app/small-employers')) {
      return null
    }

    const splits = url.split('/')
    if (splits.length > 3 && splits[3] !== 'list') {
      return splits[3]
    }

    return null
  }

  isActiveURL(url, links, notExact) {
    const link = links[0];

    let isActive = this._router.isActive(
      this._router.createUrlTree(links, {
        queryParamsHandling: 'preserve',
      }),
      !notExact
    );

    if (link.includes('/quotes')) {
      return url.includes('/quotes');
    }

    if (url.includes('/quotes')) {
      return false;
    }

    return isActive;
  }

  navigateItem(item: NavigationItem, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();

    if (!item.disabled) {
      this._router.navigate(
        typeof item.link === 'string' ? [item.link] : item.link
      );
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}

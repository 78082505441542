import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'besc-no-side-layout',
    templateUrl: './no-side-layout.component.html',
    styleUrls: ['./no-side-layout.component.scss']
})
export class NoSideLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}

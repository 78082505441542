<div class="confirmation-modal">
    <button
        type="button"
        class="btn btn-outline-light fs-3 btn-sm close-button-modal"
        aria-label="Close"
        (click)="close(false)"
    >
        <fa-icon [icon]="['fad', 'times']"></fa-icon>
    </button>
    <div class="confirmation-modal-header">
        <h2 class="modal-title" id="modal-basic-title">
            {{ params?.title ?? 'Delete' }}
        </h2>
    </div>
    <div class="confirmation-modal-body">
        {{ params?.body }}
      </div>
    <div class="confirmation-modal-footer">
        <button
            type="button"
            class="bs-button bs-secondary action-button"
            (click)="close(false)"
        >
            {{ params?.cancelButton ?? 'Cancel' }}
        </button>
        <button
            type="button"
            [class.bs-danger]="!params?.confirmationBtnClass || params.confirmationBtnClass === 'danger'"
            [class.bs-primary]="params.confirmationBtnClass === 'primary'"
            class="bs-button bs-danger action-button"
            (click)="close(true)"
        >
            {{ params?.deleteButton ?? 'Delete' }}
        </button>
    </div>
</div>

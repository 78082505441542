import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { SubHeaderComponent } from './sub-header.component';
import { SubHeaderWrapperComponent } from './sub-header-wrapper.component';
import { NavigationModule } from '../navigation';
import { UserProfileModule } from '@besc/user-profile';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';
import { OnboardingModule } from '@besc/onboarding';

@NgModule({
    declarations: [
        HeaderComponent,
        SubHeaderComponent,
        SubHeaderWrapperComponent,
    ],
    imports: [
        CommonModule,
        NgbProgressbarModule,
        UserProfileModule,
        NavigationModule,
        FontAwesomeModule,
        RouterModule,
        OnboardingModule,
    ],
    exports: [HeaderComponent, SubHeaderComponent, SubHeaderWrapperComponent],
})
export class HeaderModule {}

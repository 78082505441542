import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, tap } from 'rxjs/operators';
import { AuthFacadeService } from '@benefit-sculptor/auth';
import { HttpClient } from '@angular/common/http';
declare let heap: any;

@Injectable({
    providedIn: 'root',
})
export class AuthEffectsService {
    constructor(
        private _actions: Actions,
        private _auth: AuthFacadeService,
        private _http: HttpClient
    ) {}

    logout$ = createEffect(
        () =>
            this._actions.pipe(ofType('LOGOUT')).pipe(
                switchMap(() => {
                    this._auth.clearAuth();
                    return this._http.post('auth/logout', {
                        revokeToken: true,
                    }).pipe(
                        tap(() => heap.resetIdentity()),
                    );
                })
            ),
        { dispatch: false }
    );
}

<ng-container [formGroup]="dateForm">
    <div
        class="month-year-picker input-group min-w-200px"
        ngbDropdown
        #dropdown="ngbDropdown"
    >
        <ng-container *ngIf="!!label">
            <div class="form-floating">
                <ng-container *ngTemplateOutlet="input"></ng-container>
                <label>{{ label }}</label>
            </div>
        </ng-container>
        <ng-container *ngIf="!label">
            <ng-container *ngTemplateOutlet="input"></ng-container>
        </ng-container>
        <ng-template #input>
            <input
                class="form-control form-control-solid"
                formControlName="date"
                [class.readonly-field]="readonly"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [placeholder]="placeholder"
                [required]="required"
                [readOnly]="readonly"
                ngbDatepicker
            />
        </ng-template>
        <button
            class="btn btn-secondary"
            [disabled]="dateForm.controls.date.disabled"
            ngbDropdownToggle
            type="button"
        >
            <fa-icon [icon]="['fad', 'calendar']"></fa-icon>
        </button>
        <ngb-datepicker
            [displayMonths]="12"
            outsideDays="hidden"
            [weekdays]="false"
            navigation="none"
            ngbDropdownMenu
            formControlName="date"
            #dp
        >
            <ng-template ngbDatepickerContent>
                <div
                    class="multi-month-navigation mt-2 mb-4 d-flex align-items-center justify-content-between"
                >
                    <button
                        class="btn btn-outline-light btn-light btn-sm"
                        (click)="navigateCalendar(-1)"
                    >
                        <fa-icon [icon]="['fad', 'chevron-left']"></fa-icon>
                    </button>

                    <button
                        class="btn btn-sm"
                        [ngClass]="dp.state.months[0].year === currentYear ? 'btn-primary' : 'btn-light'"
                        (click)="goToToday()"
                    >
                        Go to current year
                    </button>

                    <button
                        class="btn btn-outline-light btn-light btn-sm"
                        (click)="navigateCalendar(1)"
                    >
                        <fa-icon [icon]="['fad', 'chevron-right']"></fa-icon>
                    </button>
                    <!--                    {{dp.state}}-->
                </div>
                <div class="multi-month-selector">
                    <div
                        class="month-selector"
                        *ngFor="let month of dp.state.months"
                    >
                        <div
                            class="btn btn-outline-primary text-hover-white"
                            [ngClass]="{
                                'btn-primary':
                                    dp.model.selectedDate?.month ===
                                        month.month &&
                                    dp.model.selectedDate?.year === month.year
                            }"
                            [class.disabled]="disabled(month)"
                            (click)="selectMonth(month); dropdown.close()"
                        >
                            {{ i18n.getMonthShortName(month.month) }} <br>
                            {{ month.year }}
                        </div>
                    </div>
                </div>
            </ng-template>
        </ngb-datepicker>
    </div>
</ng-container>

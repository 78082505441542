import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationItem, NavigationService } from './navigation.service';
import {
    map,
} from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'besc-header-navigation',
    templateUrl: './header-navigation.component.html',
    styleUrls: ['./header-navigation.component.scss'],
})
export class HeaderNavigationComponent implements OnInit {
    @ViewChild('ktPageTitle', { static: true }) ktPageTitle: ElementRef;
    navigation$ = this._navigation.headerNavigation$.pipe(
        map((navigation) => (navigation ? navigation : { items: [] }))
    );
    navigationItems$ = this.navigation$.pipe(
        this._navigation.getNavigationItemsWithTransformedLinks()
    );
    activeNavItemLabel$ = this.navigationItems$.pipe(
        map((items) => {
            for (const item of items) {
                if (item.isActive) {
                    return item.label;
                }
            }

            return 'Select page';
        })
    );

    constructor(
        private _navigation: NavigationService,
        private _router: Router,
    ) {}

    ngOnInit() {}

    navigateItem(item: NavigationItem, $event: MouseEvent) {
        $event.preventDefault();
        $event.stopPropagation();

        if (!item.disabled) {
            this._router.navigate(
                typeof item.link === 'string' ? [item.link] : item.link,
                !!item.extras ? item.extras : undefined
            );
        }
    }
}

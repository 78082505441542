import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmployerPlan } from '../../interfaces';

@Component({
    selector: 'besc-plan-details-modal',
    templateUrl: './plan-details-modal.component.html',
    styleUrls: ['./plan-details-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanDetailsModalComponent {
    plan: EmployerPlan;

    private _modal = inject(NgbActiveModal);

    close(): void {
        this._modal.close();
    }
}

import { Injectable } from '@angular/core';
import { Observable, OperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LongTermPersistenceService {
    constructor() {}

    get(key: string): any {
        const value = localStorage.getItem(key);
        if (!value) {
            return value;
        }
        try {
            return JSON.parse(value);
        } catch (err) {
            return value;
        }
    }

    set(key: string, value: any) {
        if (typeof value !== 'string') {
            value = JSON.stringify(value);
        }

        localStorage.setItem(key, value);
    }

    delete(key: string) {
        localStorage.removeItem(key);
    }

    saveValueOfObservable(key: string): OperatorFunction<any, any> {
        return (obs: Observable<any>) =>
            obs.pipe(tap(value => this.set(key, value)));
    }
}
